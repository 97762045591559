import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { useCreateLeadMutation } from "../../../features/lead/leadSlice";
import { useGetLeadTypesQuery } from "../../../features/lead_type/leadTypeSlice";
import { useGetLeadSubTypesQuery } from "../../../features/lead_sub_type/leadSubTypeSlice";
import { useGetCampaignsQuery } from "../../../features/campaign/campaignSlice";
import { useGetAdvertisementsQuery } from "../../../features/advertisement/advertisementSlice";
import { useGetAdGroupsQuery } from "../../../features/ad_group/adGroupSlice";
import { useGetLocationsQuery } from "../../../features/location/locationSlice";
import { useGetCountriesQuery } from "../../../features/country/countrySlice";
import { LeadSubType } from "../../../interfaces/LeadSubType";
import { Advertisement } from "../../../interfaces/Advertisement";
import { useGetSubordinatesQuery } from "../../../features/user/userSlice";
import {
  useGetAllCustomersQuery,
  useGetCustomersQuery,
} from "../../../features/customer/customerSlice";
import AddCustomerModal from "./AddCustomerModal";
import { Customer } from "../../../interfaces/Customer";

const AddLead: React.FC = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [customerDetails, setCustomerDetails] = useState<Customer | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [leadName, setLeadName] = useState<string>("");
  const [leadType, setLeadType] = useState<string>("");
  const [leadSubType, setLeadSubType] = useState<string>("");
  const [campaign, setCampaign] = useState<string>("");
  const [advertisement, setAdvertisement] = useState<string>("");
  const [adGroup, setAdGroup] = useState<string>("");
  const [interestedLocation, setInterestedLocation] = useState<string>("");
  const [assignedSalesAgent, setAssignedSalesAgent] = useState<string>("");
  const [budgetValue, setBudgetValue] = useState<string>("");
  const [budgetCurrency, setBudgetCurrency] = useState<string>("USD");
  // const [trackingID, setTrackingID] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const { data: subordinates, refetch: subRefetch } = useGetSubordinatesQuery();

  const navigate = useNavigate();
  const [createLead] = useCreateLeadMutation();

  const { data: customers, refetch: refetchCustomers } =
    useGetAllCustomersQuery({
      page: 1,
      limit: 0,
    });
  const { data: leadTypes, refetch: refetchLeadTypes } = useGetLeadTypesQuery({
    page: 1,
    limit: 0,
  });
  const { data: allLeadSubTypes, refetch: refetchLeadSubTypes } =
    useGetLeadSubTypesQuery({
      page: 1,
      limit: 0,
    });
  const { data: campaigns, refetch: refetchCampaigns } = useGetCampaignsQuery({
    page: 1,
    limit: 0,
  });
  const { data: allAdvertisements, refetch: refetchAdvertisements } =
    useGetAdvertisementsQuery({
      page: 1,
      limit: 0,
    });
  const { data: adGroups, refetch: refetchAdGroups } = useGetAdGroupsQuery({
    page: 1,
    limit: 0,
  });
  // const { data: contactMethods, refetch: refetchContactMethods } =
  //   useGetContactMethodsQuery({
  //     page: 1,
  //     limit: 0,
  //   });
  const { data: locations, refetch: refetchLocations } = useGetLocationsQuery({
    page: 1,
    limit: 0,
  });
  const { data: countries, refetch: refetchCountries } = useGetCountriesQuery();

  const [filteredLeadSubTypes, setFilteredLeadSubTypes] = useState<
    LeadSubType[]
  >([]);
  const [filteredAdvertisements, setFilteredAdvertisements] = useState<
    Advertisement[]
  >([]);

  useEffect(() => {
    if (leadType && allLeadSubTypes) {
      const filtered = allLeadSubTypes.data.filter((subType) => {
        // Check if subType.leadType is an object or a string
        if (typeof subType.leadType === "object" && subType.leadType !== null) {
          return subType.leadType._id === leadType;
        } else {
          return subType.leadType === leadType;
        }
      });
      setFilteredLeadSubTypes(filtered);
    } else {
      setFilteredLeadSubTypes([]);
    }
  }, [leadType, allLeadSubTypes]);

  useEffect(() => {
    if (adGroup && allAdvertisements) {
      const filtered = allAdvertisements.data.filter((ad) => {
        // Check if subType.leadType is an object or a string
        if (typeof ad.adGroup === "object" && ad.adGroup !== null) {
          return ad.adGroup._id === adGroup;
        } else {
          return ad.adGroup === adGroup;
        }
      });
      setFilteredAdvertisements(filtered);
    } else {
      setFilteredAdvertisements([]);
    }
  }, [adGroup, allAdvertisements]);

  // Refetch data on component mount
  useEffect(() => {
    refetchLeadTypes();
    refetchLeadSubTypes();
    refetchCampaigns();
    refetchAdvertisements();
    refetchAdGroups();
    // refetchContactMethods();
    refetchCustomers();
    refetchLocations();
    refetchCountries();
    subRefetch();
  }, []);

  // useEffect(() => {
  //   if (countries && countries.length > 0) {
  //     const defaultCountryId = countries[0]._id || "";
  //     setPrimaryPhone((prev) => ({
  //       ...prev,
  //       country: defaultCountryId as string,
  //     }));
  //     setSecondaryPhone((prev) => ({
  //       ...prev,
  //       country: defaultCountryId as string,
  //     }));
  //   }
  // }, [countries]);

  useEffect(() => {
    if (selectedCustomer) {
      const customer = customers?.data.find(
        (customer) => customer._id === selectedCustomer
      );
      setCustomerDetails(customer || null);
    }
  }, [selectedCustomer, customers]);

  const handleAddCustomer = (newCustomer: Customer) => {
    setCustomerDetails(newCustomer);
    setSelectedCustomer(newCustomer._id || "");
    setIsModalOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createLead({
        name: leadName,
        leadType,
        leadSubType,
        campaign,
        advertisement,
        adGroup,
        interestedLocation,
        assignedSalesAgent,
        customer: selectedCustomer,
        budgetDetails: { value: budgetValue, currency: budgetCurrency },
        // trackingID,
        notes,
        comments: [],
      }).unwrap();
      setSelectedCustomer("");
      setCustomerDetails(null);
      setLeadType("");
      setLeadSubType("");
      setCampaign("");
      setAdvertisement("");
      setAdGroup("");
      setInterestedLocation("");
      setAssignedSalesAgent("");
      setBudgetValue("");
      setBudgetCurrency("USD");
      // setTrackingID("");
      setNotes("");
      navigate("/dashboard/lead");
      toast.success("Lead added successfully");
    } catch (err) {
      toast.error("Failed to add Lead");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box className="main-container container-fluid p-1 m-1">
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4>Add New Lead</h4>
          </Grid>
          {/* Customer Container */}
          <Grid item xs={12}>
            <h5>Customer Information</h5>
            <FormControl fullWidth>
              <InputLabel>Select Customer</InputLabel>
              <Select
                value={selectedCustomer}
                onChange={(e) => setSelectedCustomer(e.target.value)}
                label="Select Customer"
              >
                <MenuItem value="">Select Customer</MenuItem>
                {customers?.data.map((customer) => (
                  <MenuItem key={customer._id} value={customer._id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="outlined"
                onClick={() => setIsModalOpen(true)}
                style={{ marginTop: "10px" }}
              >
                Add Customer
              </Button>
            </FormControl>
            {customerDetails && (
              <Box mt={2}>
                <h6>Customer Details:</h6>
                <Grid container spacing={2}>
                  {/* Column 1 */}
                  <Grid item xs={12} md={6}>
                    <p>Name: {customerDetails.name}</p>
                    <p>Email: {customerDetails.email}</p>
                    <p>
                      Primary Phone:{" "}
                      {typeof customerDetails.primaryPhone.country === "object"
                        ? customerDetails.primaryPhone.country.code
                        : ""}{" "}
                      {customerDetails.primaryPhone.phoneNumber}
                    </p>
                  </Grid>

                  {/* Column 2 */}
                  <Grid item xs={12} md={6}>
                    <p>
                      Secondary Phone:{" "}
                      {customerDetails.secondaryPhone &&
                      typeof customerDetails.secondaryPhone.country === "object"
                        ? customerDetails.secondaryPhone.country.code
                        : ""}{" "}
                      {customerDetails.secondaryPhone?.phoneNumber}
                    </p>
                    <p>Landline: {customerDetails.landline}</p>
                    <p>
                      Preferred Call Time: {customerDetails.preferredCallTime}
                    </p>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
          {/* Lead Container */}
          <Grid item xs={12}>
            <h5>Lead Information</h5>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Lead Name"
                  value={leadName}
                  onChange={(e) => setLeadName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Lead Type</InputLabel>
                  <Select
                    value={leadType}
                    onChange={(e) => setLeadType(e.target.value as string)}
                    label="Lead Type"
                  >
                    <MenuItem value="">Select Lead Type</MenuItem>
                    {leadTypes &&
                      leadTypes.data.map((type) => (
                        <MenuItem key={type._id} value={type._id}>
                          {type.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Lead SubType</InputLabel>
                  <Select
                    value={leadSubType}
                    onChange={(e) => setLeadSubType(e.target.value as string)}
                    label="Lead SubType"
                  >
                    <MenuItem value="">Select Lead SubType</MenuItem>
                    {filteredLeadSubTypes &&
                      filteredLeadSubTypes.map((subType) => (
                        <MenuItem key={subType._id} value={subType._id}>
                          {subType.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Campaign</InputLabel>
                  <Select
                    value={campaign}
                    onChange={(e) => setCampaign(e.target.value as string)}
                    label="Campaign"
                  >
                    <MenuItem value="">Select Campaign</MenuItem>
                    {campaigns &&
                      campaigns.data.map((camp) => (
                        <MenuItem key={camp._id} value={camp._id}>
                          {camp.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Ad Group</InputLabel>
                  <Select
                    value={adGroup}
                    onChange={(e) => setAdGroup(e.target.value as string)}
                    label="Ad Group"
                  >
                    <MenuItem value="">Select Ad Group</MenuItem>
                    {adGroups &&
                      adGroups.data.map((group) => (
                        <MenuItem key={group._id} value={group._id}>
                          {group.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Advertisement</InputLabel>
                  <Select
                    value={advertisement}
                    onChange={(e) => setAdvertisement(e.target.value as string)}
                    label="Advertisement"
                  >
                    <MenuItem value="">Select Advertisement</MenuItem>
                    {filteredAdvertisements &&
                      filteredAdvertisements.map((ad) => (
                        <MenuItem key={ad._id} value={ad._id}>
                          {ad.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Interested Location</InputLabel>
                  <Select
                    value={interestedLocation}
                    onChange={(e) =>
                      setInterestedLocation(e.target.value as string)
                    }
                    label="Interested Location"
                  >
                    <MenuItem value="">Select Location</MenuItem>
                    {locations &&
                      locations.data.map((loc) => (
                        <MenuItem key={loc._id} value={loc._id}>
                          {loc.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Tracking ID"
                  value={trackingID}
                  onChange={(e) => setTrackingID(e.target.value)}
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Budget Value"
                      value={budgetValue}
                      onChange={(e) => setBudgetValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Currency</InputLabel>
                      <Select
                        value={budgetCurrency}
                        onChange={(e) =>
                          setBudgetCurrency(e.target.value as string)
                        }
                        label="Currency"
                      >
                        <MenuItem value="EGP">EGP</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="GBP">GBP</MenuItem>
                        {/* Add more currencies as needed */}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Assigned Sales Agent</InputLabel>
                  <Select
                    value={assignedSalesAgent}
                    onChange={(e) =>
                      setAssignedSalesAgent(e.target.value as string)
                    }
                    label="Assigned Sales Agent"
                  >
                    <MenuItem value="">Select Assigned Sales Agent</MenuItem>
                    {subordinates &&
                      subordinates.map((ad) => (
                        <MenuItem key={ad._id} value={ad._id}>
                          {ad.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isFormLoading}
            >
              {isFormLoading ? "Adding Lead..." : "Add Lead"}
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* Add Customer Modal */}
      <AddCustomerModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddCustomer={handleAddCustomer}
        refetchCustomers={refetchCustomers}
      />
    </Box>
  );
};

export default AddLead;
