// import { createSlice } from "@reduxjs/toolkit"

// const authSlice = createSlice({
//     name: 'auth',
//     initialState: { user: null, token: null, refreshToken: null },
//     reducers: {
//         setCredentials: (state, action) => {
//             const { user, accessToken, refreshToken } = action.payload;
//             // state.user = user;
//             // state.token = accessToken;
//             // state.refreshToken = refreshToken;
//             if (user) {
//                 state.user = user;
//             }
//             if (accessToken) {
//                 state.token = accessToken;
//             }
//             if (refreshToken) {
//                 state.refreshToken = refreshToken;
//             }
//         },
//         logOut: (state, action) => {
//             state.user = null;
//             state.token = null;
//             state.refreshToken = null;
//         }
//     }
// })

// export const { setCredentials, logOut } = authSlice.actions;

// export default authSlice.reducer;

// export const selectCurrentUser = (state) => state.auth.user;
// export const selectCurrentToken = (state) => state.auth.token;
// export const selectCurrentUserRole = (state) => state.auth.user?.role;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Admin } from "../../interfaces/Admin";

interface AdminAuthState {
  admin: any | null;
  token: string | null;
  refreshToken: string | null;
}

const initialState: AdminAuthState = {
  admin: null,
  token: null,
  refreshToken: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setCredentials: (
      state,
      action: PayloadAction<{
        admin?: Admin;
        accessToken?: string;
        refreshToken?: string;
      }>
    ) => {
      const { admin, accessToken, refreshToken } = action.payload;
      if (admin) {
        state.admin = admin;
      }
      if (accessToken) {
        state.token = accessToken;
      }
      if (refreshToken) {
        state.refreshToken = refreshToken;
      }
    },
    logOut: (state) => {
      state.admin = null;
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export const { setCredentials, logOut } = adminSlice.actions;

export default adminSlice.reducer;

export const selectCurrentAdmin = (state: { admin: AdminAuthState }) =>
  state.admin.admin;
export const selectCurrentToken = (state: { admin: AdminAuthState }) =>
  state.admin.token;
export const selectCurrentAdminRole = (state: { admin: AdminAuthState }) =>
  state.admin.admin?.role;
