// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { useCreateCampaignMutation } from '../../../../features/campaign/campaignSlice';

// const AddCampaign = () => {
//     const [name, setName] = useState('');
//     const [description, setDescription] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);
//     const navigate = useNavigate();
//     const [createCampaign] = useCreateCampaignMutation();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await createCampaign({ name, description }).unwrap();
//             setName('');
//             navigate('/dashboard/campaign');
//         } catch (err) {
//             toast.error('Failed to create Campaign');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Add New Campaign</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Campaign Name"
//                                     />
//                                     <label htmlFor="name">Campaign Name</label>
//                                 </div>
//                                 <div className="form-floating mb-2">
//                                     <textarea
//                                         className="form-control"
//                                         name='description'
//                                         value={description}
//                                         onChange={(e) => setDescription(e.target.value)}
//                                         placeholder="Lead SubType Description"
//                                     />
//                                     <label htmlFor="description">Lead SubType Description</label>
//                                 </div>

//                                 <button className="btn btn-secondary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Adding Campaign...' : 'Add Campaign'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/campaign' className="text-dark fw-bold">Campaign List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddCampaign;

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Button, TextField, Box, Typography, Container } from "@mui/material";
import { useCreateCampaignMutation } from "../../../features/campaign/campaignSlice";

const AddCampaign: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createCampaign] = useCreateCampaignMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createCampaign({ name, description }).unwrap();
      setName("");
      navigate("/dashboard/campaign");
    } catch (err) {
      toast.error("Failed to create Campaign");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box component="div" sx={{ mt: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add New Campaign
        </Typography>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Campaign Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Campaign Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            multiline
            rows={4}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isFormLoading}
          >
            {isFormLoading ? "Adding Campaign..." : "Add Campaign"}
          </Button>
          <Box textAlign="center" mt={2}>
            <Typography variant="body2">
              Back to{" "}
              <NavLink to="/dashboard/campaign" style={{ fontWeight: "bold" }}>
                Campaign List
              </NavLink>
            </Typography>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default AddCampaign;
