// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { Button, Modal, Card, Row, Col, Nav, Tab, Container } from "react-bootstrap";
// import Carousel from "react-bootstrap/Carousel";
// import styled from 'styled-components';
// import { useGetLeadQuery, useAddLeadCommentMutation } from "../../../../features/lead/leadSlice";
// import { useGetLocationQuery } from "../../../../features/location/locationSlice";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import moment from 'moment';
// import { useSelector } from "react-redux";
// import { selectCurrentUser } from "../../../../features/auth/authSlice";

// const ViewLead = () => {
//   const { id } = useParams();
//   const { data: leadData, refetch } = useGetLeadQuery(id);
//   const [showModal, setShowModal] = useState(false);
//   const [locationId, setLocationId] = useState(null);
//   const { data: locationData, refetch: refetchLocation } =
//   useGetLocationQuery(locationId, { skip: !locationId });

//   const [addLeadComment] = useAddLeadCommentMutation();
//   const [comment, setComment] = useState('');

//   // Get the current user from the Redux store
//   const user = useSelector(selectCurrentUser);

//   const handleAddComment = async () => {
//     try {
//       // Make sure comment is not empty
//       if (comment.trim() === '') {
//         alert('Comment cannot be empty.');
//         return;
//       }

//       // Ensure the user is available
//       if (!user || !user._id) {
//         alert('User information is not available.');
//         return;
//       }

//       // Send the comment along with the user ID
//       await addLeadComment({
//         id,
//         comment: {
//           user: user._id,
//           comment,
//         },
//       }).unwrap();

//       setComment(''); // Reset comment field after submission
//       refetch(); // Refetch lead data to update comments
//     } catch (error) {
//       console.error('Failed to add comment:', error);
//     }
//   };

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   useEffect(() => {
//     if (leadData && leadData.interestedLocation) {
//       setLocationId(leadData.interestedLocation._id);
//     }
//   }, [leadData]);

//   const handleCloseModal = () => setShowModal(false);
//   const handleShowModal = () => setShowModal(true);

//   return (
//     <div className="main-container container-fluid m-1">
//       <div className="row row-sm">
//         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//           <div className="card">
//             <div className="card-header pb-0">
//               <div className="d-flex justify-content-between">
//                 <h4 className="card-title mg-b-0">View Lead Details</h4>
//               </div>
//             </div>
//             <div className="card-body">
//               {leadData ? (
//                 <Container fluid>
//                   <Row className="p-1">
//                     <Col lg={4} md={6} sm={12}>
//                       <h5>Lead Info</h5>
//                       <p>
//                         <strong>Name:</strong> {leadData.name}
//                       </p>
//                       <p>
//                         <strong>Email:</strong> {leadData.email}
//                       </p>
//                       <p>
//                         <strong>Lead Type:</strong> {leadData.leadType.name}
//                       </p>
//                       <p>
//                         <strong>Lead SubType:</strong>{" "}
//                         {leadData.leadSubType.name}
//                       </p>
//                       <hr />
//                       <h5>Campaign Info</h5>
//                       <p>
//                         <strong>Campaign:</strong> {leadData.campaign.name}
//                       </p>
//                       <p>
//                         <strong>Advertisement:</strong>{" "}
//                         {leadData.advertisement.name}
//                       </p>
//                       <p>
//                         <strong>Ad Group:</strong> {leadData.adGroup.name}
//                       </p>
//                     </Col>
//                     <Col lg={4} md={6} sm={12}>
//                       <h5>Contact Info</h5>
//                       <p>
//                         <strong>Primary Phone:</strong>{" "}
//                         {leadData.primaryPhone.country.code}{" "}
//                         {leadData.primaryPhone.phoneNumber}
//                       </p>
//                       <p>
//                         <strong>Sec. Phone:</strong>{" "}
//                         {leadData.secondaryPhone.country.code}{" "}
//                         {leadData.secondaryPhone.phoneNumber}
//                       </p>
//                       <p>
//                         <strong>Landline:</strong> {leadData.landline}
//                       </p>
//                       <hr />
//                       <h5>Other Info</h5>
//                       <p>
//                         <strong>Preferred Call Time:</strong>{" "}
//                         {leadData.preferredCallTime}
//                       </p>
//                       <p>
//                         <strong>Interested Location:</strong>{" "}
//                         {leadData.interestedLocation.name}
//                       </p>
//                       <p>
//                         <strong>Budget Details:</strong>{" "}
//                         {leadData.budgetDetails.value}{" "}
//                         {leadData.budgetDetails.currency}
//                       </p>
//                     </Col>
//                     <Col lg={4} md={6} sm={12}>
//                       <h5>Sales Info</h5>
//                       <p>
//                         <strong>Assigned Sales Agent:</strong>{" "}
//                         {leadData.assignedSalesAgent}
//                       </p>
//                       <p>
//                         <strong>Tracking ID:</strong> {leadData.trackingID}
//                       </p>
//                       <p>
//                         <strong>Contact Method:</strong>{" "}
//                         {leadData.contactMethod.name}
//                       </p>
//                       <hr />
//                       <h5>Notes</h5>
//                       <p>{leadData.notes}</p>
//                       <Button variant="primary" onClick={handleShowModal}>
//                         <i className="bi bi-geo-alt"></i> Show Location Details
//                       </Button>
//                     </Col>
//                   </Row>
//                 </Container>
//               ) : (
//                 <p>Loading...</p>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="card mt-1">
//         <div className="card-header">
//           <h5>Comments & Transactions</h5>
//         </div>
//         <div className="card-body">
//           <Tab.Container id="left-tabs-example" defaultActiveKey="comments">
//             <Nav variant="tabs">
//               <Nav.Item>
//                 <Nav.Link eventKey="comments">Comments</Nav.Link>
//               </Nav.Item>
//               <Nav.Item>
//                 <Nav.Link eventKey="transactions">Transactions</Nav.Link>
//               </Nav.Item>
//             </Nav>
//             <Tab.Content>
//               <Tab.Pane eventKey="comments">
//                 <Row>
//                   <Col>
//                     <ReactQuill
//                       theme="snow"
//                       placeholder="Leave a comment"
//                       value={comment}
//                       onChange={setComment}
//                       style={{ height: '150px', marginBottom: '1rem' }}
//                     />
//                     <button
//                       className="btn btn-primary mt-5"
//                       onClick={handleAddComment}
//                     >
//                       <i className="bi bi-send-fill"></i> Send Comment
//                     </button>
//                     {leadData?.comments && leadData.comments?.length > 0 ? (
//                       leadData.comments
//                       .slice() // Create a shallow copy to avoid mutating the original array
//                       .reverse() // Reverse the order of comments
//                       .map((comment, index) => (
//                         <div key={index} className="pt-2">
//                           <div className="d-flex align-items-center mb-3">
//                             <img
//                               src={comment?.user?.avatar?.url}
//                               alt={comment?.user?.name}
//                               className="rounded-circle me-2"
//                               width="40"
//                               height="40"
//                             />
//                             <div>
//                               <strong>{comment?.user.name}</strong> - {moment(comment?.createdAt).format('MMMM D, YYYY h:mm A')}
//                             </div>
//                           </div>
//                           <div
//                             dangerouslySetInnerHTML={{ __html: comment?.comment }}
//                             style={{ border: '1px solid #dee2e6', borderRadius: '0.25rem', paddingInline: '1rem', padding: '0.5rem', backgroundColor: '#f8f9fa' }}
//                           />
//                         </div>
//                       ))
//                     ) : (
//                       <p>No comments available.</p>
//                     )}
//                     {/* {leadData?.comments && leadData.comments?.length > 0 ? (
//                       leadData.comments?.map((comment, index) => (
//                         <div key={index} className="pt-4 mb-3 border rounded p-3" style={{ backgroundColor: '#f8f9fa' }}>
//                           <div className="d-flex align-items-center mb-3">
//                             <img
//                               src={comment?.user?.avatar?.url}
//                               alt={comment?.user?.name}
//                               className="rounded-circle me-2"
//                               width="40"
//                               height="40"
//                             />
//                             <div>
//                               <strong>{comment?.user.name}</strong> - {moment(comment?.createdAt).format('MMMM D, YYYY')}
//                             </div>
//                           </div>
//                           <div
//                             dangerouslySetInnerHTML={{ __html: comment?.comment }}
//                             style={{ border: '1px solid #dee2e6', borderRadius: '0.25rem', padding: '1rem' }}
//                           />
//                         </div>
//                       ))
//                     ) : (
//                       <p>No comments available.</p>
//                     )} */}
//                   </Col>
//                 </Row>
//               </Tab.Pane>
//               <Tab.Pane eventKey="transactions">
//                 <p>No transactions available.</p>
//               </Tab.Pane>
//             </Tab.Content>
//           </Tab.Container>
//         </div>
//       </div>

//       <Modal show={showModal} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Location Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {locationData ? (
//             <div>
//               <h5>{locationData.name}</h5>
//               <p>
//                 <strong>Country:</strong> {locationData.country}
//               </p>
//               <p>
//                 <strong>City:</strong> {locationData.city}
//               </p>
//               <p>
//                 <strong>Region:</strong> {locationData.region}
//               </p>
//               <Carousel interval={3000}>
//                 <Carousel.Item>
//                   <img
//                     className="d-block mx-auto"
//                     style={{ height: "250px", objectFit: "cover" }}
//                     src={locationData.masterPlan.url}
//                     alt="Master Plan"
//                   />
//                 </Carousel.Item>
//                 {locationData.images.map((image, index) => (
//                   <Carousel.Item key={index}>
//                     <img
//                       style={{ height: "250px", objectFit: "cover" }}
//                       className="d-block mx-auto"
//                       src={image.url}
//                       alt={`Image ${index + 1}`}
//                     />
//                   </Carousel.Item>
//                 ))}
//               </Carousel>
//               <div className="mt-3">
//                 <h5>Unit Types</h5>
//                 <UnitTypeList>
//                   {locationData.unitTypes?.map((type, index) => (
//                     <UnitTypeItem key={index}>
//                       {type}
//                     </UnitTypeItem>
//                   ))}
//                 </UnitTypeList>
//               </div>
//               <div className="mt-3">
//                 {locationData.paymentPlans?.map((plan, index) => (
//                   <PaymentPlanItem key={index}>
//                     <div>
//                       {plan.name} ({plan.duration}, {plan.downPayment})
//                       <ul>
//                         {plan.installments.map((inst, i) => (
//                           <li key={i}>
//                             {inst.duration}: {inst.amount}
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                   </PaymentPlanItem>
//                 ))}
//               </div>
//             </div>
//           ) : (
//             <p>Loading...</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// const UnitTypeList = styled.div`
//   padding: 0.5rem;
//   border: 1px solid #ddd;
//   border-radius: 4px;
//   margin-top: 0.5rem;
//   max-height: 150px;
//   overflow-y: auto;
// `;

// const UnitTypeItem = styled.div`
//   padding: 0.5rem;
//   border-bottom: 1px solid #eee;
// `;

// const PaymentPlanItem = styled.div`
//   padding: 0.5rem;
//   border-bottom: 1px solid #eee;
// `;

// export default ViewLead;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Tabs,
  Tab,
  Container,
  Avatar,
  Modal,
  IconButton,
  Chip,
  Select,
  MenuItem,
  SelectChangeEvent,
  lighten,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone"; // Import the Phone icon
import Carousel from "react-material-ui-carousel";
import {
  useGetLeadQuery,
  useAddLeadCommentMutation,
  useUpdateAssignedSalesAgentMutation,
  useUpdateLeadStatusMutation,
  useUpdateCallMutation,
} from "../../../features/lead/leadSlice";
import { useGetLocationQuery } from "../../../features/location/locationSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/auth/authSlice";
import { Comment } from "../../../interfaces/Comment";
import { ImageType } from "../../../interfaces/ImageType";
import { Call, ExpandMore, Map, Person, Send, Sync } from "@mui/icons-material";
import { useGetLeadStatusesQuery } from "../../../features/lead_status/leadStatusSlice";
import { useGetSubordinatesQuery } from "../../../features/user/userSlice";
import { useGetLeadTransactionsByLeadIdQuery } from "../../../features/lead_transaction/leadTransactionSlice";
import { LeadTransaction } from "../../../interfaces/LeadTransaction";
import { indigo } from "@mui/material/colors";
import { toast, ToastContainer } from "react-toastify";

const ViewLead: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: leadData, refetch } = useGetLeadQuery(id!);
  const { data: leadTransactions, refetch: refetchLeadTransactions } =
    useGetLeadTransactionsByLeadIdQuery({
      leadId: id!,
      page: 1,
      limit: 0,
    });
  const [tabValue, setTabValue] = useState(0);
  const {
    data: leadStatuses,
    isLoading: isStatusLoading,
    isSuccess: isLeadStatusesSuccess,
  } = useGetLeadStatusesQuery({
    page: 1,
    limit: 0,
  });
  const {
    data: subordinates,
    refetch: subRefetch,
    isSuccess: isSubordinatesSuccess,
  } = useGetSubordinatesQuery();
  const [updateLeadStatus] = useUpdateLeadStatusMutation();
  const [updateAssignedSalesAgent] = useUpdateAssignedSalesAgentMutation();
  const [updateCall] = useUpdateCallMutation();

  const [selectedStatus, setSelectedStatus] = useState(
    typeof leadData?.status === "object" ? leadData.status._id : ""
  );

  const [selectedAgent, setSelectedAgent] = useState(
    typeof leadData?.assignedSalesAgent === "object"
      ? leadData.assignedSalesAgent._id
      : ""
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<string>("");
  const {
    data: locationData,
    refetch: refetchLocation,
    isSuccess: isLocationSuccess,
  } = useGetLocationQuery(locationId, { skip: !locationId });

  const [addLeadComment] = useAddLeadCommentMutation();
  const [comment, setComment] = useState<string>("");

  const user = useSelector(selectCurrentUser);

  const handleAddComment = async () => {
    if (!comment.trim() || !user?._id) return;

    const newComment: Comment = {
      user: user._id,
      comment: comment,
    };

    try {
      await addLeadComment({
        _id: id,
        ...newComment, // Adjusted to match the expected type
      }).unwrap();
      setComment("");
      refetch();
    } catch (error) {
      console.error("Failed to add comment:", error);
    }
  };

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  useEffect(() => {
    if (isLeadStatusesSuccess) {
      refetch();
    }
    if (isSubordinatesSuccess) {
      subRefetch();
    }
    if (isLocationSuccess) {
      refetchLocation();
    }
    refetchLeadTransactions();
  }, [isLeadStatusesSuccess, isSubordinatesSuccess, isLocationSuccess]);

  useEffect(() => {
    if (leadData?.interestedLocation) {
      // setLocationId(leadData.interestedLocation._id);
      setLocationId(
        typeof leadData.interestedLocation === "object"
          ? leadData.interestedLocation._id || ""
          : leadData.interestedLocation || ""
      );
    }
  }, [leadData]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const images = [
    ...(locationData &&
    locationData.masterPlan &&
    typeof locationData.masterPlan !== "string"
      ? [{ url: locationData.masterPlan.url, alt: "Master Plan" }]
      : []),
    ...(locationData && Array.isArray(locationData.images)
      ? locationData.images
          .filter(
            (image): image is ImageType =>
              typeof image === "object" && image !== null
          )
          .map((image, index) => ({ ...image, alt: `Image ${index + 1}` }))
      : []),
  ];

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newStatusId = event.target.value;
    setSelectedStatus(newStatusId);
    updateLeadStatus({
      id: leadData?._id!,
      status: newStatusId, // Wrap the new status ID in an object
    })
      .unwrap()
      .then(() => {
        // Refetch or update UI after successful status change
        refetch(); // Function to re-fetch lead data if needed
        refetchLeadTransactions();
      })
      .catch((error) => {
        console.error("Failed to update status:", error);
      });
  };

  const handleAgentChange = (event: SelectChangeEvent<string>) => {
    const newAgent = event.target.value;
    setSelectedAgent(newAgent);
    updateAssignedSalesAgent({
      id: leadData?._id!,
      assignedSalesAgent: newAgent,
    })
      .unwrap()
      .then(() => {
        // Refetch or update UI after successful status change
        refetch(); // Function to re-fetch lead data if needed
        refetchLeadTransactions();
      })
      .catch((error) => {
        console.error("Failed to update status:", error);
      });
  };

  const [openDialog, setOpenDialog] = useState(false);

  // Detect mobile device
  const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    // if (isMobileDevice()) {
    //   setOpenDialog(true);
    // } else {
    //   console.log("Not on a mobile device");
    // }
  };

  const handleCloseDialog = () => setOpenDialog(false);

  // const handleCall = (
  //   phone: string,
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   event.preventDefault();
  //   if (!isMobileDevice()) {
  //     // Log the call event if not on mobile device
  //     toast.warning("Not on a mobile device, call not initiated.");
  //     console.log("Not on a mobile device, call not initiated.");

  //     return;
  //   }
  //   // Trigger the updateCall mutation
  //   updateCall({
  //     id: leadData?._id!, // Lead ID
  //     phoneNumber: phone, // Selected phone number
  //   })
  //     .unwrap()
  //     .then((response) => {
  //       // console.log("Call updated successfully:", response); // Log the successful response

  //       // window.location.href = `tel:${phone}`;
  //       // Check if on mobile device before initiating the call
  //       // if (isMobileDevice()) {
  //       //   // Initiate the call if on mobile
  //       //   window.location.href = `tel:${phone}`;
  //       // } else {
  //       //   console.log("Not on a mobile device, call not initiated.");
  //       // }
  //       refetch(); // Function to re-fetch lead data if needed
  //       refetchLeadTransactions();
  //       setOpenDialog(false); // Close the dialog
  //     })
  //     .catch((error) => {
  //       console.error("Failed to update call:", error); // Log error
  //     });
  // };
  const handleCall = (
    phone: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (!isMobileDevice()) {
      // Notify the user about mobile limitations
      toast.warning("This feature is only available on mobile devices.");
      console.log("Not on a mobile device, call not initiated.");
      return;
    }

    // Proceed with the call if on a mobile device
    updateCall({
      id: leadData?._id!, // Lead ID
      phoneNumber: phone, // Selected phone number
    })
      .unwrap()
      .then((response) => {
        refetch(); // Function to re-fetch lead data if needed
        refetchLeadTransactions();
        setOpenDialog(false); // Close the dialog
      })
      .catch((error) => {
        console.error("Failed to update call:", error); // Log error
      });
  };

  return (
    // <Container maxWidth="lg">
    //   <Card variant="outlined" sx={{ mb: 4 }}>
    //     <CardContent>
    //       <Typography variant="h4" gutterBottom>
    //         View Lead Details
    //       </Typography>
    //       {leadData ? (
    //         <Grid container spacing={3}>
    //           <Grid item xs={12} md={4}>
    //             <Typography variant="h6">Lead Info</Typography>
    //             <Typography>
    //               <strong>Name:</strong> {leadData.name}
    //             </Typography>
    //             <Typography>
    //               <strong>Email:</strong> {leadData.email}
    //             </Typography>
    //             <Typography>
    //               <strong>Lead Type:</strong>
    //               {leadData.leadType && typeof leadData.leadType !== "string"
    //                 ? leadData.leadType.name
    //                 : "Unknown Lead Type"}
    //             </Typography>
    //             <Typography>
    //               <strong>Lead SubType:</strong>
    //               {leadData.leadSubType &&
    //               typeof leadData.leadSubType !== "string"
    //                 ? leadData.leadSubType.name
    //                 : "Unknown Lead Sub Type"}
    //             </Typography>
    //             <Typography variant="h6" mt={2}>
    //               Campaign Info
    //             </Typography>
    //             <Typography>
    //               <strong>Campaign:</strong>
    //               {leadData.campaign && typeof leadData.campaign !== "string"
    //                 ? leadData.campaign.name
    //                 : "Unknown Campaign"}
    //             </Typography>
    //             <Typography>
    //               <strong>Advertisement:</strong>
    //               {leadData.advertisement &&
    //               typeof leadData.advertisement !== "string"
    //                 ? leadData.advertisement.name
    //                 : "Unknown Advertisement"}
    //             </Typography>
    //             <Typography>
    //               <strong>Ad Group:</strong>
    //               {leadData.adGroup && typeof leadData.adGroup !== "string"
    //                 ? leadData.adGroup.name
    //                 : "Unknown AdGroup"}
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={12} md={4}>
    //             <Typography variant="h6">Contact Info</Typography>
    //             <Typography>
    //               <strong>Primary Phone:</strong>{" "}
    //               {leadData.primaryPhone.country &&
    //               typeof leadData.primaryPhone.country !== "string"
    //                 ? leadData.primaryPhone.country.code
    //                 : "Unknown Country Code"}{" "}
    //               {leadData.primaryPhone.phoneNumber}
    //             </Typography>
    //             <Typography>
    //               <strong>Sec. Phone:</strong>{" "}
    //               {leadData.secondaryPhone.country &&
    //               typeof leadData.secondaryPhone.country !== "string"
    //                 ? leadData.secondaryPhone.country.code
    //                 : "Unknown Country Code"}{" "}
    //               {leadData.secondaryPhone.phoneNumber}
    //             </Typography>
    //             <Typography>
    //               <strong>Landline:</strong> {leadData.landline}
    //             </Typography>
    //             <Typography variant="h6" mt={2}>
    //               Other Info
    //             </Typography>
    //             <Typography>
    //               <strong>Preferred Call Time:</strong>{" "}
    //               {leadData.preferredCallTime}
    //             </Typography>
    //             <Typography>
    //               <strong>Interested Location:</strong>{" "}
    //               {leadData.interestedLocation &&
    //               typeof leadData.interestedLocation !== "string"
    //                 ? leadData.interestedLocation.name
    //                 : "Unknown Location"}
    //             </Typography>
    //             <Typography>
    //               <strong>Budget Details:</strong>{" "}
    //               {leadData.budgetDetails?.value}{" "}
    //               {leadData.budgetDetails?.currency}
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={12} md={4}>
    //             <Typography variant="h6">Sales Info</Typography>
    //             <Typography>
    //               <strong>Assigned Sales Agent:</strong> Assigned Sales Agent{" "}
    //             </Typography>
    //             <Typography>
    //               <strong>Tracking ID:</strong> {leadData.trackingID}
    //             </Typography>
    //             <Typography>
    //               <strong>Contact Method:</strong>
    //               {leadData.contactMethod &&
    //               typeof leadData.contactMethod !== "string"
    //                 ? leadData.contactMethod.name
    //                 : "Unknown Contact Method"}
    //             </Typography>
    //             <Typography variant="h6" mt={2}>
    //               Notes
    //             </Typography>
    //             <Typography>{leadData.notes}</Typography>
    //             <Button
    //               variant="contained"
    //               onClick={handleShowModal}
    //               startIcon={<i className="bi bi-geo-alt"></i>}
    //             >
    //               Show Location Details
    //             </Button>
    //           </Grid>
    //         </Grid>
    //       ) : (
    //         <Typography>Loading...</Typography>
    //       )}
    //     </CardContent>
    //   </Card>

    //   <Card variant="outlined" sx={{ mb: 4 }}>
    //     <CardContent>
    //       <Typography variant="h5" gutterBottom>
    //         Comments & Transactions
    //       </Typography>
    //       <Tabs value={0}>
    //         <Tab label="Comments" />
    //         <Tab label="Transactions" />
    //       </Tabs>
    //       <Box mt={2}>
    //         <ReactQuill
    //           theme="snow"
    //           placeholder="Leave a comment"
    //           value={comment}
    //           onChange={setComment}
    //           style={{ height: "150px", marginBottom: "1rem" }}
    //         />
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           onClick={handleAddComment}
    //           startIcon={<i className="bi bi-send-fill"></i>}
    //         >
    //           Send Comment
    //         </Button>
    //         {leadData?.comments && leadData.comments.length > 0 ? (
    //           leadData.comments
    //             .slice()
    //             .reverse()
    //             .map((comment: Comment, index: number) => (
    //               <Box key={index} mt={2}>
    //                 <Grid container alignItems="center" spacing={2}>
    //                   <Grid item>
    //                     <Avatar
    //                       src={
    //                         comment?.user &&
    //                         typeof comment?.user !== "string" &&
    //                         comment.user.avatar &&
    //                         typeof comment.user.avatar !== "string"
    //                           ? comment.user.avatar.url
    //                           : "Unknown Avatar"
    //                       }
    //                       alt={
    //                         comment?.user && typeof comment?.user !== "string"
    //                           ? comment.user.name
    //                           : "Unknown User"
    //                       }
    //                     />
    //                   </Grid>
    //                   <Grid item>
    //                     <Typography variant="body1">
    //                       <strong>
    //                         {comment?.user && typeof comment?.user !== "string"
    //                           ? comment?.user.name
    //                           : "Unknown User"}
    //                       </strong>{" "}
    //                       -{" "}
    //                       {moment(comment?.createdAt).format(
    //                         "MMMM D, YYYY h:mm A"
    //                       )}
    //                     </Typography>
    //                     <Box
    //                       mt={1}
    //                       sx={{
    //                         border: "1px solid #ddd",
    //                         borderRadius: "0.25rem",
    //                         padding: "1rem",
    //                         backgroundColor: "#f8f9fa",
    //                       }}
    //                     >
    //                       <Typography
    //                         dangerouslySetInnerHTML={{
    //                           __html: comment?.comment,
    //                         }}
    //                       />
    //                     </Box>
    //                   </Grid>
    //                 </Grid>
    //               </Box>
    //             ))
    //         ) : (
    //           <Typography>No comments available.</Typography>
    //         )}
    //       </Box>
    //     </CardContent>
    //   </Card>

    //   <Modal open={showModal} onClose={handleCloseModal}>
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         top: "50%",
    //         left: "50%",
    //         transform: "translate(-50%, -50%)",
    //         width: 400,
    //         bgcolor: "background.paper",
    //         boxShadow: 24,
    //         p: 4,
    //       }}
    //     >
    //       {locationData ? (
    //         <>
    //           <Typography variant="h6">{locationData.name}</Typography>
    //           <Typography>
    //             <strong>Country:</strong> {locationData.country}
    //           </Typography>
    //           <Typography>
    //             <strong>City:</strong> {locationData.city}
    //           </Typography>
    //           <Typography>
    //             <strong>Region:</strong> {locationData.region}
    //           </Typography>
    //           <Carousel interval={3000}>
    //             {images.map((image, index) => (
    //               <div key={index}>
    //                 <img
    //                   style={{ height: "250px", objectFit: "cover" }}
    //                   src={image.url}
    //                   alt={image.alt}
    //                 />
    //               </div>
    //             ))}
    //           </Carousel>
    //           <Box mt={2}>
    //             <Typography variant="h6">Unit Types</Typography>
    //             <Box
    //               sx={{
    //                 maxHeight: "150px",
    //                 overflowY: "auto",
    //                 border: "1px solid #ddd",
    //                 borderRadius: "4px",
    //                 p: 1,
    //               }}
    //             >
    //               {locationData.unitTypes?.map(
    //                 (type: string, index: number) => (
    //                   <Typography
    //                     key={index}
    //                     sx={{ borderBottom: "1px solid #eee", py: 1 }}
    //                   >
    //                     {type}
    //                   </Typography>
    //                 )
    //               )}
    //             </Box>
    //           </Box>
    //           <Box mt={2}>
    //             {locationData.paymentPlans?.map((plan: any, index: number) => (
    //               <Box
    //                 key={index}
    //                 sx={{ borderBottom: "1px solid #eee", py: 1 }}
    //               >
    //                 <Typography>
    //                   {plan.name} ({plan.duration}, {plan.downPayment})
    //                 </Typography>
    //                 <ul>
    //                   {plan.installments.map((inst: any, i: number) => (
    //                     <li key={i}>
    //                       {inst.duration}: {inst.amount}
    //                     </li>
    //                   ))}
    //                 </ul>
    //               </Box>
    //             ))}
    //           </Box>
    //         </>
    //       ) : (
    //         <Typography>Loading location data...</Typography>
    //       )}
    //     </Box>
    //   </Modal>
    // </Container>
    <Container maxWidth="lg">
      <ToastContainer />
      {/* <Card variant="outlined" sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom color="primary">
            View Lead Details
          </Typography>
          {leadData ? (
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Lead Info
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Name:</strong> {leadData.name}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Email:</strong>{" "}
                  {typeof leadData.customer !== "string" &&
                    leadData.customer?.email}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Lead Type:</strong>{" "}
                  {leadData.leadType && typeof leadData.leadType !== "string"
                    ? leadData.leadType.name
                    : "Unknown Lead Type"}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Lead SubType:</strong>{" "}
                  {leadData.leadSubType &&
                  typeof leadData.leadSubType !== "string"
                    ? leadData.leadSubType.name
                    : "Unknown Lead Sub Type"}
                </Typography>
                <Typography sx={{ color: "text.primary", mt: 2 }}>
                  <strong>Status:</strong>
                  {leadData.status && (
                    <Chip
                      label={
                        typeof leadData.status === "object"
                          ? leadData.status.status
                          : "Unknown Status"
                      }
                      sx={{
                        backgroundColor:
                          typeof leadData.status === "object"
                            ? leadData.status.color
                            : "#000",
                        color: "#fff",
                        ml: 1,
                      }}
                    />
                  )}
                </Typography>
                <Typography sx={{ color: "text.primary", mt: 2 }}>
                  <strong>Assigned Sales Agent:</strong>
                  {leadData.assignedSalesAgent && (
                    <Chip
                      label={
                        typeof leadData.assignedSalesAgent === "object"
                          ? leadData.assignedSalesAgent.name
                          : "Unknown Agent"
                      }
                      sx={{
                        backgroundColor:
                          typeof leadData.assignedSalesAgent === "object"
                            ? leadData.assignedSalesAgent.color
                            : "#000",
                        color: "#fff",
                        ml: 1,
                      }}
                    />
                  )}
                </Typography>

                <Typography variant="h6" mt={2} color="secondary">
                  Campaign Info
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Campaign:</strong>{" "}
                  {leadData.campaign && typeof leadData.campaign !== "string"
                    ? leadData.campaign.name
                    : "Unknown Campaign"}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Advertisement:</strong>{" "}
                  {leadData.advertisement &&
                  typeof leadData.advertisement !== "string"
                    ? leadData.advertisement.name
                    : "Unknown Advertisement"}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Ad Group:</strong>{" "}
                  {leadData.adGroup && typeof leadData.adGroup !== "string"
                    ? leadData.adGroup.name
                    : "Unknown AdGroup"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Contact Info
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Primary Phone:</strong>{" "}
                  {typeof leadData.customer !== "string" &&
                  leadData.customer?.primaryPhone.country &&
                  typeof leadData.customer?.primaryPhone.country !== "string"
                    ? leadData.customer?.primaryPhone.country.code
                    : "Unknown Country Code"}{" "}
                  {typeof leadData.customer !== "string" &&
                    leadData.customer?.primaryPhone.phoneNumber}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Sec. Phone:</strong>{" "}
                  {typeof leadData.customer !== "string" &&
                    leadData.customer?.secondaryPhone && (
                      <>
                        {typeof leadData.customer !== "string" &&
                        leadData.customer?.secondaryPhone.country &&
                        typeof leadData.customer?.secondaryPhone.country !==
                          "string"
                          ? leadData.customer?.secondaryPhone.country.code
                          : "Unknown Country Code"}{" "}
                        {typeof leadData.customer !== "string" &&
                          leadData.customer?.secondaryPhone.phoneNumber}
                      </>
                    )}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Landline:</strong>{" "}
                  {typeof leadData.customer !== "string" &&
                    leadData.customer?.landline}
                </Typography>
                <Button
                  startIcon={<PhoneIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog}
                >
                  Call
                </Button>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>Choose a Number to Call</DialogTitle>
                  <DialogContent>
                    <Button
                      startIcon={<PhoneIcon />}
                      href={`tel:${
                        typeof leadData.customer !== "string" &&
                        typeof leadData.customer?.primaryPhone.country ===
                          "object"
                          ? leadData.customer?.primaryPhone.country.code
                          : ""
                      }${
                        typeof leadData.customer !== "string" &&
                        leadData.customer?.primaryPhone.phoneNumber
                      }`}
                      onClick={() =>
                        handleCall(
                          `${
                            typeof leadData.customer !== "string" &&
                            typeof leadData.customer?.primaryPhone.country ===
                              "object"
                              ? leadData.customer?.primaryPhone.country.code
                              : ""
                          }${
                            typeof leadData.customer !== "string" &&
                            leadData.customer?.primaryPhone.phoneNumber
                          }`
                        )
                      }
                      fullWidth
                      variant="contained"
                      sx={{ mt: 2 }}
                    >
                      Primary:{" "}
                      {typeof leadData.customer !== "string" &&
                      typeof leadData.customer?.primaryPhone.country ===
                        "object"
                        ? leadData.customer?.primaryPhone.country.code
                        : ""}{" "}
                      {typeof leadData.customer !== "string" &&
                        leadData.customer?.primaryPhone.phoneNumber}
                    </Button>

                    <Button
                      startIcon={<PhoneIcon />}
                      href={`tel:${
                        typeof leadData.customer !== "string" &&
                        leadData.customer?.secondaryPhone &&
                        typeof leadData.customer?.secondaryPhone.country ===
                          "object"
                          ? leadData.customer?.secondaryPhone.country.code
                          : ""
                      }${
                        typeof leadData.customer !== "string" &&
                        leadData.customer?.secondaryPhone &&
                        leadData.customer?.secondaryPhone.phoneNumber
                      }`}
                      onClick={() =>
                        handleCall(
                          `${
                            typeof leadData.customer !== "string" &&
                            leadData.customer?.secondaryPhone &&
                            typeof leadData.customer?.secondaryPhone.country ===
                              "object"
                              ? leadData.customer?.secondaryPhone.country.code
                              : ""
                          }${
                            typeof leadData.customer !== "string" &&
                            leadData.customer?.secondaryPhone &&
                            leadData.customer?.secondaryPhone.phoneNumber
                          }`
                        )
                      }
                      fullWidth
                      variant="contained"
                      sx={{ mt: 2 }}
                    >
                      Secondary:{" "}
                      {typeof leadData.customer !== "string" &&
                      leadData.customer?.secondaryPhone &&
                      typeof leadData.customer?.secondaryPhone.country ===
                        "object"
                        ? leadData.customer?.secondaryPhone.country.code
                        : ""}{" "}
                      {typeof leadData.customer !== "string" &&
                        leadData.customer?.secondaryPhone &&
                        leadData.customer?.secondaryPhone.phoneNumber}
                    </Button>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                  </DialogActions>
                </Dialog>

                <Typography variant="h6" mt={2} color="secondary">
                  Other Info
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Preferred Call Time:</strong>{" "}
                  {typeof leadData.customer !== "string" &&
                    leadData.customer?.preferredCallTime}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Interested Location:</strong>{" "}
                  {leadData.interestedLocation &&
                  typeof leadData.interestedLocation !== "string"
                    ? leadData.interestedLocation.name
                    : "Unknown Location"}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Budget Details:</strong>{" "}
                  {leadData.budgetDetails?.value}{" "}
                  {leadData.budgetDetails?.currency}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Sales Info
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Assigned Sales Agent:</strong>{" "}
                  {leadData.assignedSalesAgent &&
                  typeof leadData.assignedSalesAgent !== "string"
                    ? leadData.assignedSalesAgent.name
                    : "Unknown User Agent"}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Tracking ID:</strong> {leadData.trackingID}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Contact Method:</strong>{" "}
                  {typeof leadData.customer !== "string" &&
                  leadData.customer?.contactMethod &&
                  typeof leadData.customer?.contactMethod !== "string"
                    ? leadData.customer?.contactMethod.name
                    : "Unknown Contact Method"}
                </Typography>
                <Typography variant="h6" mt={2} color="secondary">
                  Notes
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  {leadData.notes}
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleShowModal}
                  startIcon={<i className="bi bi-geo-alt"></i>}
                  sx={{ backgroundColor: "primary.main", mt: 2 }}
                >
                  Show Location Details
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Update Status
                </Typography>
                <Select
                  fullWidth
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  disabled={isStatusLoading}
                  sx={{ mt: 1 }}
                >
                  {leadStatuses?.data?.map((status) => (
                    <MenuItem
                      sx={{
                        backgroundColor: status.color,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: lighten(status.color, 0.2),
                        },
                      }}
                      key={status._id}
                      value={status._id}
                    >
                      {status.status}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Update Assigned Sales Agent
                </Typography>
                <Select
                  fullWidth
                  value={selectedAgent}
                  onChange={handleAgentChange}
                  sx={{ mt: 1 }}
                >
                  {subordinates?.map((agent) => (
                    <MenuItem
                      sx={{
                        backgroundColor: agent.color,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: lighten(agent.color, 0.2),
                        },
                      }}
                      key={agent._id}
                      value={agent._id}
                    >
                      {agent.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </CardContent>
      </Card> */}

      <Card variant="outlined" sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom color="primary">
            View Lead Details
          </Typography>
          {leadData ? (
            <Grid container spacing={3}>
              {/* Lead Info (Always visible) */}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Lead Info
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Name:</strong> {leadData.name}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Email:</strong>{" "}
                  {typeof leadData.customer !== "string" &&
                    leadData.customer?.email}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Lead Type:</strong>{" "}
                  {leadData.leadType && typeof leadData.leadType !== "string"
                    ? leadData.leadType.name
                    : "Unknown Lead Type"}
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  <strong>Lead SubType:</strong>{" "}
                  {leadData.leadSubType &&
                  typeof leadData.leadSubType !== "string"
                    ? leadData.leadSubType.name
                    : "Unknown Lead Sub Type"}
                </Typography>
                <Typography sx={{ color: "text.primary", mt: 2 }}>
                  <strong>Status:</strong>
                  {leadData.status && (
                    <Chip
                      label={
                        typeof leadData.status === "object"
                          ? leadData.status.status
                          : "Unknown Status"
                      }
                      sx={{
                        backgroundColor:
                          typeof leadData.status === "object"
                            ? leadData.status.color
                            : "#000",
                        color: "#fff",
                        ml: 1,
                      }}
                    />
                  )}
                </Typography>
                <Typography sx={{ color: "text.primary", mt: 2 }}>
                  <strong>Assigned Sales Agent:</strong>
                  {leadData.assignedSalesAgent && (
                    <Chip
                      label={
                        typeof leadData.assignedSalesAgent === "object"
                          ? leadData.assignedSalesAgent.name
                          : "Unknown Agent"
                      }
                      sx={{
                        backgroundColor:
                          typeof leadData.assignedSalesAgent === "object"
                            ? leadData.assignedSalesAgent.color
                            : "#000",
                        color: "#fff",
                        ml: 1,
                      }}
                    />
                  )}
                </Typography>

                <Button
                  variant="contained"
                  onClick={handleShowModal}
                  startIcon={<Map />}
                  sx={{ backgroundColor: "primary.main", mt: 2 }}
                >
                  Show Location
                </Button>
              </Grid>

              {/* Accordion for Campaign Info */}
              <Grid item xs={12} md={8}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="campaign-info-content"
                    id="campaign-info-header"
                  >
                    <Typography variant="h6" color="secondary">
                      Campaign Info
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Campaign:</strong>{" "}
                      {leadData.campaign &&
                      typeof leadData.campaign !== "string"
                        ? leadData.campaign.name
                        : "Unknown Campaign"}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Advertisement:</strong>{" "}
                      {leadData.advertisement &&
                      typeof leadData.advertisement !== "string"
                        ? leadData.advertisement.name
                        : "Unknown Advertisement"}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Ad Group:</strong>{" "}
                      {leadData.adGroup && typeof leadData.adGroup !== "string"
                        ? leadData.adGroup.name
                        : "Unknown AdGroup"}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion for Contact Info */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="contact-info-content"
                    id="contact-info-header"
                  >
                    <Typography variant="h6" color="secondary">
                      Contact Info
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Primary Phone:</strong>{" "}
                      {typeof leadData.customer !== "string" &&
                      leadData.customer?.primaryPhone.country &&
                      typeof leadData.customer?.primaryPhone.country !==
                        "string"
                        ? leadData.customer?.primaryPhone.country.code
                        : "Unknown Country Code"}{" "}
                      {typeof leadData.customer !== "string" &&
                        leadData.customer?.primaryPhone.phoneNumber}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Sec. Phone:</strong>{" "}
                      {typeof leadData.customer !== "string" &&
                        leadData.customer?.secondaryPhone && (
                          <>
                            {typeof leadData.customer !== "string" &&
                            leadData.customer?.secondaryPhone.country &&
                            typeof leadData.customer?.secondaryPhone.country !==
                              "string"
                              ? leadData.customer?.secondaryPhone.country.code
                              : "Unknown Country Code"}{" "}
                            {typeof leadData.customer !== "string" &&
                              leadData.customer?.secondaryPhone.phoneNumber}
                          </>
                        )}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Landline:</strong>{" "}
                      {typeof leadData.customer !== "string" &&
                        leadData.customer?.landline}
                    </Typography>
                    <Button
                      startIcon={<PhoneIcon />}
                      variant="contained"
                      color="primary"
                      onClick={handleOpenDialog}
                    >
                      Call
                    </Button>
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                      <DialogTitle>Choose a Number to Call</DialogTitle>
                      <DialogContent>
                        <Button
                          startIcon={<PhoneIcon />}
                          onClick={(event) => {
                            const phoneNumber = `${
                              typeof leadData.customer !== "string" &&
                              typeof leadData.customer?.primaryPhone.country ===
                                "object"
                                ? leadData.customer?.primaryPhone.country.code
                                : ""
                            }${
                              typeof leadData.customer !== "string" &&
                              leadData.customer?.primaryPhone.phoneNumber
                            }`;

                            // Optionally, prevent default action if you still want to use href for some reason
                            event.preventDefault();

                            // Call your handleCall function with the phoneNumber
                            handleCall(phoneNumber, event);
                          }}
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2 }}
                        >
                          Primary:{" "}
                          {typeof leadData.customer !== "string" &&
                          typeof leadData.customer?.primaryPhone.country ===
                            "object"
                            ? leadData.customer?.primaryPhone.country.code
                            : ""}{" "}
                          {typeof leadData.customer !== "string" &&
                            leadData.customer?.primaryPhone.phoneNumber}
                        </Button>

                        <Button
                          startIcon={<PhoneIcon />}
                          onClick={(event) => {
                            const phoneNumber = `${
                              typeof leadData.customer !== "string" &&
                              leadData.customer?.secondaryPhone &&
                              typeof leadData.customer?.secondaryPhone
                                .country === "object"
                                ? leadData.customer?.secondaryPhone.country.code
                                : ""
                            }${
                              typeof leadData.customer !== "string" &&
                              leadData.customer?.secondaryPhone &&
                              leadData.customer?.secondaryPhone.phoneNumber
                            }`;

                            // Optionally, prevent default action if you still want to use href for some reason
                            event.preventDefault();

                            // Call your handleCall function with the phoneNumber
                            handleCall(phoneNumber, event);
                          }}
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2 }}
                        >
                          Secondary:{" "}
                          {typeof leadData.customer !== "string" &&
                          leadData.customer?.secondaryPhone &&
                          typeof leadData.customer?.secondaryPhone.country ===
                            "object"
                            ? leadData.customer?.secondaryPhone.country.code
                            : ""}{" "}
                          {typeof leadData.customer !== "string" &&
                            leadData.customer?.secondaryPhone &&
                            leadData.customer?.secondaryPhone.phoneNumber}
                        </Button>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                      </DialogActions>
                    </Dialog>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion for Other Info */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="other-info-content"
                    id="other-info-header"
                  >
                    <Typography variant="h6" color="secondary">
                      Other Info
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Preferred Call Time:</strong>{" "}
                      {typeof leadData.customer !== "string" &&
                        leadData.customer?.preferredCallTime}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Interested Location:</strong>{" "}
                      {leadData.interestedLocation &&
                      typeof leadData.interestedLocation !== "string"
                        ? leadData.interestedLocation.name
                        : "Unknown Location"}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Budget Details:</strong>{" "}
                      {leadData.budgetDetails?.value}{" "}
                      {leadData.budgetDetails?.currency}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion for Sales Info */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="sales-info-content"
                    id="sales-info-header"
                  >
                    <Typography variant="h6" color="secondary">
                      Sales Info
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Assigned Sales Agent:</strong>{" "}
                      {leadData.assignedSalesAgent &&
                      typeof leadData.assignedSalesAgent !== "string"
                        ? leadData.assignedSalesAgent.name
                        : "Unknown User Agent"}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Tracking ID:</strong> {leadData.trackingID}
                    </Typography>
                    <Typography sx={{ color: "text.primary" }}>
                      <strong>Contact Method:</strong>{" "}
                      {typeof leadData.customer !== "string" &&
                      leadData.customer?.contactMethod &&
                      typeof leadData.customer?.contactMethod !== "string"
                        ? leadData.customer?.contactMethod.name
                        : "Unknown Contact Method"}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion for Notes */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="notes-content"
                    id="notes-header"
                  >
                    <Typography variant="h6" color="secondary">
                      Notes
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: "text.primary" }}>
                      {leadData.notes || "No Notes Available"}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                {/* Show Location Details Button */}
                {/* <IconButton
                  aria-label="show location details"
                  color="primary"
                  onClick={handleShowModal}
                >
                  Show Location Details <Map />
                </IconButton> */}
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Update Status
                </Typography>
                <Select
                  fullWidth
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  disabled={isStatusLoading}
                  sx={{ mt: 1 }}
                >
                  {leadStatuses?.data?.map((status) => (
                    <MenuItem
                      sx={{
                        backgroundColor: status.color,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: lighten(status.color, 0.2),
                        },
                      }}
                      key={status._id}
                      value={status._id}
                    >
                      {status.status}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h6" color="secondary">
                  Update Assigned Sales Agent
                </Typography>
                <Select
                  fullWidth
                  value={selectedAgent}
                  onChange={handleAgentChange}
                  sx={{ mt: 1 }}
                >
                  {subordinates?.map((agent) => (
                    <MenuItem
                      sx={{
                        backgroundColor: agent.color,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: lighten(agent.color, 0.2),
                        },
                      }}
                      key={agent._id}
                      value={agent._id}
                    >
                      {agent.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom color="primary">
            Comments & Transactions
          </Typography>
          {/* Tabs for Comments and Transactions */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ backgroundColor: indigo[200] }}
          >
            <Tab label="Comments" />
            <Tab label="Transactions" />
          </Tabs>
          <Box mt={2}>
            {tabValue === 0 ? (
              // Render Comments
              <>
                <ReactQuill
                  theme="snow"
                  placeholder="Leave a comment"
                  value={comment}
                  onChange={setComment}
                  style={{ height: "150px", marginBottom: "1rem" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddComment}
                  startIcon={<Send />}
                >
                  Send Comment
                </Button>
                {leadData?.comments && leadData.comments.length > 0 ? (
                  leadData.comments
                    .slice()
                    .reverse()
                    .map((comment: Comment, index: number) => (
                      <Box key={index} mt={2}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <Avatar
                              src={
                                comment?.user &&
                                typeof comment?.user !== "string" &&
                                comment.user.avatar &&
                                typeof comment.user.avatar !== "string"
                                  ? comment.user.avatar.url
                                  : "Unknown Avatar"
                              }
                              alt={
                                comment?.user &&
                                typeof comment?.user !== "string"
                                  ? comment.user.name
                                  : "Unknown User"
                              }
                              sx={{
                                border: "2px solid",
                                borderColor: "secondary.main",
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{ color: "text.secondary" }}
                            >
                              <strong>
                                {comment?.user &&
                                typeof comment?.user !== "string"
                                  ? comment?.user.name
                                  : "Unknown User"}
                              </strong>{" "}
                              -{" "}
                              {moment(comment?.createdAt).format(
                                "MMMM D, YYYY h:mm A"
                              )}
                            </Typography>
                            <Box
                              mt={1}
                              sx={{
                                border: "1px solid #ddd",
                                borderRadius: "0.25rem",
                                padding: "1rem",
                                backgroundColor: "background.default",
                              }}
                            >
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: comment?.comment,
                                }}
                                sx={{ color: "text.primary" }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                ) : (
                  <Typography>No comments available.</Typography>
                )}
              </>
            ) : (
              // Render Transactions
              <>
                {leadTransactions?.data && leadTransactions.data.length > 0 ? (
                  leadTransactions.data.map(
                    (transaction: LeadTransaction, index: number) => {
                      if (
                        transaction.status &&
                        typeof transaction.status !== "string" &&
                        typeof transaction.status.from !== "string" &&
                        typeof transaction.status.to !== "string" &&
                        typeof transaction.status.operatedBy !== "string"
                      ) {
                        // Render status transaction
                        return (
                          <Box key={index} mt={2}>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item>
                                <IconButton>
                                  <Sync color="primary" />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Status changed from
                                  <Chip
                                    label={transaction.status.from?.status}
                                    style={{
                                      backgroundColor:
                                        transaction.status.from?.color ||
                                        "#ccc",
                                      color: "#fff",
                                      margin: "0 5px",
                                    }}
                                  />
                                  to
                                  <Chip
                                    label={transaction.status.to?.status}
                                    style={{
                                      backgroundColor:
                                        transaction.status.to?.color || "#ccc",
                                      color: "#fff",
                                      margin: "0 5px",
                                    }}
                                  />
                                  by{" "}
                                  <strong>
                                    {transaction.status.operatedBy?.name}
                                  </strong>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {moment(transaction.createdAt).format(
                                    "MMMM D, YYYY h:mm A"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      } else if (
                        transaction.assigned &&
                        typeof transaction.assigned !== "string" &&
                        typeof transaction.assigned.from !== "string" &&
                        typeof transaction.assigned.to !== "string" &&
                        typeof transaction.assigned.operatedBy !== "string"
                      ) {
                        // Render assigned transaction
                        return (
                          <Box key={index} mt={2}>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item>
                                <IconButton>
                                  <Person color="primary" />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Assigned changed from
                                  <Chip
                                    label={transaction.assigned.from?.name}
                                    style={{
                                      backgroundColor:
                                        transaction.assigned.from?.color ||
                                        "#ccc",
                                      color: "#fff",
                                      margin: "0 5px",
                                    }}
                                  />
                                  to
                                  <Chip
                                    label={transaction.assigned.to?.name}
                                    style={{
                                      backgroundColor:
                                        transaction.assigned.to?.color ||
                                        "#ccc",
                                      color: "#fff",
                                      margin: "0 5px",
                                    }}
                                  />
                                  by{" "}
                                  <strong>
                                    {transaction.assigned.operatedBy?.name}
                                  </strong>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {moment(transaction.createdAt).format(
                                    "MMMM D, YYYY h:mm A"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      } else if (
                        transaction.call &&
                        typeof transaction.call !== "string" &&
                        typeof transaction.call.callMadeBy !== "string"
                      ) {
                        // Render call transaction
                        return (
                          <Box key={index} mt={2}>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item>
                                <IconButton>
                                  <Call color="primary" />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Call to{" "}
                                  <strong>
                                    {transaction.call?.phoneNumber}
                                  </strong>{" "}
                                  made by{" "}
                                  <strong>
                                    {transaction.call.callMadeBy?.name}
                                  </strong>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {moment(transaction.createdAt).format(
                                    "MMMM D, YYYY h:mm A"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    }
                  )
                ) : (
                  <Typography>No transactions available.</Typography>
                )}
              </>
            )}
          </Box>
        </CardContent>
      </Card>

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          {locationData ? (
            <>
              <Typography variant="h6" color="primary">
                {locationData.name}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                <strong>Country:</strong> {locationData.country}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                <strong>City:</strong> {locationData.city}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                <strong>Region:</strong> {locationData.region}
              </Typography>
              <Carousel interval={3000}>
                {images.map((image, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={
                      typeof image === "string"
                        ? image
                        : (image as ImageType).url
                    }
                    alt={`Location Image ${index + 1}`}
                    sx={{
                      height: "300px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                ))}
              </Carousel>
              <Typography variant="h6" mt={2} color="primary">
                Unit Types
              </Typography>
              {locationData.unitTypes.map((unitType, index) => (
                <Typography key={index}>{unitType}</Typography>
              ))}
              <Typography variant="h6" mt={2} color="primary">
                Payment Plans
              </Typography>
              {locationData.paymentPlans.map((paymentPlan, index) => (
                // <Typography key={index} sx={{ color: "text.secondary" }}>
                //   {paymentPlan.name} - {paymentPlan.duration}
                // </Typography>
                <>
                  <Typography>
                    {paymentPlan.name} ({paymentPlan.duration},{" "}
                    {paymentPlan.downPayment})
                  </Typography>
                  <ul>
                    {paymentPlan.installments.map((inst: any, i: number) => (
                      <li key={i}>
                        {inst.duration}: {inst.amount}
                      </li>
                    ))}
                  </ul>
                </>
              ))}
            </>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default ViewLead;
