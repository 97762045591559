import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Pagination,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { Company } from "../../../interfaces/Company";
import {
  useDeleteAdminCompanyMutation,
  useGetAdminCompaniesQuery,
} from "../../../features/company/adminCompanySlice";

const CompanyList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data, error, isLoading, refetch } = useGetAdminCompaniesQuery({
    page,
    limit,
  });
  const [deleteCompany] = useDeleteAdminCompanyMutation();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    null
  );
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const addCompany = () => {
    navigate("/admin-dashboard/add-company");
  };

  const handleDelete = async () => {
    if (selectedCompanyId) {
      await deleteCompany(selectedCompanyId);
      setShowDialog(false);
      refetch();
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  if (isLoading) return <CircularProgress />;
  if (error)
    return <Typography color="error">Error loading companies</Typography>;

  const totalPages = Math.ceil((data?.total || 0) / limit);

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Company Table
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        onClick={addCompany}
        color="primary"
      >
        Add New Company
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((company: Company) => (
              <TableRow key={company._id}>
                <TableCell>{company.name}</TableCell>
                <TableCell>
                  {`${company.contactPersonInformation.firstName} ${company.contactPersonInformation.lastName}`}
                </TableCell>
                <TableCell>{company.contactPersonInformation.email}</TableCell>
                <TableCell>
                  <IconButton
                    component={Link}
                    to={`/admin-dashboard/view-company/${company._id}`}
                  >
                    <Visibility />
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={`/admin-dashboard/edit-company/${company._id}`}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedCompanyId(company._id ?? null);
                      setShowDialog(true);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Box>
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this company?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Box>
  );
};

export default CompanyList;
