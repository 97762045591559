import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa; // light gray background
`;

const WarningIcon = styled.i`
  font-size: 8rem; // large icon
  color: #dc3545; // Bootstrap's danger color (red)
  margin-bottom: 20px;
`;

const Message = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  font-size: 1.25rem;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff; // Bootstrap's primary color (blue)
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; // darker blue
  }
`;

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <UnauthorizedContainer>
      <WarningIcon className="bi bi-exclamation-triangle-fill"></WarningIcon>
      <Message>Unauthorized Access</Message>
      <p>You do not have permission to view this page.</p>
      <BackButton onClick={handleBackClick}>Go Back</BackButton>
    </UnauthorizedContainer>
  );
};

export default Unauthorized;
