// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import {
//   Box,
//   Avatar,
//   Typography,
//   TextField,
//   Grid,
//   Tabs,
//   Tab,
//   MenuItem,
//   CircularProgress,
// } from "@mui/material";
// import { useGetAdminCompanyQuery } from "../../../features/company/adminCompanySlice";
// import { useUpdateSubscriptionMutation } from "../../../features/subscription/subscriptionSlice";
// import { useUpdatePaymentMutation } from "../../../features/payment/paymentSlice";
// import {
//   Subscription,
//   SubscriptionStatus,
// } from "../../../interfaces/Subscription";
// import { Payment, PaymentStatus } from "../../../interfaces/Payment";

// const ViewCompany: React.FC = () => {
//   const { id } = useParams<{ id: string }>();
//   const {
//     data: companyData,
//     isLoading,
//     refetch,
//   } = useGetAdminCompanyQuery(id!);
//   const [updateSubscription] = useUpdateSubscriptionMutation();
//   const [updatePayment] = useUpdatePaymentMutation();
//   const [selectedTab, setSelectedTab] = useState<number>(0);

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
//     setSelectedTab(newValue);
//   };

//   if (isLoading || !companyData) {
//     return <CircularProgress />;
//   }

//   // Type guard to ensure subscription is not a string
//   const isSubscriptionObject = (
//     subscription: any
//   ): subscription is Subscription => {
//     return typeof subscription !== "string" && subscription !== null;
//   };

//   // Type guard to ensure payment is not a string
//   const isPaymentObject = (payment: any): payment is Payment => {
//     return typeof payment !== "string" && payment !== null;
//   };

//   const handleSubscriptionStatusChange = async (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     if (isSubscriptionObject(companyData.subscription)) {
//       const newStatus = event.target.value as SubscriptionStatus;
//       await updateSubscription({
//         id: companyData.subscription._id!, // Here we know _id exists since subscription is not a string
//         subscription: { status: newStatus },
//       });
//     }
//   };

//   const handlePaymentStatusChange = async (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     if (
//       isSubscriptionObject(companyData.subscription) &&
//       isPaymentObject(companyData.subscription.payment)
//     ) {
//       const newStatus = event.target.value as PaymentStatus;
//       await updatePayment({
//         id: companyData.subscription.payment._id!, // We know _id exists since payment is an object
//         payment: { status: newStatus },
//       });
//     }
//   };

//   return (
//     <Box p={3}>
//       <Grid container spacing={3}>
//         <Grid item xs={6}>
//           <Typography variant="h4">{companyData.name}</Typography>
//           <Avatar
//             src={
//               typeof companyData.logo === "string"
//                 ? companyData.logo
//                 : companyData.logo?.url
//             }
//             alt="Company Logo"
//             sx={{ width: 100, height: 100 }}
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant="h6">Contact Information</Typography>
//           <TextField
//             fullWidth
//             label="First Name"
//             value={companyData.contactPersonInformation.firstName}
//             variant="outlined"
//             margin="normal"
//           />
//           <TextField
//             fullWidth
//             label="Last Name"
//             value={companyData.contactPersonInformation.lastName}
//             variant="outlined"
//             margin="normal"
//           />
//           <TextField
//             fullWidth
//             label="Email"
//             value={companyData.contactPersonInformation.email}
//             variant="outlined"
//             margin="normal"
//           />
//         </Grid>
//       </Grid>

//       <Tabs
//         value={selectedTab}
//         onChange={handleChangeTab}
//         aria-label="company tabs"
//       >
//         <Tab label="Subscription" />
//         <Tab label="Payment" />
//       </Tabs>
//       {selectedTab === 0 && (
//         <Box mt={2}>
//           <Typography variant="h6">Subscription</Typography>
//           {isSubscriptionObject(companyData.subscription) ? (
//             <TextField
//               fullWidth
//               select
//               label="Subscription Status"
//               value={companyData.subscription.status || ""}
//               onChange={handleSubscriptionStatusChange}
//               variant="outlined"
//               margin="normal"
//             >
//               {Object.values(SubscriptionStatus).map((status) => (
//                 <MenuItem key={status} value={status}>
//                   {status}
//                 </MenuItem>
//               ))}
//             </TextField>
//           ) : (
//             <Typography color="error">Invalid subscription data</Typography>
//           )}
//         </Box>
//       )}
//       {selectedTab === 1 && (
//         <Box mt={2}>
//           <Typography variant="h6">Payment</Typography>
//           {isSubscriptionObject(companyData.subscription) &&
//           isPaymentObject(companyData.subscription.payment) ? (
//             <TextField
//               fullWidth
//               select
//               label="Payment Status"
//               value={companyData.subscription.payment.status || ""}
//               onChange={handlePaymentStatusChange}
//               variant="outlined"
//               margin="normal"
//             >
//               {Object.values(PaymentStatus).map((status) => (
//                 <MenuItem key={status} value={status}>
//                   {status}
//                 </MenuItem>
//               ))}
//             </TextField>
//           ) : (
//             <Typography color="error">Invalid payment data</Typography>
//           )}
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ViewCompany;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  Grid,
  Tabs,
  Tab,
  MenuItem,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import { useGetAdminCompanyQuery } from "../../../features/company/adminCompanySlice";
import { useUpdateAdminSubscriptionMutation } from "../../../features/subscription/adminSubscriptionSlice";
import { useUpdateAdminPaymentMutation } from "../../../features/payment/adminPaymentSlice";
import {
  Subscription,
  SubscriptionStatus,
} from "../../../interfaces/Subscription";
import { Payment, PaymentStatus } from "../../../interfaces/Payment";

const ViewCompany: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: companyData,
    isLoading,
    refetch,
  } = useGetAdminCompanyQuery(id!);
  const [updateSubscription] = useUpdateAdminSubscriptionMutation();
  const [updatePayment] = useUpdateAdminPaymentMutation();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [transactionId, setTransactionId] = useState<string>(""); // State for transaction ID

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (
      companyData &&
      companyData.subscription &&
      typeof companyData.subscription !== "string" &&
      companyData.subscription &&
      typeof companyData.subscription !== "string" &&
      companyData.subscription.payment &&
      typeof companyData.subscription.payment !== "string"
    ) {
      setTransactionId(companyData.subscription.payment.transactionId || "");
    }
  }, [companyData]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (isLoading || !companyData) {
    return <CircularProgress />;
  }

  const isSubscriptionObject = (
    subscription: any
  ): subscription is Subscription => {
    return typeof subscription !== "string" && subscription !== null;
  };

  const isPaymentObject = (payment: any): payment is Payment => {
    return typeof payment !== "string" && payment !== null;
  };

  const handleSubscriptionStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isSubscriptionObject(companyData.subscription)) {
      const newStatus = event.target.value as SubscriptionStatus;
      await updateSubscription({
        id: companyData.subscription._id!,
        subscription: { status: newStatus },
      });
    }
  };

  const handlePaymentStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      isSubscriptionObject(companyData.subscription) &&
      isPaymentObject(companyData.subscription.payment)
    ) {
      const newStatus = event.target.value as PaymentStatus;
      await updatePayment({
        id: companyData.subscription.payment._id!,
        payment: { status: newStatus },
      });
    }
  };

  const handleTransactionIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTransactionId(event.target.value);
  };

  const handleUpdateTransactionId = async () => {
    if (
      isSubscriptionObject(companyData.subscription) &&
      isPaymentObject(companyData.subscription.payment)
    ) {
      await updatePayment({
        id: companyData.subscription.payment._id!,
        payment: { transactionId },
      });
    }
  };

  // Helper function to format dates
  const formatDate = (dateString?: string) => {
    return dateString ? moment(dateString).format("MM/DD/YYYY h:mm A") : "";
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h4">{companyData.name}</Typography>
          <Avatar
            src={
              typeof companyData.logo === "string"
                ? companyData.logo
                : companyData.logo?.url
            }
            alt="Company Logo"
            sx={{ width: 100, height: 100 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Contact Information</Typography>
          <TextField
            fullWidth
            label="First Name"
            value={companyData.contactPersonInformation.firstName}
            variant="outlined"
            margin="normal"
            disabled
          />
          <TextField
            fullWidth
            label="Last Name"
            value={companyData.contactPersonInformation.lastName}
            variant="outlined"
            margin="normal"
            disabled
          />
          <TextField
            fullWidth
            label="Email"
            value={companyData.contactPersonInformation.email}
            variant="outlined"
            margin="normal"
            disabled
          />
        </Grid>
      </Grid>

      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        aria-label="company tabs"
      >
        <Tab label="Subscription" />
        <Tab label="Payment" />
        <Tab label="Pricing Plan" /> {/* New Tab for Pricing Plan */}
      </Tabs>

      {selectedTab === 0 && (
        <Box mt={2}>
          <Typography variant="h6">Subscription Details</Typography>
          {isSubscriptionObject(companyData.subscription) ? (
            <>
              <TextField
                fullWidth
                select
                label="Subscription Status"
                value={companyData.subscription.status || ""}
                onChange={handleSubscriptionStatusChange}
                variant="outlined"
                margin="normal"
              >
                {Object.values(SubscriptionStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Paid Amount"
                value={companyData.subscription.paidAmount}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="Start Date"
                value={formatDate(companyData.subscription.startDate)}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="End Date"
                value={formatDate(companyData.subscription.endDate)}
                variant="outlined"
                margin="normal"
                disabled
              />
            </>
          ) : (
            <Typography color="error">Invalid subscription data</Typography>
          )}
        </Box>
      )}

      {selectedTab === 1 && (
        <Box mt={2}>
          <Typography variant="h6">Payment Details</Typography>
          {isSubscriptionObject(companyData.subscription) &&
          isPaymentObject(companyData.subscription.payment) ? (
            <>
              <TextField
                fullWidth
                select
                label="Payment Status"
                value={companyData.subscription.payment.status || ""}
                onChange={handlePaymentStatusChange}
                variant="outlined"
                margin="normal"
              >
                {Object.values(PaymentStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                label="Payment Method"
                value={companyData.subscription.payment.method}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="Amount"
                value={companyData.subscription.payment.amount}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="Transaction ID"
                value={transactionId}
                onChange={handleTransactionIdChange}
                variant="outlined"
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateTransactionId}
              >
                Update Transaction ID
              </Button>
            </>
          ) : (
            <Typography color="error">Invalid payment data</Typography>
          )}
        </Box>
      )}

      {selectedTab === 2 && (
        <Box mt={2}>
          <Typography variant="h6">Pricing Plan Details</Typography>
          {isSubscriptionObject(companyData.subscription) &&
          typeof companyData.subscription.pricingPlan !== "string" &&
          typeof companyData.subscription.pricingPlan.duration !== "string" ? (
            <>
              <TextField
                fullWidth
                label="Plan Name"
                value={companyData.subscription.pricingPlan.name}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="Plan Description"
                value={companyData.subscription.pricingPlan.description}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="No. of Users"
                value={companyData.subscription.pricingPlan.noOfUsers}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="Plan Duration"
                value={companyData.subscription.pricingPlan.duration?.duration}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="Plan Price"
                value={companyData.subscription.pricingPlan.price}
                variant="outlined"
                margin="normal"
                disabled
              />
              <Typography variant="h6" mt={2}>
                Features
              </Typography>
              <List>
                {companyData.subscription.pricingPlan.features?.map(
                  (feature, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={feature} />
                    </ListItem>
                  )
                ) || (
                  <ListItem>
                    <ListItemText primary="No features available" />
                  </ListItem>
                )}
              </List>
            </>
          ) : (
            <Typography color="error">Invalid pricing plan data</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ViewCompany;
