// import { createSlice } from "@reduxjs/toolkit"

// const authSlice = createSlice({
//     name: 'auth',
//     initialState: { user: null, token: null, refreshToken: null },
//     reducers: {
//         setCredentials: (state, action) => {
//             const { user, accessToken, refreshToken } = action.payload;
//             // state.user = user;
//             // state.token = accessToken;
//             // state.refreshToken = refreshToken;
//             if (user) {
//                 state.user = user;
//             }
//             if (accessToken) {
//                 state.token = accessToken;
//             }
//             if (refreshToken) {
//                 state.refreshToken = refreshToken;
//             }
//         },
//         logOut: (state, action) => {
//             state.user = null;
//             state.token = null;
//             state.refreshToken = null;
//         }
//     }
// })

// export const { setCredentials, logOut } = authSlice.actions;

// export default authSlice.reducer;

// export const selectCurrentUser = (state) => state.auth.user;
// export const selectCurrentToken = (state) => state.auth.token;
// export const selectCurrentUserRole = (state) => state.auth.user?.role;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../interfaces/User";
import { AppDispatch } from "../store";

interface AuthState {
  user: any | null;
  token: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  refreshToken: null,
};

// Thunk for handling sign-out
export const signOut = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  "auth/signOut",
  async (_, { dispatch }) => {
    // Clear state by dispatching logOut action
    dispatch(logOut());
    // Navigate to the login page after state is cleared
    window.location.href = "/login";
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      action: PayloadAction<{
        user?: User;
        accessToken?: string;
        refreshToken?: string;
      }>
    ) => {
      const { user, accessToken, refreshToken } = action.payload;
      if (user) {
        state.user = user;
      }
      if (accessToken) {
        state.token = accessToken;
      }
      if (refreshToken) {
        state.refreshToken = refreshToken;
      }
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: { auth: AuthState }) =>
  state.auth.user;
export const selectCurrentToken = (state: { auth: AuthState }) =>
  state.auth.token;
export const selectCurrentUserRole = (state: { auth: AuthState }) =>
  state.auth.user?.role;
export const selectCurrentCompany = (state: { auth: AuthState }) =>
  state.auth.user?.company;
