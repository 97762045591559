import React, { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import { useGetCustomerQuery } from "../../../features/customer/customerSlice";
import { Lead } from "../../../interfaces/Lead"; // Assuming lead interface is defined

const ViewCustomer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: customerData,
    isLoading,
    error,
    isSuccess,
    refetch,
  } = useGetCustomerQuery(id!);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading customer details</Typography>;

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {customerData?.name}
        </Typography>

        <Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Email: {customerData?.email}
            </Typography>
            <Typography variant="body1">
              Primary Phone:{" "}
              {typeof customerData?.primaryPhone.country === "object"
                ? customerData?.primaryPhone.country.code
                : ""}{" "}
              {customerData?.primaryPhone.phoneNumber}
            </Typography>
            <Typography variant="body1">
              Secondary Phone:{" "}
              {customerData?.secondaryPhone?.phoneNumber || "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Landline: {customerData?.landline || "N/A"}
            </Typography>
            <Typography variant="body1">
              Preferred Call Time: {customerData?.preferredCallTime || "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mt: 4 }}>
          Associated Leads
        </Typography>

        <div style={{ overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lead Name</TableCell>
                <TableCell>Lead Type</TableCell>
                <TableCell>Lead SubType</TableCell>
                <TableCell>Assigned Sales Agent</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Lead Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData?.leads?.map((lead: Lead) => (
                <TableRow key={lead._id}>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>
                    {lead.leadType && typeof lead.leadType !== "string"
                      ? lead.leadType.name
                      : "Unknown Lead Type"}
                  </TableCell>
                  <TableCell>
                    {lead.leadSubType && typeof lead.leadSubType !== "string"
                      ? lead.leadSubType.name
                      : "Unknown Lead Sub Type"}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor:
                        lead.assignedSalesAgent &&
                        typeof lead.assignedSalesAgent !== "string"
                          ? `${lead.assignedSalesAgent.color}` // Replace with the hex color you want ff5733
                          : "#ffcccb", // Optionally, a different color for unknown agent ffcccb
                      fontWeight: "bold",
                    }}
                  >
                    {lead.assignedSalesAgent &&
                    typeof lead.assignedSalesAgent !== "string"
                      ? `${lead.assignedSalesAgent.name}`
                      : "Unknown Assigned Sales Agent"}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor:
                        lead.status && typeof lead.status !== "string"
                          ? `${lead.status.color}` // Replace with the hex color you want ff5733
                          : "#ffcccb", // Optionally, a different color for unknown status
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow text
                      textOverflow: "ellipsis", // Add ellipsis for overflow text if needed
                      fontWeight: "bold",
                    }}
                  >
                    {lead.status && typeof lead.status !== "string"
                      ? `${lead.status.status}`
                      : "Unknown Status"}
                  </TableCell>
                  <TableCell>{lead.notes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Box textAlign="center" mt={2}>
          <NavLink to="/dashboard/customer">Back to Customer List</NavLink>
        </Box>
      </Box>
    </Container>
  );
};

export default ViewCustomer;
