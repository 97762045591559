// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { useDeleteCampaignMutation, useGetCampaignsQuery } from '../../../../features/campaign/campaignSlice';
// import ConfirmationDialog from '../common/ConfirmationDialog';

// const CampaignList = () => {
//     const { data, error, isLoading, refetch } = useGetCampaignsQuery();
//     const [deleteCampaign] = useDeleteCampaignMutation();
//     const navigate = useNavigate();
//     const [showDialog, setShowDialog] = useState(false);
//     const [selectedCampaignId, setSelectedCampaignId] = useState(null);

//     const addCampaign = () => {
//         navigate('/dashboard/add-campaign');
//     };

//     const handleDelete = async (e) => {
//         e.preventDefault();
//         if (selectedCampaignId) {
//             await deleteCampaign(selectedCampaignId);
//             setShowDialog(false);
//             refetch();
//         }
//     };

//     if (isLoading) return <div>Loading...</div>;
//     if (error) return <div>Error loading campaigns</div>;

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Campaign Table</h4>
//                             </div>
//                             <button id="table2-new-row-button" className="btn btn-primary mb-4 mr-2" onClick={addCampaign}>
//                                 Add New Campaign
//                             </button>
//                         </div>
//                         <div className="card-body">
//                             <div className="table-responsive border-top userlist-table">
//                                 <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
//                                     <thead>
//                                         <tr>
//                                             <th>Campaign Name</th>
//                                             <th>Actions</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {data?.map((campaign) => (
//                                             <tr key={campaign._id}>
//                                                 <td>{campaign.name}</td>
//                                                 <td>
//                                                     <Link to={`/dashboard/edit-campaign/${campaign._id}`}>
//                                                         <i className="bi bi-pencil-square m-1 text-primary"></i>
//                                                     </Link>
//                                                     <a onClick={() => { setSelectedCampaignId(campaign._id); setShowDialog(true); }}>
//                                                         <i className="bi bi-trash3-fill m-1 text-danger"></i>
//                                                     </a>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {showDialog && (
//                 <ConfirmationDialog
//                     title="Confirm Deletion"
//                     message="Are you sure you want to delete this Campaign?"
//                     onConfirm={handleDelete}
//                     onCancel={() => setShowDialog(false)}
//                 />
//             )}
//         </div>
//     );
// };

// export default CampaignList;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Box,
  Typography,
  Container,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  useDeleteCampaignMutation,
  useGetCampaignsQuery,
} from "../../../features/campaign/campaignSlice";
import { Campaign } from "../../../interfaces/Campaign";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Add } from "@mui/icons-material";

const CampaignList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data, error, isLoading, refetch } = useGetCampaignsQuery({
    page,
    limit,
  });
  const [deleteCampaign] = useDeleteCampaignMutation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null
  );

  const addCampaign = () => {
    navigate("/dashboard/add-campaign");
  };

  //   const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
  //     e.preventDefault();
  //     if (selectedCampaignId) {
  //       await deleteCampaign(selectedCampaignId);
  //       setShowDialog(false);
  //       refetch();
  //     }
  //   };

  const handleDelete = async () => {
    if (selectedCampaignId) {
      await deleteCampaign(selectedCampaignId);
      setShowDialog(false);
      refetch();
    }
  };

  useEffect(() => {
    refetch();
  }, [page, refetch]);
  //   const totalPages = Math.ceil(data?.totalAdvertisements / 5);
  const totalPages = Math.ceil((data?.total || 0) / limit);

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography>Error loading campaigns</Typography>;

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Campaign Table
        </Typography>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="primary"
          onClick={addCampaign}
        >
          Add New Campaign
        </Button>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Campaign Name</TableCell>
                <TableCell>Campaign Description</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Modified Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data.map((campaign: Campaign) => (
                <TableRow key={campaign._id}>
                  <TableCell>{campaign.name}</TableCell>
                  <TableCell>{campaign.description}</TableCell>
                  <TableCell>
                    {moment(campaign.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </TableCell>
                  <TableCell>
                    {moment(campaign.updatedAt).format("DD/MM/YYYY hh:mm A")}
                  </TableCell>
                  {campaign.company && (
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          navigate(`/dashboard/edit-campaign/${campaign._id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setSelectedCampaignId(campaign._id ?? null);
                          setShowDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} display="flex" justifyContent="center">
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, newPage) => handlePageChange(newPage)}
            color="primary"
          />
        </Box>
        {showDialog && (
          <ConfirmationDialog
            show={showDialog}
            title="Confirm Deletion"
            message="Are you sure you want to delete this Campaign?"
            onConfirm={handleDelete}
            onCancel={() => setShowDialog(false)}
          />
        )}
      </Box>
    </Container>
  );
};

export default CampaignList;
