// import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { setCredentials, logOut } from "./authSlice";

// const authBaseQuery = fetchBaseQuery({
//   baseUrl: "http://localhost:3001",
//   credentials: "include",
//   prepareHeaders: (headers, { getState }) => {
//     const token = getState().auth.token;
//     if (token) {
//       headers.set("Authorization", `Bearer ${token}`);
//     }
//     return headers;
//   },
// });

// export const refreshToken = async (args, api, extraOptions, baseQuery) => {
//   let result = await baseQuery(args, api, extraOptions);
//   if (result?.error?.status === 403) {
//     const refreshToken = api.getState().auth.refreshToken;
//     if (refreshToken) {
//       const refreshResult = await authBaseQuery(
//         {
//           url: "/user/refresh",
//           method: "POST",
//           body: { refreshToken },
//         },
//         api,
//         extraOptions
//       );
//       if (refreshResult?.data) {
//         api.dispatch(setCredentials({ ...refreshResult.data }));
//         result = await baseQuery(args, api, extraOptions);
//       } else {
//         api.dispatch(logOut());
//       }
//     } else {
//       api.dispatch(logOut());
//     }
//   }
//   return result;
// };

import {
  fetchBaseQuery,
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { setCredentials, logOut } from "./adminSlice";

type BaseQueryResult = { data: any } | { error: FetchBaseQueryError };

// interface RefreshResponse {
//   user: any; // You can replace `any` with the actual user type if available
//   accessToken: string;
//   refreshToken: string;
// }

const adminBaseQuery = fetchBaseQuery({
  baseUrl: "http://localhost:3001",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).admin.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const refreshToken = async (
  args: string | FetchArgs, // Type for the args parameter
  api: BaseQueryApi, // Type for the api parameter
  extraOptions: object, // Type for the extraOptions parameter
  baseQuery: typeof adminBaseQuery // Type for the baseQuery parameter
): Promise<BaseQueryResult> => {
  // Type for the return value
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 403) {
    const refreshToken = (api.getState() as RootState).admin.refreshToken;
    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: "/admin/refresh",
          method: "POST",
          body: { refreshToken },
        },
        api,
        extraOptions
      );
      // Ensure the response contains the new accessToken
      const data = refreshResult.data as { accessToken: string };

      if (data && data.accessToken) {
        // Dispatch only the new accessToken to be set in the Redux store
        api.dispatch(setCredentials({ accessToken: data.accessToken }));

        // Retry the original query with the new accessToken
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut());
      }
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};
