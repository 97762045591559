import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCreatePricingPlanMutation } from "../../../features/pricing_plan/pricingPlanSlice";
import { useGetDurationsQuery } from "../../../features/duration/durationSlice";

const AddPricingPlan: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [noOfUsers, setNoOfUsers] = useState<number>(0);
  const [features, setFeatures] = useState<string[]>([]);
  const [price, setPrice] = useState<number>(0);
  const [best, setBest] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createPricingPlan] = useCreatePricingPlanMutation();

  // Fetch all durations without pagination
  const { data: durations } = useGetDurationsQuery({ page: 1, limit: 0 });

  const handleFeatureChange = (index: number, value: string) => {
    const newFeatures = [...features];
    newFeatures[index] = value;
    setFeatures(newFeatures);
  };

  const handleAddFeature = () => {
    setFeatures([...features, ""]);
  };

  const handleRemoveFeature = (index: number) => {
    const newFeatures = [...features];
    newFeatures.splice(index, 1);
    setFeatures(newFeatures);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createPricingPlan({
        name,
        description,
        duration,
        noOfUsers,
        price,
        features,
        best,
      }).unwrap();
      setName("");
      setDescription("");
      setDuration("");
      setNoOfUsers(0);
      setPrice(0);
      setBest(false);
      navigate("/admin-dashboard/pricing-plan");
      toast.success("Pricing Plan added successfully");
    } catch (err) {
      toast.error("Failed to add Pricing Plan");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Add New Pricing Plan
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Plan Name"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
        />
        <Select
          fullWidth
          variant="outlined"
          value={duration}
          onChange={(e) => setDuration(e.target.value as string)}
          displayEmpty
          required
        >
          <MenuItem value="">
            <em>Select Duration</em>
          </MenuItem>
          {durations &&
            durations.data.map((duration) => (
              <MenuItem key={duration._id} value={duration._id}>
                {duration.duration}
              </MenuItem>
            ))}
        </Select>
        <TextField
          fullWidth
          label="Number of Users"
          variant="outlined"
          margin="normal"
          type="number"
          value={noOfUsers}
          onChange={(e) => setNoOfUsers(Number(e.target.value))}
          required
        />
        <TextField
          fullWidth
          label="Price"
          variant="outlined"
          margin="normal"
          type="number"
          value={price}
          onChange={(e) => setPrice(Number(e.target.value))}
          required
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Features
        </Typography>
        {features.map((feature, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            <TextField
              fullWidth
              label={`Feature ${index + 1}`}
              variant="outlined"
              value={feature}
              onChange={(e) => handleFeatureChange(index, e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleRemoveFeature(index)}
              sx={{ ml: 2 }}
            >
              Remove
            </Button>
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddFeature}
          sx={{ mt: 2 }}
        >
          Add Feature
        </Button>
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={best}
                onChange={(e) => setBest(e.target.checked)}
              />
            }
            label="Best Plan"
          />
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          sx={{ mt: 2 }}
        >
          {isFormLoading ? <CircularProgress size={24} /> : "Add Pricing Plan"}
        </Button>
      </form>
    </Box>
  );
};

export default AddPricingPlan;
