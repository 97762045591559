import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useCreateLeadStatusMutation } from "../../../features/lead_status/leadStatusSlice";

const AddLeadStatus: React.FC = () => {
  const [status, setStatus] = useState<string>("");
  const [color, setColor] = useState<string>("#000000"); // Default color
  const [description, setDescription] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createLeadStatus] = useCreateLeadStatusMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createLeadStatus({ status, color, description }).unwrap();
      setStatus("");
      setColor("#000000");
      setDescription("");
      navigate("/dashboard/lead-status");
    } catch (err) {
      toast.error("Failed to create Lead Status");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>
        Add New Lead Status
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Lead Status Name"
          variant="outlined"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          type="color"
          label="Lead Status Color"
          variant="outlined"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
        >
          {isFormLoading ? "Adding Lead Status..." : "Add Lead Status"}
        </Button>
        <Box textAlign="center" mt={3}>
          <Typography variant="body2">
            <NavLink to="/dashboard/lead-status">
              Back to Lead Status List
            </NavLink>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default AddLeadStatus;
