import React from "react";
import "./App.css";
import LandingPage from "./landing-page/LandingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RedirectIfAuthenticated from "./features/auth/RedirectIfAuthenticated";
import Login from "./dashboard/auth/Login";
import Register from "./dashboard/auth/Register";
import RequireAuth from "./features/auth/RequireAuth";
import DashboardLayout from "./dashboard/DashboardLayout";
import PrivateRoute from "./features/auth/PrivateRoute";
import { UserRole } from "./dashboard/common/constants";
import Main from "./dashboard/Main";
import Unauthorized from "./dashboard/auth/Unauthorized";
import Profile from "./dashboard/auth/Profile";
import AdGroupList from "./dashboard/forms/ad_group/AdGroupList";
import AddAdGroup from "./dashboard/forms/ad_group/AddAdGroup";
import EditAdGroup from "./dashboard/forms/ad_group/EditAdGroup";
import AdvertisementList from "./dashboard/forms/advertisement/AdvertisementList";
import AddAdvertisement from "./dashboard/forms/advertisement/AddAdvertisement";
import EditAdvertisement from "./dashboard/forms/advertisement/EditAdvertisement";
import CampaignList from "./dashboard/forms/campaign/CampaignList";
import AddCampaign from "./dashboard/forms/campaign/AddCampaign";
import EditCampaign from "./dashboard/forms/campaign/EditCampaign";
import ContactMethodList from "./dashboard/forms/contact_method/ContactMethodList";
import AddContactMethod from "./dashboard/forms/contact_method/AddContactMethod";
import EditContactMethod from "./dashboard/forms/contact_method/EditContactMethod";
import LeadTypeList from "./dashboard/forms/lead_type/LeadTypeList";
import AddLeadType from "./dashboard/forms/lead_type/AddLeadType";
import EditLeadType from "./dashboard/forms/lead_type/EditLeadType";
import LeadSubTypeList from "./dashboard/forms/lead_sub_type/LeadSubTypeList";
import AddLeadSubType from "./dashboard/forms/lead_sub_type/AddLeadSubType";
import EditLeadSubType from "./dashboard/forms/lead_sub_type/EditLeadSubType";
import AddLocation from "./dashboard/forms/location/AddLocation";
import LocationList from "./dashboard/forms/location/LocationList";
import EditLocation from "./dashboard/forms/location/EditLocation";
import LeadList from "./dashboard/forms/lead/LeadList";
import AddLead from "./dashboard/forms/lead/AddLead";
import EditLead from "./dashboard/forms/lead/EditLead";
import ViewLead from "./dashboard/forms/lead/ViewLead";
import StepperForm from "./dashboard/auth/company_setup/StepperForm";
import AdminRedirectIfAuthenticated from "./features/admin/AdminRedirectIfAuthenticated";
import AdminLogin from "./admin_dashboard/auth/AdminLogin";
import AdminRegister from "./admin_dashboard/auth/AdminRegister";
import AdminRequireAuth from "./features/admin/AdminRequireAuth";
import AdminDashboardLayout from "./admin_dashboard/AdminDashboardLayout";
import AdminPrivateRoute from "./features/admin/AdminPrivateRoute";
import AdminMain from "./admin_dashboard/AdminMain";
import AdminProfile from "./admin_dashboard/auth/AdminProfile";
import { AdminRole } from "./admin_dashboard/common/constants";
import DurationList from "./admin_dashboard/forms/duration/DurationList";
import AddDuration from "./admin_dashboard/forms/duration/AddDuration";
import EditDuration from "./admin_dashboard/forms/duration/EditDuration";
import PricingPlanList from "./admin_dashboard/forms/pricing_plan/PricingPlanList";
import AddPricingPlan from "./admin_dashboard/forms/pricing_plan/AddPricingPlan";
import EditPricingPlan from "./admin_dashboard/forms/pricing_plan/EditPricingPlan";
import WaitingConfirmation from "./dashboard/auth/company_setup/WaitingConfirmation";
import UserList from "./dashboard/forms/user/UserList";
import AddUser from "./dashboard/forms/user/AddUser";
import EditUser from "./dashboard/forms/user/EditUser";
import CompanyList from "./admin_dashboard/forms/company/CompanyList";
import ViewCompany from "./admin_dashboard/forms/company/ViewCompany";
import LeadStatusList from "./dashboard/forms/lead_status/LeadStatusList";
import AddLeadStatus from "./dashboard/forms/lead_status/AddLeadStatus";
import EditLeadStatus from "./dashboard/forms/lead_status/EditLeadStatus";
import AdminLeadStatusList from "./admin_dashboard/forms/lead_status/AdminLeadStatusList";
import AdminAddLeadStatus from "./admin_dashboard/forms/lead_status/AdminAddLeadStatus";
import AdminEditLeadStatus from "./admin_dashboard/forms/lead_status/AdminEditLeadStatus";
import CustomerList from "./dashboard/forms/customer/CustomerList";
import AddCustomer from "./dashboard/forms/customer/AddCustomer";
import EditCustomer from "./dashboard/forms/customer/EditCustomer";
import ViewCustomer from "./dashboard/forms/customer/ViewCustomer";
import VerifyEmailForm from "./dashboard/auth/VerifyEmailForm";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />{" "}
          {/* Publicly accessible route */}
          {/* Redirect to home if already authenticated */}
          <Route element={<RedirectIfAuthenticated />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Route>
          {/* Require authentication for these routes */}
          <Route element={<RequireAuth />}>
            <Route
              path="/verify-email"
              element={
                <PrivateRoute roles={[UserRole.OWNER]}>
                  <VerifyEmailForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-setup"
              element={
                <PrivateRoute roles={[UserRole.OWNER]}>
                  <StepperForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/waiting-confirmation"
              element={
                <PrivateRoute roles={[UserRole.OWNER]}>
                  <WaitingConfirmation />
                </PrivateRoute>
              }
            />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <Main />
                  </PrivateRoute>
                }
              />
              <Route
                path="profile/:id"
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="lead-status"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <LeadStatusList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-lead-status"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddLeadStatus />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-lead-status/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditLeadStatus />
                  </PrivateRoute>
                }
              />
              <Route
                path="lead-type"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <LeadTypeList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-lead-type"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddLeadType />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-lead-type/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditLeadType />
                  </PrivateRoute>
                }
              />
              <Route
                path="lead-sub-type"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <LeadSubTypeList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-lead-sub-type"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddLeadSubType />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-lead-sub-type/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditLeadSubType />
                  </PrivateRoute>
                }
              />
              <Route
                path="ad-group"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AdGroupList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-ad-group"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddAdGroup />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-ad-group/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditAdGroup />
                  </PrivateRoute>
                }
              />
              <Route
                path="advertisement"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AdvertisementList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-advertisement"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddAdvertisement />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-advertisement/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditAdvertisement />
                  </PrivateRoute>
                }
              />
              <Route
                path="campaign"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <CampaignList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-campaign"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddCampaign />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-campaign/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditCampaign />
                  </PrivateRoute>
                }
              />
              <Route
                path="contact-method"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <ContactMethodList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-contact-method"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddContactMethod />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-contact-method/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditContactMethod />
                  </PrivateRoute>
                }
              />
              <Route
                path="location"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <LocationList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-location"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddLocation />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-location/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditLocation />
                  </PrivateRoute>
                }
              />
              <Route
                path="user"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <UserList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-user"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <AddUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-user/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="lead"
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <LeadList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-lead"
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <AddLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-lead/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="view-lead/:id"
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <></>
                    <ViewLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="customer"
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <CustomerList />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-customer"
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <AddCustomer />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-customer/:id"
                element={
                  <PrivateRoute
                    roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}
                  >
                    <EditCustomer />
                  </PrivateRoute>
                }
              />
              <Route
                path="view-customer/:id"
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.OWNER,
                      UserRole.HEAD_OF_SALES,
                      UserRole.SALES_DIRECTOR,
                      UserRole.SALES_MANAGER,
                      UserRole.SALES,
                    ]}
                  >
                    <></>
                    <ViewCustomer />
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="profile/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES, UserRole.SALES_DIRECTOR, UserRole.SALES_MANAGER, UserRole.SALES]}>
                    <Profile />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="lead" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES, UserRole.SALES_DIRECTOR, UserRole.SALES_MANAGER, UserRole.SALES]}>
                    <LeadList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-lead" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES, UserRole.SALES_DIRECTOR, UserRole.SALES_MANAGER, UserRole.SALES]}>
                    <AddLead />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-lead/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditLead />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="view-lead/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES, UserRole.SALES_DIRECTOR, UserRole.SALES_MANAGER, UserRole.SALES]}>
                    <ViewLead />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="ad-group" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AdGroupList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-ad-group" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AddAdGroup />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-ad-group/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditAdGroup />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="lead-type" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <LeadTypeList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-lead-type" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AddLeadType />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-lead-type/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditLeadType />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="lead-sub-type" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <LeadSubTypeList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-lead-sub-type" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AddLeadSubType />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-lead-sub-type/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditLeadSubType />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="campaign" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <CampaignList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-campaign" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AddCampaign />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-campaign/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditCampaign />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="advertisement" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AdvertisementList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-advertisement" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AddAdvertisement />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-advertisement/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditAdvertisement />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="contact-method" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <ContactMethodList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-contact-method" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AddContactMethod />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-contact-method/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditContactMethod />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="location" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <LocationList />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="add-location" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <AddLocation />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="edit-location/:id" 
                element={
                  <PrivateRoute roles={[UserRole.OWNER, UserRole.HEAD_OF_SALES]}>
                    <EditLocation />
                  </PrivateRoute>
                } 
              /> */}
            </Route>
          </Route>
          <Route element={<AdminRedirectIfAuthenticated />}>
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-register" element={<AdminRegister />} />
          </Route>
          <Route element={<AdminRequireAuth />}>
            <Route path="/admin-dashboard" element={<AdminDashboardLayout />}>
              <Route
                index
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <AdminMain />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="profile/:id"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <AdminProfile />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="duration"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <DurationList />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="add-duration"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <AddDuration />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="edit-duration/:id"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <EditDuration />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="pricing-plan"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <PricingPlanList />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="add-pricing-plan"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <AddPricingPlan />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="edit-pricing-plan/:id"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <EditPricingPlan />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="lead-status"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <AdminLeadStatusList />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="add-lead-status"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <AdminAddLeadStatus />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="edit-lead-status/:id"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <AdminEditLeadStatus />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="company"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <CompanyList />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="view-company/:id"
                element={
                  <AdminPrivateRoute roles={[AdminRole.ADMIN]}>
                    <ViewCompany />
                  </AdminPrivateRoute>
                }
              />
            </Route>
          </Route>
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </div>
    </Router>
    // <div className="App">
    //   <LandingPage />
    //   <Dashboard />
    // </div>
  );
}

export default App;
