import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import instapayLogo from "../../images/instapay-logo.png";

interface PaymentFormProps {
  ipa: string;
  setIpa: React.Dispatch<React.SetStateAction<string>>;
  mobileNumber: string;
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  ipa,
  setIpa,
  mobileNumber,
  setMobileNumber,
  amount,
  setAmount,
}) => {
  const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    if (value.length <= 10) {
      setMobileNumber(value);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={8} md={6} lg={5}>
        <Card
          sx={{
            boxShadow: 3,
            borderRadius: 3,
            marginTop: 3,
            p: 1,
          }}
        >
          <CardContent>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Payment
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  backgroundColor: "#582487",
                  padding: 1,
                  borderRadius: 1,
                  justifyContent: "center",
                  mx: "auto",
                  width: "75%",
                }}
              >
                <img
                  src={instapayLogo}
                  alt="Instapay Logo"
                  style={{ width: "75%", maxWidth: "75%" }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TextField
                  label="IPA"
                  value={ipa}
                  required
                  onChange={(e) => setIpa(e.target.value)}
                  sx={{ flexGrow: 1, marginRight: 1 }}
                />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                  @instapay
                </Typography>
              </Box>
              <TextField
                label="Mobile Number"
                type="number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                InputProps={{
                  startAdornment: (
                    <Typography variant="body1" sx={{ marginRight: 1 }}>
                      +20
                    </Typography>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="Amount"
                type="text"
                value={amount}
                fullWidth
                required
                InputProps={{
                  readOnly: true, // Make the field read-only
                  startAdornment: (
                    <InputAdornment position="start">E£</InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
              {/* Additional Information Box */}
              <Box
                sx={{
                  mt: 2,
                  padding: 2,
                  backgroundColor: "#f5f5f5",
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "#333", textAlign: "center" }}
                >
                  Your payment request will be sent to Instapay within 1 day.
                  <br />
                  From account: <strong>orcida@instapay</strong>
                  <br />
                  After your confirmation, your account will be activated.
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentForm;
