// import React, { useEffect, useState } from 'react';
// import moment from 'moment';
// import { Link, useNavigate } from 'react-router-dom';
// import ConfirmationDialog from '../common/ConfirmationDialog';
// import { useDeleteAdvertisementMutation, useGetAdvertisementsQuery } from '../../../features/advertisement/advertisementSlice';

// const AdvertisementList = () => {
//     const [page, setPage] = useState(1);
//     const { data, error, isLoading, refetch } = useGetAdvertisementsQuery({ page });
//     const [deleteAdvertisement] = useDeleteAdvertisementMutation();

//     const navigate = useNavigate();
//     const [showDialog, setShowDialog] = useState(false);
//     const [selectedAdvertisementId, setSelectedAdvertisementId] = useState(null);

//     const addAdvertisement = () => {
//         navigate('/dashboard/add-advertisement');
//     }

//     const handleDelete = async () => {
//         if (selectedAdvertisementId) {
//             await deleteAdvertisement(selectedAdvertisementId);
//             setShowDialog(false);
//             refetch();
//         }
//     };

//     useEffect(() => {
//         refetch();
//     }, [page, refetch]);

//     const handlePageChange = (newPage) => {
//         if (newPage !== page) {
//             setPage(newPage);
//         }
//     };

//     const totalPages = Math.ceil(data?.totalAdvertisements / 5);

//     if (isLoading) return <div>Loading...</div>;
//     if (error) return <div>Error loading advertisements</div>;

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Advertisement Table</h4>
//                             </div>
//                             <button id="table2-new-row-button" className="btn btn-primary mb-4 mr-2" onClick={addAdvertisement}>
//                                 Add New Advertisement
//                             </button>
//                         </div>
//                         <div className="card-body">
//                             <div className="table-responsive border-top userlist-table">
//                                 <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
//                                     <thead>
//                                         <tr>
//                                             <th>Advertisement Name</th>
//                                             <th>Advertisement Description</th>
//                                             <th>Ad Group</th>
//                                             <th>Created Date</th>
//                                             <th>Modified Date</th>
//                                             <th>Actions</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {data?.map((advertisement) => (
//                                             <tr key={advertisement._id}>
//                                                 <td>{advertisement.name}</td>
//                                                 <td>{advertisement.description}</td>
//                                                 <td>{advertisement.adGroup.name}</td>
//                                                 <td>{moment(advertisement.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
//                                                 <td>{moment(advertisement.updatedAt).format("DD/MM/YYYY hh:mm A")}</td>
//                                                 <td>
//                                                     <Link to={`/dashboard/edit-advertisement/${advertisement._id}`}>
//                                                         <i className="bi bi-pencil-square m-1 text-primary"></i>
//                                                     </Link>
//                                                     <a onClick={() => { setSelectedAdvertisementId(advertisement._id); setShowDialog(true); }}>
//                                                         <i className="bi bi-trash3-fill m-1 text-danger"></i>
//                                                     </a>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                             <div className="pagination">
//                                 {Array.from({ length: totalPages }, (_, i) => (
//                                     <button
//                                         key={i + 1}
//                                         className={`btn ${i + 1 === page ? 'btn-primary' : 'btn-secondary'}`}
//                                         onClick={() => handlePageChange(i + 1)}
//                                     >
//                                         {i + 1}
//                                     </button>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {showDialog && (
//                 <ConfirmationDialog
//                     title="Confirm Deletion"
//                     message="Are you sure you want to delete this Advertisement?"
//                     onConfirm={handleDelete}
//                     onCancel={() => setShowDialog(false)}
//                 />
//             )}
//         </div>
//     );
// };

// export default AdvertisementList;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  useDeleteAdvertisementMutation,
  useGetAdvertisementsQuery,
} from "../../../features/advertisement/advertisementSlice";
import { Advertisement } from "../../../interfaces/Advertisement";
import { Add } from "@mui/icons-material";

const AdvertisementList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data, error, isLoading, refetch } = useGetAdvertisementsQuery({
    page,
    limit,
  });
  const [deleteAdvertisement] = useDeleteAdvertisementMutation();

  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedAdvertisementId, setSelectedAdvertisementId] = useState<
    string | null
  >(null);

  const addAdvertisement = () => {
    navigate("/dashboard/add-advertisement");
  };

  const handleDelete = async () => {
    if (selectedAdvertisementId) {
      await deleteAdvertisement(selectedAdvertisementId);
      setShowDialog(false);
      refetch();
    }
  };

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  //   const totalPages = Math.ceil(data?.totalAdvertisements / 5);
  const totalPages = Math.ceil((data?.total || 0) / limit);

  if (isLoading) return <CircularProgress />;
  if (error)
    return <Typography color="error">Error loading advertisements</Typography>;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Advertisement Table
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={addAdvertisement}
      >
        Add New Advertisement
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Advertisement Name</TableCell>
              <TableCell>Advertisement Description</TableCell>
              <TableCell>Ad Group</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Modified Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((advertisement: Advertisement) => (
              <TableRow key={advertisement._id}>
                <TableCell>{advertisement.name}</TableCell>
                <TableCell>{advertisement.description}</TableCell>
                <TableCell>
                  {advertisement.adGroup &&
                  typeof advertisement.adGroup !== "string"
                    ? advertisement.adGroup.name
                    : "Unknown Group"}
                </TableCell>
                <TableCell>
                  {moment(advertisement.createdAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell>
                  {moment(advertisement.updatedAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                {advertisement.company && (
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(
                          `/dashboard/edit-advertisement/${advertisement._id}`
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setSelectedAdvertisementId(advertisement._id ?? null);
                        setShowDialog(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Box>
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this Advertisement?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Box>
  );
};

export default AdvertisementList;
