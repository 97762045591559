// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate, NavLink } from 'react-router-dom';
// import { useGetLeadTypesQuery } from '../../../../features/lead_type/leadTypeSlice';
// import { toast, ToastContainer } from 'react-toastify';
// import { useGetLeadSubTypeQuery, useUpdateLeadSubTypeMutation } from '../../../../features/lead_sub_type/leadSubTypeSlice';

// const EditLeadSubType = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [name, setName] = useState('');
//     const [leadType, setLeadType] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);

//     const { data: leadTypeData } = useGetLeadTypesQuery();

//     const { data: leadSubTypeData, refetch } = useGetLeadSubTypeQuery(id);
//     const [updateLeadSubType] = useUpdateLeadSubTypeMutation();

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     useEffect(() => {
//         if (leadSubTypeData) {
//             setName(leadSubTypeData?.name || '');
//             setLeadType(leadSubTypeData?.leadType || '');
//         }
//     }, [leadSubTypeData]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await updateLeadSubType({ id, leadSubType: { name, leadType } }).unwrap();
//             toast.success('Lead SubType updated successfully');
//             navigate('/dashboard/lead-sub-type');
//         } catch (err) {
//             toast.error('Failed to update Lead SubType');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Edit Lead SubType</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Lead SubType Name"
//                                     />
//                                     <label htmlFor="name">Lead SubType Name</label>
//                                 </div>

//                                 <div className="form-floating mb-2">
//                                     <select
//                                         className="form-select"
//                                         value={leadType}
//                                         onChange={(e) => setLeadType(e.target.value)}
//                                     >
//                                         <option value="">Select Lead Type</option>
//                                         {leadTypeData?.map(leadType => (
//                                             <option key={leadType._id} value={leadType._id}>{leadType.name}</option>
//                                         ))}
//                                     </select>
//                                     <label htmlFor="leadType">Lead Type</label>
//                                 </div>

//                                 <button className="btn btn-primary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Updating Lead SubType...' : 'Update Lead SubType'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/lead-subtype' className="text-dark fw-bold">Lead SubType List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditLeadSubType;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useGetLeadTypesQuery } from "../../../features/lead_type/leadTypeSlice";
import {
  useGetLeadSubTypeQuery,
  useUpdateLeadSubTypeMutation,
} from "../../../features/lead_sub_type/leadSubTypeSlice";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const EditLeadSubType: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [leadType, setLeadType] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  // Fetch all ad groups without pagination
  const { data: leadTypeData } = useGetLeadTypesQuery({
    page: 1,
    limit: 0,
  });
  const { data: leadSubTypeData, refetch } = useGetLeadSubTypeQuery(id!);
  const [updateLeadSubType] = useUpdateLeadSubTypeMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (leadSubTypeData) {
      setName(leadSubTypeData?.name || "");
      //   setLeadType(leadSubTypeData?.leadType || "");
      const leadTypeData = leadSubTypeData?.leadType;
      if (typeof leadTypeData === "string") {
        setLeadType(leadTypeData);
      } else if (leadTypeData && typeof leadTypeData === "object") {
        setLeadType(leadTypeData._id || ""); // Or another property based on your requirements
      }
    }
  }, [leadSubTypeData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await updateLeadSubType({
        id: id!,
        leadSubType: { name, leadType },
      }).unwrap();
      toast.success("Lead SubType updated successfully");
      navigate("/dashboard/lead-sub-type");
    } catch (err) {
      toast.error("Failed to update Lead SubType");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Edit Lead SubType
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Lead SubType Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="lead-type-select-label">Lead Type</InputLabel>
          <Select
            labelId="lead-type-select-label"
            value={leadType}
            onChange={(e) => setLeadType(e.target.value as string)}
            required
          >
            <MenuItem value="">
              <em>Select Lead Type</em>
            </MenuItem>
            {leadTypeData?.data?.map((leadType) => (
              <MenuItem key={leadType._id} value={leadType._id}>
                {leadType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          startIcon={isFormLoading && <CircularProgress size={20} />}
        >
          {isFormLoading ? "Updating Lead SubType..." : "Update Lead SubType"}
        </Button>

        <Typography
          align="center"
          variant="body2"
          color="textSecondary"
          className="mt-3"
        >
          <NavLink to="/dashboard/lead-sub-type">
            Back to Lead SubType List
          </NavLink>
        </Typography>
      </form>
    </Container>
  );
};

export default EditLeadSubType;
