import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Paper, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useGetLeadsQuery } from "../features/lead/leadSlice";
import { useGetLeadTransactionsQuery } from "../features/lead_transaction/leadTransactionSlice";
import Chart from "./Chart";
import RecentLeads from "./RecentLeads";
import RecentTransactions from "./RecentTransactions";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="">
        Your CRM
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Main() {
  const theme = useTheme();
  const dispatch = useDispatch();

  // Fetch
  // Pagination state
  const [currentPage, setCurrentPage] = useState(0); // Start at page 0
  const transactionsPerPage = 10; // Define how many transactions per page

  // Fetch leads and transactions from slices
  const { data: leadsData, refetch: refetchLeads } = useGetLeadsQuery({
    page: 1,
    limit: 0,
  });
  const { data: transactionsData, refetch: refetchTransactions } =
    useGetLeadTransactionsQuery({
      page: currentPage + 1, // Increment by 1 for API (if API is 1-based)
      limit: transactionsPerPage,
    });

  useEffect(() => {
    // Handle any additional data fetching or side effects
    refetchLeads();
    refetchTransactions();
  }, [dispatch]);

  // Fallback to empty array if data is undefined
  const leads = leadsData?.data || [];
  const transactions = transactionsData?.data || [];
  const totalCount = transactionsData?.total || 0; // Assuming your API response contains total count

  // Page change handler
  const handlePageChange = (event: unknown, newPage: number) => {
    setCurrentPage(newPage); // Update current page
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* Chart showing lead statistics */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <Chart leadsData={leads} />
          </Paper>
        </Grid>

        {/* Recent CRM lead updates */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
              overflow: "auto", // Enable scrolling
            }}
          >
            <RecentLeads leads={leads} />
          </Paper>
        </Grid>

        {/* Recent CRM transactions */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <RecentTransactions
              transactions={transactions}
              page={currentPage} // Replace with your current page state
              limit={transactionsPerPage} // Replace with your defined limit
              totalTransactions={totalCount} // Total transactions count from your API response
              onPageChange={handlePageChange} // Handler function for page change
            />
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </>
  );
}
