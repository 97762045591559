import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  IconButton,
} from "@mui/material";
import {
  useDeleteDurationMutation,
  useGetDurationsQuery,
} from "../../../features/duration/durationSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { Duration } from "../../../interfaces/Duration"; // Adjust the import path accordingly
import { Add } from "@mui/icons-material";

const DurationList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data, error, isLoading, refetch } = useGetDurationsQuery({
    page,
    limit,
  } as any);
  const [deleteDuration] = useDeleteDurationMutation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedDurationId, setSelectedDurationId] = useState<string | null>(
    null
  );

  const addDuration = () => {
    navigate("/admin-dashboard/add-duration");
  };

  const handleDelete = async () => {
    if (selectedDurationId) {
      await deleteDuration(selectedDurationId);
      setShowDialog(false);
      refetch();
    }
  };

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  const totalPages = Math.ceil((data?.total || 0) / limit);

  if (isLoading) return <CircularProgress />;
  if (error)
    return <Typography color="error">Error loading durations</Typography>;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Duration Table
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={addDuration}
      >
        Add New Duration
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Duration</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((duration: Duration) => (
              <TableRow key={duration._id}>
                <TableCell>{duration.duration}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(`/admin-dashboard/edit-duration/${duration._id}`)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => {
                      setSelectedDurationId(duration._id ?? null);
                      setShowDialog(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Box>
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this Duration?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Box>
  );
};

export default DurationList;
