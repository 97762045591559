// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { useDeleteLocationMutation, useGetLocationsQuery } from '../../../../features/location/locationSlice';
// import ConfirmationDialog from '../common/ConfirmationDialog';

// const LocationList = () => {
//     const { data, error, isLoading, refetch } = useGetLocationsQuery();
//     const [deleteLocation] = useDeleteLocationMutation();
//     const navigate = useNavigate();
//     const [showDialog, setShowDialog] = useState(false);
//     const [selectedLocationId, setSelectedLocationId] = useState(null);

//     const addLocation = () => {
//         navigate('/dashboard/add-location');
//     };

//     const handleDelete = async (e) => {
//         e.preventDefault();
//         if (selectedLocationId) {
//             await deleteLocation(selectedLocationId);
//             setShowDialog(false);
//             refetch();
//         }
//     };

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     if (isLoading) return <div>Loading...</div>;
//     if (error) return <div>Error loading locations</div>;

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Location Table</h4>
//                             </div>
//                             <button id="table2-new-row-button" className="btn btn-primary mb-4 mr-2" onClick={addLocation}>
//                                 Add New Location
//                             </button>
//                         </div>
//                         <div className="card-body">
//                             <div className="table-responsive border-top userlist-table">
//                                 <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
//                                     <thead>
//                                         <tr>
//                                             <th>Location Name</th>
//                                             <th>Actions</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {data?.map((location) => (
//                                             <tr key={location._id}>
//                                                 <td>{location.name}</td>
//                                                 <td>
//                                                     <Link to={`/dashboard/edit-location/${location._id}`}>
//                                                         <i className="bi bi-pencil-square m-1 text-primary"></i>
//                                                     </Link>
//                                                     <a onClick={() => { setSelectedLocationId(location._id); setShowDialog(true); }}>
//                                                         <i className="bi bi-trash3-fill m-1 text-danger"></i>
//                                                     </a>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {showDialog && (
//                 <ConfirmationDialog
//                     title="Confirm Deletion"
//                     message="Are you sure you want to delete this Location?"
//                     onConfirm={handleDelete}
//                     onCancel={() => setShowDialog(false)}
//                 />
//             )}
//         </div>
//     );
// };

// export default LocationList;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeleteLocationMutation,
  useGetLocationsQuery,
} from "../../../features/location/locationSlice";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Location } from "../../../interfaces/Location";
import { Add } from "@mui/icons-material";
import moment from "moment";

const LocationList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data, error, isLoading, refetch } = useGetLocationsQuery({
    page,
    limit,
  } as any);
  const [deleteLocation] = useDeleteLocationMutation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(
    null
  );
  const addLocation = () => {
    navigate("/dashboard/add-location");
  };

  const handleDelete = async () => {
    if (selectedLocationId) {
      await deleteLocation(selectedLocationId);
      setShowDialog(false);
      refetch();
    }
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography>Error loading locations</Typography>
      </Box>
    );

  const totalPages = Math.ceil((data?.total || 0) / limit);

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  return (
    <Box padding={2}>
      <Typography variant="h4" mb={2}>
        Location Table
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        onClick={addLocation}
        sx={{ marginBottom: 2 }}
      >
        Add New Location
      </Button>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Location Name</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Modified Date</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((location: Location) => (
              <TableRow key={location._id}>
                <TableCell>{location.name}</TableCell>
                <TableCell>
                  {moment(location.createdAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell>
                  {moment(location.updatedAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    component={Link}
                    to={`/dashboard/edit-location/${location._id}`}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedLocationId(location._id ?? null);
                      setShowDialog(true);
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Box>
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this Location?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Box>
  );
};

export default LocationList;
