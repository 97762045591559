import {
  fetchBaseQuery,
  BaseQueryApi,
  FetchArgs,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { refreshToken } from "../auth/refreshToken";

// Custom fetchBaseQuery with token handling
const authBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Base query with refresh token logic
const authBaseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  return refreshToken(args, api, extraOptions, authBaseQuery);
};

export { authBaseQuery, authBaseQueryWithReauth };
