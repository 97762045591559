import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetCustomersQuery,
  useDeleteCustomerMutation,
} from "../../../features/customer/customerSlice";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  Edit,
  Delete,
} from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import { Customer } from "../../../interfaces/Customer";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { lightBlue } from "@mui/material/colors";

const CustomerList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );

  const { data, error, isLoading, refetch } = useGetCustomersQuery({
    page,
    limit,
  });
  const [deleteCustomer] = useDeleteCustomerMutation();
  const navigate = useNavigate();

  const totalPages = Math.ceil((data?.total || 0) / limit);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    refetch();
  }, [page, limit]);

  const handleDelete = async () => {
    if (selectedCustomerId) {
      await deleteCustomer(selectedCustomerId);
      setShowDialog(false);
      refetch();
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography>Error loading customers</Typography>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4">Customer List</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/dashboard/add-customer")}
        startIcon={<AddIcon />}
      >
        Add New Customer
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Primary Phone</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((customer: Customer) => (
              <TableRow key={customer._id}>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>
                  {typeof customer.primaryPhone.country === "object" &&
                    customer.primaryPhone.country.code}
                  {customer.primaryPhone.phoneNumber}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", gap: "1px" }}>
                    <IconButton
                      component={Link}
                      to={`/dashboard/view-customer/${customer._id}`}
                    >
                      <Visibility sx={{ color: lightBlue[500] }} />
                    </IconButton>
                    <IconButton
                      component={Link}
                      to={`/dashboard/edit-customer/${customer._id}`}
                    >
                      <Edit color="primary" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedCustomerId(customer._id ?? null);
                        setShowDialog(true);
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, newPage) => handlePageChange(newPage)}
        />
      </Box>
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this Customer?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Container>
  );
};

export default CustomerList;
