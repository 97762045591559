// import React, { useState, useEffect } from 'react';
// import { NavLink, useNavigate, useParams } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { useGetAdGroupQuery, useUpdateAdGroupMutation } from '../../../../features/ad_group/adGroupSlice';

// const EditAdGroup = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [name, setName] = useState('');
//     const [description, setDescription] = useState('');
//     const [advertisements, setAdvertisements] = useState([]);
//     const [isFormLoading, setIsFormLoading] = useState(false);
//     const { data: adGroupData, refetch } = useGetAdGroupQuery(id);
//     const [updateAdGroup] = useUpdateAdGroupMutation();

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     useEffect(() => {
//         if (adGroupData) {
//             setName(adGroupData?.name || '');
//             setDescription(adGroupData?.description || '');
//             setAdvertisements(adGroupData?.advertisements || []);
//         }
//     }, [adGroupData]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await updateAdGroup({ id, adGroup: { name, description, advertisements } }).unwrap();
//             toast.success('Ad Group updated successfully');
//             navigate('/dashboard/ad-group');
//         } catch (err) {
//             toast.error('Failed to update Ad Group');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Add New Ad Group</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Ad Group Name"
//                                     />
//                                     <label htmlFor="name">Ad Group Name</label>
//                                 </div>

//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='description'
//                                         value={description}
//                                         onChange={(e) => setDescription(e.target.value)}
//                                         placeholder="Ad Group Description"
//                                     />
//                                     <label htmlFor="description">Ad Group Description</label>
//                                 </div>

//                                 <button className="btn btn-primary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Updating Ad Group...' : 'Update Ad Group'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/ad-group' className="text-dark fw-bold">Ad Group List</NavLink></p>
//                                 </div>

//                                 <p className="mb-0 text-muted mt-3 text-center">© 2023 Your Company.</p>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditAdGroup;

import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TextField, Button, Box, Typography } from "@mui/material";
import {
  useGetAdGroupQuery,
  useUpdateAdGroupMutation,
} from "../../../features/ad_group/adGroupSlice";

const EditAdGroup: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const { data: adGroupData, refetch } = useGetAdGroupQuery(id!);
  const [updateAdGroup] = useUpdateAdGroupMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (adGroupData) {
      setName(adGroupData.name || "");
      setDescription(adGroupData.description || "");
    }
  }, [adGroupData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await updateAdGroup({ id: id!, adGroup: { name, description } }).unwrap();
      toast.success("Ad Group updated successfully");
      navigate("/dashboard/ad-group");
    } catch (err) {
      toast.error("Failed to update Ad Group");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Edit Ad Group
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          label="Ad Group Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Ad Group Description"
          variant="outlined"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disabled={isFormLoading}
        >
          {isFormLoading ? "Updating Ad Group..." : "Update Ad Group"}
        </Button>
        <Box textAlign="center" mt={2}>
          <Typography variant="body2">
            <NavLink to="/dashboard/ad-group">Back to Ad Group List</NavLink>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default EditAdGroup;
