import React from "react";
import { useTheme } from "@mui/material/styles";
import { LineChart, axisClasses } from "@mui/x-charts";
import Title from "./Title";
import { ChartsTextStyle } from "@mui/x-charts/ChartsText";
import { Lead } from "../interfaces/Lead";

// Utility function to create data points for the chart
function createData(time: string, leads: number = 0) {
  return { time, leads };
}

interface ChartProps {
  leadsData: Lead[]; // Define the prop type for leadsData
}

export default function Chart({ leadsData }: ChartProps) {
  const theme = useTheme();

  // Process the leads data to create chart points for each hour of the day
  const processedData = processLeadsDataForChart(leadsData);

  return (
    <React.Fragment>
      <Title>Leads Over Time</Title>
      <div style={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
        <LineChart
          dataset={processedData}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: "point",
              dataKey: "time",
              tickNumber: 6, // Customize for better time intervals
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
            },
          ]}
          yAxis={[
            {
              label: "Number of Leads",
              labelStyle: {
                ...(theme.typography.body1 as ChartsTextStyle),
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
              max: getMaxLeadsCount(processedData), // Dynamic scaling based on data
              tickNumber: 5, // Adjust tick number for better visibility
            },
          ]}
          series={[
            {
              dataKey: "leads",
              showMark: true,
              color: theme.palette.primary.main,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: {
              stroke: theme.palette.text.secondary,
            },
            [`.${axisClasses.root} text`]: {
              fill: theme.palette.text.secondary,
            },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translateX(-25px)",
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}

// Function to process lead data and aggregate it by hour (or other time periods)
function processLeadsDataForChart(leadsData: Lead[]) {
  // Assuming leadsData contains an array of lead objects with a createdAt timestamp
  const hours = Array.from({ length: 24 }, (_, i) => i); // Array of hours (0 to 23)
  const processedData = hours.map((hour) => {
    // Filter leads created in this hour
    const leadsInHour = leadsData?.filter((lead: Lead) => {
      const leadTime = new Date(lead?.createdAt!).getHours();
      return leadTime === hour;
    }).length;

    return createData(`${hour}:00`, leadsInHour || 0); // Ensure leads is never null
  });

  return processedData;
}

// Function to dynamically calculate the max lead count for y-axis scaling
function getMaxLeadsCount(data: { leads: number }[]) {
  return Math.max(...data.map((point) => point.leads)) || 10; // Default max of 10 if no data
}
