// import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   IconButton,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
//   Pagination,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import ConfirmationDialog from "../common/ConfirmationDialog";
// import { User } from "../../../interfaces/User";
// import { Add } from "@mui/icons-material";
// import {
//   useDeactivateUserMutation,
//   useGetSubordinatesQuery,
//   useGetUsersQuery,
// } from "../../../features/user/userSlice";

// const UserList: React.FC = () => {
//   const [page, setPage] = useState<number>(1);
//   const limit = 10;
//   const { data, error, isLoading, refetch } = useGetUsersQuery({
//     page,
//     limit,
//   });

//   const { data: subordinates, refetch: subRefetch } = useGetSubordinatesQuery();
//   useEffect(() => {
//     subRefetch();
//   }, [subRefetch]);
//   console.log("subordinates", subordinates);

//   const [deactivateUser] = useDeactivateUserMutation();

//   const navigate = useNavigate();
//   const [showDialog, setShowDialog] = useState<boolean>(false);
//   const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

//   const addUser = () => {
//     navigate("/dashboard/add-user");
//   };

//   const handleDelete = async () => {
//     if (selectedUserId) {
//       await deactivateUser(selectedUserId);
//       setShowDialog(false);
//       refetch();
//     }
//   };

//   useEffect(() => {
//     refetch();
//   }, [page, refetch]);

//   const handlePageChange = (newPage: number) => {
//     if (newPage !== page) {
//       setPage(newPage);
//     }
//   };

//   const totalPages = Math.ceil((data?.total || 0) / limit);

//   if (isLoading) return <CircularProgress />;
//   if (error) return <Typography color="error">Error loading users</Typography>;

//   return (
//     <Box sx={{ p: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         User Table
//       </Typography>
//       <Button
//         startIcon={<Add />}
//         variant="contained"
//         color="primary"
//         sx={{ mb: 2 }}
//         onClick={addUser}
//       >
//         Add New User
//       </Button>
//       <TableContainer>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>User Name</TableCell>
//               <TableCell>Email</TableCell>
//               <TableCell>Role</TableCell>
//               <TableCell>Created Date</TableCell>
//               <TableCell>Modified Date</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {data?.data?.map((user: User) => (
//               <TableRow key={user._id}>
//                 <TableCell>{user.name}</TableCell>
//                 <TableCell>{user.email}</TableCell>
//                 <TableCell>{user.role}</TableCell>
//                 <TableCell>
//                   {moment(user.createdAt).format("DD/MM/YYYY hh:mm A")}
//                 </TableCell>
//                 <TableCell>
//                   {moment(user.updatedAt).format("DD/MM/YYYY hh:mm A")}
//                 </TableCell>
//                 <TableCell>
//                   <IconButton
//                     color="primary"
//                     onClick={() => navigate(`/dashboard/edit-user/${user._id}`)}
//                   >
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton
//                     color="error"
//                     onClick={() => {
//                       setSelectedUserId(user._id ?? null);
//                       setShowDialog(true);
//                     }}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <Box mt={2} display="flex" justifyContent="center">
//         <Pagination
//           count={totalPages}
//           page={page}
//           onChange={(_, newPage) => handlePageChange(newPage)}
//           color="primary"
//         />
//       </Box>
//       {showDialog && (
//         <ConfirmationDialog
//           show={showDialog}
//           title="Confirm Deletion"
//           message="Are you sure you want to deactivate this user?"
//           onConfirm={handleDelete}
//           onCancel={() => setShowDialog(false)}
//         />
//       )}
//     </Box>
//   );
// };

// export default UserList;

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   IconButton,
//   Typography,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import ConfirmationDialog from "../common/ConfirmationDialog";
// import { Add } from "@mui/icons-material";
// import {
//   useGetSubordinatesQuery,
//   useUpdateUserMutation,
// } from "../../../features/user/userSlice";
// import { UserRole } from "../../../interfaces/UserRole";
// import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
// import { TreeItem } from "@mui/x-tree-view/TreeItem";

// const UserList: React.FC = () => {
//   const {
//     data: subordinates,
//     error,
//     isLoading,
//     refetch,
//   } = useGetSubordinatesQuery();
//   const [updateUser] = useUpdateUserMutation();
//   const navigate = useNavigate();
//   const [showDialog, setShowDialog] = useState<boolean>(false);
//   const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const addUser = () => {
//     navigate("/dashboard/add-user");
//   };

//   const handleDelete = async () => {
//     if (selectedUserId) {
//       await updateUser({ id: selectedUserId, user: { isActive: false } });
//       setShowDialog(false);
//       refetch();
//     }
//   };

//   const renderTree = (user: any) => {
//     const children = subordinates?.filter((sub) => sub.parentUser === user._id);

//     return (
//       <TreeItem
//         key={user._id}
//         itemId={user._id}
//         label={
//           <Box
//             display="flex"
//             justifyContent="space-between"
//             alignItems="center"
//           >
//             <Typography>{user.name}</Typography>
//             {(user.role === UserRole.OWNER ||
//               user.role === UserRole.HEAD_OF_SALES) && (
//               <Box>
//                 <IconButton
//                   color="primary"
//                   onClick={() => navigate(`/dashboard/edit-user/${user._id}`)}
//                 >
//                   <EditIcon />
//                 </IconButton>
//                 <IconButton
//                   color="error"
//                   onClick={() => {
//                     setSelectedUserId(user._id ?? null);
//                     setShowDialog(true);
//                   }}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </Box>
//             )}
//           </Box>
//         }
//       >
//         {children?.map((child) => renderTree(child))} {/* Render children */}
//       </TreeItem>
//     );
//   };

//   if (isLoading) return <CircularProgress />;
//   if (error) return <Typography color="error">Error loading users</Typography>;

//   return (
//     <Box sx={{ p: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         User Tree
//       </Typography>
//       <Button
//         startIcon={<Add />}
//         variant="contained"
//         color="primary"
//         sx={{ mb: 2 }}
//         onClick={addUser}
//       >
//         Add New User
//       </Button>
//       <Box sx={{ minHeight: 352, minWidth: 250 }}>
//         <SimpleTreeView>
//           {subordinates
//             ?.filter(
//               (user) => !subordinates.some((sub) => sub.parentUser === user._id)
//             )
//             .map((user) => renderTree(user))}{" "}
//           {/* Render users that are not children of others */}
//         </SimpleTreeView>
//       </Box>
//       {showDialog && (
//         <ConfirmationDialog
//           show={showDialog}
//           title="Confirm Deletion"
//           message="Are you sure you want to deactivate this user?"
//           onConfirm={handleDelete}
//           onCancel={() => setShowDialog(false)}
//         />
//       )}
//     </Box>
//   );
// };

// export default UserList;

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   IconButton,
//   Typography,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import ConfirmationDialog from "../common/ConfirmationDialog";
// import { Add } from "@mui/icons-material";
// import {
//   useGetSubordinatesQuery,
//   useUpdateUserMutation,
// } from "../../../features/user/userSlice";
// import { UserRole } from "../../../interfaces/UserRole";
// import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
// import { TreeItem } from "@mui/x-tree-view/TreeItem";

// const UserList: React.FC = () => {
//   const {
//     data: subordinates,
//     error,
//     isLoading,
//     refetch,
//   } = useGetSubordinatesQuery();
//   const [updateUser] = useUpdateUserMutation();
//   const navigate = useNavigate();
//   const [showDialog, setShowDialog] = useState<boolean>(false);
//   const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const addUser = () => {
//     navigate("/dashboard/add-user");
//   };

//   const handleDelete = async () => {
//     if (selectedUserId) {
//       await updateUser({ id: selectedUserId, user: { isActive: false } });
//       setShowDialog(false);
//       refetch();
//     }
//   };

//   const renderTree = (user: any) => {
//     // Find children of the current user
//     const children = subordinates?.filter((sub) => sub.parentUser === user._id);

//     return (
//       <TreeItem
//         key={user._id}
//         itemId={user._id}
//         label={
//           <Box
//             display="flex"
//             justifyContent="space-between"
//             alignItems="center"
//           >
//             <Typography>{user.name}</Typography>
//             {(user.role === UserRole.OWNER ||
//               user.role === UserRole.HEAD_OF_SALES) && (
//               <Box>
//                 <IconButton
//                   color="primary"
//                   onClick={() => navigate(`/dashboard/edit-user/${user._id}`)}
//                 >
//                   <EditIcon />
//                 </IconButton>
//                 <IconButton
//                   color="error"
//                   onClick={() => {
//                     setSelectedUserId(user._id ?? null);
//                     setShowDialog(true);
//                   }}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </Box>
//             )}
//           </Box>
//         }
//       >
//         {/* Render children recursively */}
//         {children?.map((child) => renderTree(child))}
//       </TreeItem>
//     );
//   };

//   if (isLoading) return <CircularProgress />;
//   if (error) return <Typography color="error">Error loading users</Typography>;

//   return (
//     <Box sx={{ p: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         User Tree
//       </Typography>
//       <Button
//         startIcon={<Add />}
//         variant="contained"
//         color="primary"
//         sx={{ mb: 2 }}
//         onClick={addUser}
//       >
//         Add New User
//       </Button>
//       <Box sx={{ minHeight: 352, minWidth: 250 }}>
//         <SimpleTreeView>
//           {subordinates
//             ?.filter(
//               (user) => !subordinates.some((sub) => sub.parentUser === user._id)
//             )
//             .map((user) => renderTree(user))}
//         </SimpleTreeView>
//       </Box>
//       {showDialog && (
//         <ConfirmationDialog
//           show={showDialog}
//           title="Confirm Deletion"
//           message="Are you sure you want to deactivate this user?"
//           onConfirm={handleDelete}
//           onCancel={() => setShowDialog(false)}
//         />
//       )}
//     </Box>
//   );
// };

// export default UserList;

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   IconButton,
//   Typography,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import ConfirmationDialog from "../common/ConfirmationDialog";
// import { Add } from "@mui/icons-material";
// import {
//   useGetSubordinatesQuery,
//   useUpdateUserMutation,
// } from "../../../features/user/userSlice";
// import { UserRole } from "../../../interfaces/UserRole";
// import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
// import { TreeItem } from "@mui/x-tree-view/TreeItem";

// const UserList: React.FC = () => {
//   const {
//     data: subordinates,
//     error,
//     isLoading,
//     refetch,
//   } = useGetSubordinatesQuery();
//   const [updateUser] = useUpdateUserMutation();
//   const navigate = useNavigate();
//   const [showDialog, setShowDialog] = useState<boolean>(false);
//   const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const addUser = () => {
//     navigate("/dashboard/add-user");
//   };

//   const handleDelete = async () => {
//     if (selectedUserId) {
//       await updateUser({ id: selectedUserId, user: { isActive: false } });
//       setShowDialog(false);
//       refetch();
//     }
//   };

//   const renderTree = (user: any, userMap: Record<string, any>) => {
//     const children = userMap[user._id] || []; // Find children of the current user

//     return (
//       <TreeItem
//         key={user._id}
//         itemId={user._id}
//         label={
//           <Box
//             display="flex"
//             justifyContent="space-between"
//             alignItems="center"
//           >
//             <Typography>{user.name}</Typography>
//             {(user.role === UserRole.OWNER ||
//               user.role === UserRole.HEAD_OF_SALES) && (
//               <Box>
//                 <IconButton
//                   color="primary"
//                   onClick={() => navigate(`/dashboard/edit-user/${user._id}`)}
//                 >
//                   <EditIcon />
//                 </IconButton>
//                 <IconButton
//                   color="error"
//                   onClick={() => {
//                     setSelectedUserId(user._id ?? null);
//                     setShowDialog(true);
//                   }}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </Box>
//             )}
//           </Box>
//         }
//       >
//         {/* Render children recursively */}
//         {children.map((child: any) => renderTree(child, userMap))}
//       </TreeItem>
//     );
//   };

//   if (isLoading) return <CircularProgress />;
//   if (error) return <Typography color="error">Error loading users</Typography>;

//   // Create a mapping of users to easily find children
//   const userMap: Record<string, any[]> = {};
//   const allUsersIds = new Set<string>(); // Track all user IDs

//   subordinates?.forEach((user) => {
//     allUsersIds.add(user._id!); // Add each user ID to the set
//     if (!userMap[user.parentUser!.toString()]) {
//       userMap[user.parentUser!.toString()] = [];
//     }
//     userMap[user.parentUser!.toString()].push(user);
//   });

//   // Identify root users (those without a parent)
//   const rootUsers =
//     subordinates?.filter(
//       (user) => !user.parentUser || !allUsersIds.has(user.parentUser.toString())
//     ) || [];

//   return (
//     <Box sx={{ p: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         User Tree
//       </Typography>
//       <Button
//         startIcon={<Add />}
//         variant="contained"
//         color="primary"
//         sx={{ mb: 2 }}
//         onClick={addUser}
//       >
//         Add New User
//       </Button>
//       <Box sx={{ minHeight: 352, minWidth: 250 }}>
//         <SimpleTreeView>
//           {rootUsers.map((user) => renderTree(user, userMap))}
//         </SimpleTreeView>
//       </Box>
//       {showDialog && (
//         <ConfirmationDialog
//           show={showDialog}
//           title="Confirm Deletion"
//           message="Are you sure you want to deactivate this user?"
//           onConfirm={handleDelete}
//           onCancel={() => setShowDialog(false)}
//         />
//       )}
//     </Box>
//   );
// };

// export default UserList;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { Add } from "@mui/icons-material";
import {
  useGetSubordinatesQuery,
  useUpdateUserMutation,
} from "../../../features/user/userSlice";
import { UserRole } from "../../../interfaces/UserRole";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/store";
import { selectCurrentUserRole } from "../../../features/auth/authSlice";

const UserList: React.FC = () => {
  const {
    data: subordinates,
    error,
    isLoading,
    refetch,
  } = useGetSubordinatesQuery();
  const [updateUser] = useUpdateUserMutation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const userRole = useSelector((state: RootState) =>
    selectCurrentUserRole(state)
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const addUser = () => {
    navigate("/dashboard/add-user");
  };

  const handleDelete = async () => {
    if (selectedUserId) {
      await updateUser({ id: selectedUserId, user: { isActive: false } });
      setShowDialog(false);
      refetch();
    }
  };

  const renderTree = (user: any, userMap: Record<string, any>) => {
    const children = userMap[user._id] || []; // Find children of the current user

    return (
      <TreeItem
        key={user._id}
        itemId={user._id}
        label={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: `${user.color}`, // Replace with your hex color
              padding: "8px", // Optional padding for better spacing
              borderRadius: "4px", // Optional rounded corners
            }}
          >
            <Typography>{user.name}</Typography>
            {(userRole === UserRole.OWNER ||
              userRole === UserRole.HEAD_OF_SALES) && (
              <Box>
                <IconButton
                  color="primary"
                  onClick={() => navigate(`/dashboard/edit-user/${user._id}`)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => {
                    setSelectedUserId(user._id ?? null);
                    setShowDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        }
      >
        {/* Render children recursively */}
        {children.map((child: any) => renderTree(child, userMap))}
      </TreeItem>
    );
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading users</Typography>;

  // Create a mapping of users to easily find children
  const userMap: Record<string, any[]> = {};
  const allUsersIds = new Set<string>(); // Track all user IDs

  subordinates?.forEach((user) => {
    allUsersIds.add(user._id!); // Add each user ID to the set
    if (!userMap[user.parentUser!.toString()]) {
      userMap[user.parentUser!.toString()] = [];
    }
    userMap[user.parentUser!.toString()].push(user);
  });

  // Identify root users (those without a parent)
  const rootUsers =
    subordinates?.filter(
      (user) =>
        !user.parentUser || !allUsersIds.has(user.parentUser!.toString())
    ) || [];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        User Tree
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={addUser}
      >
        Add New User
      </Button>
      <Box sx={{ minHeight: 352, minWidth: 250 }}>
        <SimpleTreeView>
          {rootUsers.map((user) => renderTree(user, userMap))}
        </SimpleTreeView>
      </Box>
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to deactivate this user?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Box>
  );
};

export default UserList;
