import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateDurationMutation } from "../../../features/duration/durationSlice";

const AddDuration: React.FC = () => {
  const [duration, setDuration] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createDuration] = useCreateDurationMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createDuration({ duration }).unwrap();
      setDuration("");
      navigate("/admin-dashboard/duration");
      toast.success("Duration added successfully");
    } catch (err) {
      toast.error("Failed to add Duration");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Add New Duration
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Duration"
          variant="outlined"
          margin="normal"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          required
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          sx={{ mt: 2 }}
        >
          {isFormLoading ? <CircularProgress size={24} /> : "Add Duration"}
        </Button>
      </form>
    </Box>
  );
};

export default AddDuration;
