import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDurationQuery,
  useUpdateDurationMutation,
} from "../../../features/duration/durationSlice"; // Adjust the import path accordingly
// Adjust the import path accordingly

const EditDuration: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, error, isLoading, refetch } = useGetDurationQuery(id!);
  const [updateDuration, { isLoading: isUpdating }] =
    useUpdateDurationMutation();
  const [duration, setDuration] = useState<string>("");

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (data) {
      setDuration(data.duration);
    }
  }, [data]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await updateDuration({
      id: id!,
      duration: { duration },
    }).unwrap();
    navigate("/admin-dashboard/duration");
  };

  if (isLoading) return <CircularProgress />;
  if (error)
    return <Typography color="error">Error loading duration</Typography>;

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Edit Duration
      </Typography>
      <TextField
        fullWidth
        label="Name"
        variant="outlined"
        margin="normal"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
        required
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        disabled={isUpdating}
        sx={{ mt: 2 }}
      >
        {isUpdating ? <CircularProgress size={24} /> : "Update Duration"}
      </Button>
    </Box>
  );
};

export default EditDuration;
