import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import Title from "./Title";
import { Lead } from "../interfaces/Lead";

interface RecentLeadsProps {
  leads: Lead[]; // Define the prop type explicitly
}

export default function RecentLeads({ leads }: RecentLeadsProps) {
  return (
    <React.Fragment>
      <Title>Recent Leads</Title>
      <List>
        {leads?.map((lead: Lead) => (
          <ListItem key={lead._id}>
            <ListItemText
              primary={lead.name}
              secondary={`Phone: ${
                typeof lead.customer === "object" &&
                typeof lead.customer?.primaryPhone.country === "object"
                  ? lead.customer?.primaryPhone.country?.code
                  : ""
              } ${
                typeof lead.customer === "object"
                  ? lead.customer?.primaryPhone?.phoneNumber
                  : ""
              }`}
            />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
}
