// import { useLocation, Navigate, Outlet } from "react-router-dom"
// import { useSelector } from "react-redux"
// import { selectCurrentToken } from "./authSlice"

// const RequireAuth = () => {
//     const token = useSelector(selectCurrentToken)
//     const location = useLocation()

//     return (
//         token
//             ? <Outlet />
//             : <Navigate to="/login" state={{ from: location }} replace />
//     )
// }
// export default RequireAuth

import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  selectCurrentToken,
  selectCurrentUser,
  setCredentials,
} from "./authSlice";
import { PaymentStatus } from "../../interfaces/Payment";
import { SubscriptionStatus } from "../../interfaces/Subscription";
import { clearRedirectPath, setRedirectPath } from "../redirectSlice";
import { useGetUserQuery, userApiSlice } from "../user/userSlice";
// import { useGetUserQuery } from "./authApiSlice";

const RequireAuth: React.FC = () => {
  const token = useSelector((state: RootState) => selectCurrentToken(state));
  const userData = useSelector((state: RootState) => selectCurrentUser(state));
  const navigate = useNavigate();
  const {
    data: user,
    refetch: refetchUser,
    isSuccess,
  } = useGetUserQuery(userData._id, {
    skip: !userData._id, // Ensure the query is only skipped if userData._id is falsy
  });

  const redirectPath = useSelector((state: RootState) => state.redirect.path);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchUserData = async () => {
      // Reset the API cache to clear the cached data for the user
      dispatch(userApiSlice.util.resetApiState());

      // Trigger the refetch API call
      const refetchResult = await refetchUser();

      // Check if the API call was successful
      if (refetchResult.isSuccess && refetchResult.data) {
        dispatch(setCredentials({ user: refetchResult.data }));
      }

      setLoading(false); // Stop the loading state after refetching
    };

    if (userData && userData?._id !== undefined && userData?._id !== null) {
      // Only refetch if we have the user's ID
      fetchUserData();
    } else {
      navigate("/login");
    }
  }, [refetchUser, userData._id, dispatch]);

  useEffect(() => {
    if (token && userData && isSuccess) {
      let newRedirectPath: string | null = null;

      // Determine the redirect path based on userData state

      // Check if the email is verified, if not, redirect to verification page
      if (!userData.isEmailVerified) {
        newRedirectPath = "/verify-email";
      } else if (userData.role === "owner") {
        if (typeof userData.company !== "object" || !userData.company) {
          newRedirectPath = "/company-setup";
        } else if (
          typeof userData.company.subscription !== "object" ||
          !userData.company.subscription
        ) {
          newRedirectPath = "/company-setup";
        } else if (
          typeof userData.company.subscription.payment !== "object" ||
          !userData.company.subscription.payment
        ) {
          newRedirectPath = "/company-setup";
        } else if (
          userData.company.subscription.payment.status ===
            PaymentStatus.PENDING ||
          userData.company.subscription.payment.status ===
            PaymentStatus.REQUESTED
        ) {
          newRedirectPath = "/waiting-confirmation";
        } else if (
          userData.company.subscription.status === SubscriptionStatus.EXPIRED
        ) {
          newRedirectPath = "/subscription-expired";
        }
        // else {
        //   newRedirectPath = "/dashboard";
        // }

        // console.log("Determined redirect path:", newRedirectPath);
      }

      // If a new redirect path is determined, set it in Redux
      if (newRedirectPath && newRedirectPath !== location.pathname) {
        // console.log("Setting redirect path in Redux:", newRedirectPath);
        dispatch(setRedirectPath(newRedirectPath));
      }

      // Set loading to false after logic is complete
      setLoading(false);
    } else {
      // No token or userData, set loading to false immediately
      setLoading(false);
    }
  }, [token, userData, location.pathname, dispatch]);

  useEffect(() => {
    // Perform the redirection outside of the render phase
    if (redirectPath) {
      // console.log("Redirecting to:", redirectPath);
      dispatch(clearRedirectPath());
      window.location.href = redirectPath; // As a fallback, force a redirect
    }
  }, [redirectPath, dispatch]);

  // Show a loading state while determining redirect path
  if (loading) {
    return null; // You can return a loading spinner or any placeholder component here
  }

  // Handle redirection if a redirect path is set
  if (redirectPath) {
    // console.log("Redirecting to:", redirectPath);
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  // If not authenticated, redirect to login
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If all checks pass, render the child components
  return <Outlet />;
};

export default RequireAuth;

// import { useEffect, useState } from "react";
// import { Navigate, Outlet, useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../store";
// import { selectCurrentToken, selectCurrentUser } from "./authSlice";
// import { PaymentStatus } from "../../interfaces/Payment";
// import { SubscriptionStatus } from "../../interfaces/Subscription";
// import { clearRedirectPath, setRedirectPath } from "../redirectSlice";
// import { useGetUserQuery } from "./authApiSlice";

// const RequireAuth: React.FC = () => {
//   const token = useSelector((state: RootState) => selectCurrentToken(state));
//   const userData = useSelector((state: RootState) => selectCurrentUser(state));
//   const { data: user, refetch: refetchUser } = useGetUserQuery(userData._id!);
//   const redirectPath = useSelector((state: RootState) => state.redirect.path);
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     refetchUser();
//   }, [refetchUser]);

//   useEffect(() => {
//     if (token && user) {
//       let newRedirectPath: string | null = null;

//       // if (user.role === "owner") {
//       //   if (
//       //     !user.company ||
//       //     !user.company.subscription ||
//       //     !user.company.subscription.payment
//       //   ) {
//       //     newRedirectPath = "/company-setup";
//       //   } else if (
//       //     user.company.subscription.payment?.status === PaymentStatus.PENDING ||
//       //     user.company.subscription.payment?.status === PaymentStatus.REQUESTED
//       //   ) {
//       //     newRedirectPath = "/waiting-confirmation";
//       //   } else if (
//       //     user.company.subscription.status === SubscriptionStatus.EXPIRED
//       //   ) {
//       //     newRedirectPath = "/subscription-expired";
//       //   }
//       // }

//       if (user.role === "owner") {
//         if (
//           typeof user.company !== "object" ||
//           !user.company ||
//           typeof user.company.subscription !== "object" ||
//           !user.company.subscription ||
//           typeof user.company.subscription.payment !== "object" ||
//           !user.company.subscription.payment
//         ) {
//           newRedirectPath = "/company-setup";
//         } else if (
//           user.company.subscription.payment.status === PaymentStatus.PENDING ||
//           user.company.subscription.payment.status === PaymentStatus.REQUESTED
//         ) {
//           newRedirectPath = "/waiting-confirmation";
//         } else if (
//           user.company.subscription.status === SubscriptionStatus.EXPIRED
//         ) {
//           newRedirectPath = "/subscription-expired";
//         }
//       }

//       if (newRedirectPath && newRedirectPath !== location.pathname) {
//         dispatch(setRedirectPath(newRedirectPath));
//       }

//       setLoading(false);
//     } else {
//       setLoading(false);
//     }
//   }, [token, user, location.pathname, dispatch]);

//   useEffect(() => {
//     if (redirectPath) {
//       dispatch(clearRedirectPath());
//     }
//   }, [redirectPath, dispatch]);

//   if (loading) {
//     return null; // Loading state placeholder
//   }

//   if (!token) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   if (redirectPath) {
//     return <Navigate to={redirectPath} state={{ from: location }} replace />;
//   }

//   return <Outlet />;
// };

// export default RequireAuth;

// // Define the types for the component
// const RequireAuth: React.FC = () => {
//   const token = useSelector((state: RootState) => selectCurrentToken(state));
//   const location = useLocation();

//   const user = useSelector((state: RootState) => selectCurrentUser(state));
//   console.log("user", user);

//   return token ? (
//     <Outlet />
//   ) : (
//     <Navigate to="/login" state={{ from: location }} replace />
//   );
// };

// export default RequireAuth;

// if (token && user) {
//   // If the user is authenticated and needs to complete company setup
//   if (
//     user?.role === "owner" &&
//     (user?.company === null ||
//       user.company?.subscription === null ||
//       user.company?.subscription?.payment === null)
//   ) {
//     return (
//       <Navigate to="/company-setup" state={{ from: location }} replace />
//     );
//   } else if (
//     user?.role === "owner" &&
//     (user?.company?.subscription?.payment?.status === PaymentStatus.PENDING ||
//       user?.company?.subscription?.payment?.status ===
//         PaymentStatus.REQUESTED)
//   ) {
//     return (
//       <Navigate
//         to="/waiting-confirmation"
//         state={{ from: location }}
//         replace
//       />
//     );
//   } else if (
//     user?.role === "owner" &&
//     user?.company?.subscription?.status === SubscriptionStatus.EXPIRED
//   ) {
//     return (
//       <Navigate
//         to="/subscription-expired"
//         state={{ from: location }}
//         replace
//       />
//     );
//   }
// }
