// import React, { useEffect, useState } from 'react';
// import { useUpdateUserMutation, useUpdateAvatarMutation, useGetUserQuery } from '../../features/auth/authApiSlice';
// import styled from "styled-components";
// import { useDispatch } from 'react-redux';
// import { ToastContainer, toast } from 'react-toastify'
// import { useParams } from 'react-router-dom';
// import { setCredentials } from '../../features/auth/authSlice';

// const Profile = () => {
//     // const user = useSelector(selectCurrentUser);
//     const { id } = useParams();
//     const { data: user, error, isLoading, refetch } = useGetUserQuery(id);
//     const dispatch = useDispatch();
//     const [name, setName] = useState(user?.name);
//     const [email, setEmail] = useState(user?.email);
//     const [avatar, setAvatar] = useState(user?.avatar?.url);
//     const [isFormLoading, setIsFormLoading] = useState(false);

//     const [updateUser] = useUpdateUserMutation();
//     const [updateAvatar] = useUpdateAvatarMutation();

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     useEffect(() => {
//         setName(user?.name);
//         setEmail(user?.email);
//         setAvatar(user?.avatar?.url);
//     }, [user]);

//     const handleUpdateProfile = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);
//         try {
//             const updatedUser = await updateUser({ id: user._id, updateUser: {name, email} });
//             dispatch(setCredentials({ user: updatedUser?.data })); // Dispatch only the updated user
//             setIsFormLoading(false);
//         } catch (error) {
//             console.error('Failed to update profile:', error);
//             setIsFormLoading(false);
//         }
//     };

//     const transformAvatarFile = (file) => {
//         return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         if (file) {
//             const maxSizeInBytes = 100 * 1024; // 100 KB
//             if (file.size > maxSizeInBytes) {
//             toast.error("File size exceeds the limit of 100 KB.", {
//                 position: "bottom-right",
//             });
//             reject(new Error("File size exceeds the limit of 100 KB."));
//             } else {
//             reader.readAsDataURL(file);
//             reader.onloadend = () => {
//                 setAvatar(reader.result);
//                 resolve(reader.result);
//             };
//             reader.onerror = () => {
//                 reject(new Error("Failed to read file."));
//             };
//             }
//         } else {
//             setAvatar("");
//             resolve("");
//         }
//         });
//     };

//     const handleAvatarUpload = async (e) => {
//         const file = e.target.files[0];
//         try {
//         const result = await transformAvatarFile(file);
//         handleUpdateAvatar(result);
//         } catch (error) {
//         console.error('Error transforming avatar file:', error);
//         }
//     };

//     const handleUpdateAvatar = async (avatarData) => {
//         setIsFormLoading(true);
//         try {
//             const updatedUser = await updateAvatar({ id: user._id, avatar: avatarData });
//             dispatch(setCredentials({ user: updatedUser?.data })); // Dispatch only the updated user
//         } catch (error) {
//         console.error('Failed to update avatar:', error);
//         } finally {
//         setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Add New Ad Group</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <div className="row justify-content-center">
//                                 <div className="col-8">
//                                     <div className='row m-3'>
//                                         <div className="col-lg-6 mt-1">
//                                         <ImagePreview className='mb-2 justify-content-center rounded shadow overflow-hidden bg-light p-1'>
//                                             {avatar ?
//                                             <>
//                                                 <img src={avatar} alt='image-1' />
//                                             </>
//                                             :
//                                             <p>Image Preview</p>}
//                                         </ImagePreview>
//                                             <div className="form-icon position-relative">
//                                                 <input type="file" id="input1" name="input1" accept="image/*" hidden onChange={handleAvatarUpload} />
//                                                 <label className="btn-upload btn btn-secondary w-100" for="input1">Upload Image</label>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <form onSubmit={(e) => handleUpdateProfile(e)}>
//                                         <div className="form-floating mb-2">
//                                             <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your Name" required />
//                                             <label htmlFor="name">Name</label>
//                                         </div>
//                                         <div className="form-floating mb-2">
//                                             <input type="email" className="form-control" id="email"value={email} required disabled />
//                                             <label htmlFor="email">Email address</label>
//                                         </div>
//                                         <button className="btn btn-secondary w-100" type="submit" disabled={isFormLoading}>Save Changes</button>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const ImagePreview = styled.div`
// #margin: 2rem 0 2rem 2rem;
// #padding: 2rem;
// border: 1px solid rgb(183, 183, 183);
// #width: 180px; /* Fixed width */
// height: 180px; /* Fixed height */
// display: flex;
// align-items: center;
// justify-content: center;
// color: rgb(78, 78, 78);

// img {
// border-radius: 50%;
// max-width: 100%;
// max-height: 100%; /* Add this line to maintain the aspect ratio */
// width: auto; /* Add this line to maintain the aspect ratio */
// height: auto; /* Add this line to maintain the aspect ratio */
// }
// `;

// export default Profile;

import React, { useEffect, useState } from "react";
import {
  useUpdateAdminMutation,
  useUpdateAvatarMutation,
  useGetAdminQuery,
} from "../../features/admin/adminApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setCredentials } from "../../features/admin/adminSlice";
import {
  Button,
  Container,
  TextField,
  Box,
  CircularProgress,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { RootState } from "../../features/store";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const AdminProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: admin, refetch } = useGetAdminQuery(id!);
  const dispatch = useDispatch();

  const currentAccessToken = useSelector(
    (state: RootState) => state.admin.token
  );
  const currentRefreshToken = useSelector(
    (state: RootState) => state.admin.refreshToken
  );

  const [name, setName] = useState(admin?.name || "");
  const [email, setEmail] = useState(admin?.email || "");
  const [avatar, setAvatar] = useState(admin?.avatar?.url || "");
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [updateAdmin] = useUpdateAdminMutation();
  const [updateAvatar] = useUpdateAvatarMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (admin) {
      setName(admin.name);
      setEmail(admin.email);
      setAvatar(admin.avatar?.url);
    }
  }, [admin]);

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      const updatedAdmin = await updateAdmin({
        id: admin?._id,
        updateAdmin: { name, email },
      }).unwrap();
      dispatch(
        setCredentials({
          admin: updatedAdmin,
          accessToken: currentAccessToken || "", // Reuse the current tokens
          refreshToken: currentRefreshToken || "",
        })
      );
      setIsFormLoading(false);
    } catch (error) {
      console.error("Failed to update profile:", error);
      setIsFormLoading(false);
    }
  };

  const transformAvatarFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        const maxSizeInBytes = 100 * 1024; // 100 KB
        if (file.size > maxSizeInBytes) {
          toast.error("File size exceeds the limit of 100 KB.", {
            position: "bottom-right",
          });
          reject(new Error("File size exceeds the limit of 100 KB."));
        } else {
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setAvatar(reader.result as string);
            resolve(reader.result as string);
          };
          reader.onerror = () => {
            reject(new Error("Failed to read file."));
          };
        }
      } else {
        setAvatar("");
        resolve("");
      }
    });
  };

  const handleAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    try {
      if (file) {
        const result = await transformAvatarFile(file);
        handleUpdateAvatar(result);
      }
    } catch (error) {
      console.error("Error transforming avatar file:", error);
    }
  };

  const handleUpdateAvatar = async (avatarData: string) => {
    setIsFormLoading(true);
    try {
      const updatedAdmin = await updateAvatar({
        id: admin?._id,
        avatar: avatarData,
      }).unwrap();
      dispatch(
        setCredentials({
          admin: updatedAdmin,
          accessToken: currentAccessToken || "", // Reuse the current tokens
          refreshToken: currentRefreshToken || "",
        })
      );
    } catch (error) {
      console.error("Failed to update avatar:", error);
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Update Profile
        </Typography>
        <ToastContainer />
        <Box display="flex" justifyContent="center" my={3}>
          <Box position="relative">
            <Avatar
              src={avatar || undefined}
              alt="Profile Picture"
              sx={{ width: 180, height: 180 }}
            />
            <input
              type="file"
              id="avatarInput"
              name="avatarInput"
              accept="image/*"
              hidden
              onChange={handleAvatarUpload}
            />
            <IconButton
              component="label"
              htmlFor="avatarInput"
              sx={{
                position: "absolute",
                bottom: 8,
                right: 8,
                bgcolor: "background.paper",
                borderRadius: "50%",
                p: 1,
              }}
            >
              <PhotoCamera />
            </IconButton>
          </Box>
        </Box>
        <form onSubmit={handleUpdateProfile}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            disabled
            required
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isFormLoading}
            endIcon={isFormLoading && <CircularProgress size={24} />}
          >
            Save Changes
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AdminProfile;
