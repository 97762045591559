import React, { useState, useEffect } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Box, Button, TextField, Typography } from "@mui/material";
import {
  useGetAdminLeadStatusQuery,
  useUpdateAdminLeadStatusMutation,
} from "../../../features/lead_status/adminLeadStatusSlice";

const AdminEditLeadStatus: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>("");
  const [color, setColor] = useState<string>("#000000");
  const [description, setDescription] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const { data: leadStatusData, refetch } = useGetAdminLeadStatusQuery(id!);
  const [updateLeadStatus] = useUpdateAdminLeadStatusMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (leadStatusData) {
      setStatus(leadStatusData?.status || "");
      setColor(leadStatusData?.color || "#000000");
      setDescription(leadStatusData?.description || "");
    }
  }, [leadStatusData]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await updateLeadStatus({
        id: id!,
        leadStatus: { status, color, description },
      }).unwrap();
      toast.success("Lead Status updated successfully");
      navigate("/admin-dashboard/lead-status");
    } catch (err) {
      toast.error("Failed to update Lead Status");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>
        Edit Lead Status
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Lead Status Name"
          variant="outlined"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          type="color"
          label="Lead Status Color"
          variant="outlined"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
        >
          {isFormLoading ? "Updating Lead Status..." : "Update Lead Status"}
        </Button>
        <Box textAlign="center" mt={3}>
          <Typography variant="body2">
            <NavLink to="/admin-dashboard/lead-status">
              Back to Lead Status List
            </NavLink>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default AdminEditLeadStatus;
