// import { useLocation, Navigate, Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { selectCurrentToken } from "./authSlice";

// const RedirectIfAuthenticated = () => {
//   const token = useSelector(selectCurrentToken);
//   const location = useLocation();

//   return token ? (
//     <Navigate to="/dashboard" state={{ from: location }} replace />
//   ) : (
//     <Outlet />
//   );
// };

// export default RedirectIfAuthenticated;

import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./adminSlice";
import { RootState } from "../store";

const AdminRedirectIfAuthenticated: React.FC = () => {
  const token = useSelector((state: RootState) => selectCurrentToken(state));
  // const admin = useSelector((state: RootState) => selectCurrentAdmin(state));
  const location = useLocation();

  if (token) {
    // If the admin is authenticated and needs to complete company setup
    // if (
    //   admin?.role === "owner" &&
    //   (admin?.company === null ||
    //     admin.company.subscription === null ||
    //     admin.company.subscription.isPaid)
    // ) {
    //   return (
    //     <Navigate to="/company-setup" state={{ from: location }} replace />
    //   );
    // }
    // Otherwise, redirect to the dashboard
    return (
      <Navigate to="/admin-dashboard" state={{ from: location }} replace />
    );
  }

  // If not authenticated, render the child components (e.g., login, register)
  return <Outlet />;
};

export default AdminRedirectIfAuthenticated;
