// import React, { useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import {
//   useResendVerificationCodeMutation,
//   useVerifyEmailMutation,
// } from "../../features/auth/authApiSlice";
// import { useSelector } from "react-redux";
// import { RootState } from "../../features/store";
// import { selectCurrentUser } from "../../features/auth/authSlice";

// const VerifyEmailForm = () => {
//   const [verificationCode, setVerificationCode] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();
//   const location = useLocation();
//   const userData = useSelector((state: RootState) => selectCurrentUser(state));
//   console.log("email", userData.email);
//   const [email, setEmail] = useState(userData?.email);

//   const [resendVerificationCode] = useResendVerificationCodeMutation();
//   const [verifyEmail] = useVerifyEmailMutation();

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     try {
//       // API call to verify email
//       const result = await verifyEmail({ email, verificationCode }).unwrap();
//       if (result.message === "Email verified successfully") {
//         navigate("/dashboard");
//       }
//     } catch (error) {
//       setErrorMessage("Invalid verification code or it has expired.");
//     }
//   };

//   const handleResendCode = async () => {
//     try {
//       await resendVerificationCode({ email }).unwrap();
//       setErrorMessage("Verification code resent, check your email.");
//     } catch (err) {
//       setErrorMessage("Failed to resend verification code.");
//     }
//   };

//   return (
//     <div>
//       <h2>Verify Your Email</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           value={verificationCode}
//           onChange={(e) => setVerificationCode(e.target.value)}
//           placeholder="Enter verification code"
//           required
//         />
//         <button type="submit">Verify</button>
//       </form>
//       {errorMessage && <p>{errorMessage}</p>}
//       <button onClick={handleResendCode}>Resend Verification Code</button>
//     </div>
//   );
// };

// export default VerifyEmailForm;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useResendVerificationCodeMutation,
  useVerifyEmailMutation,
} from "../../features/auth/authApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { Box, Button, TextField, Typography, Grid, Alert } from "@mui/material";

const VerifyEmailForm = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const [codeExpirationTime, setCodeExpirationTime] = useState(300); // 300 seconds (5 minutes) expiration
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state: RootState) => selectCurrentUser(state));
  const [email, setEmail] = useState(userData?.email);

  const [resendVerificationCode] = useResendVerificationCodeMutation();
  const [verifyEmail] = useVerifyEmailMutation();

  // Countdown timer effect for the resend button
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (resendTimer > 0) {
      timerId = setTimeout(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearTimeout(timerId);
  }, [resendTimer]);

  // Countdown for code expiration (5 mins)
  useEffect(() => {
    let expirationTimerId: NodeJS.Timeout;
    if (codeExpirationTime > 0) {
      expirationTimerId = setTimeout(() => {
        setCodeExpirationTime((prev) => prev - 1);
      }, 1000);
    }
    return () => clearTimeout(expirationTimerId);
  }, [codeExpirationTime]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      // API call to verify email
      const result = await verifyEmail({ email, verificationCode }).unwrap();
      if (result.message === "Email verified successfully") {
        navigate("/company-setup");
      }
    } catch (error) {
      setErrorMessage("Invalid verification code or it has expired.");
    }
  };

  const handleResendCode = async () => {
    try {
      await resendVerificationCode({ email }).unwrap();
      setResendTimer(60); // Reset timer for resend button
      setCodeExpirationTime(300); // Reset expiration time (5 minutes)
      setErrorMessage("Verification code resent, check your email.");
    } catch (err) {
      setErrorMessage("Failed to resend verification code.");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ minHeight: "100vh", backgroundColor: "#f4f4f4" }}
    >
      {/* Logo at the top */}
      <Grid item>
        <Box mb={3}>
          <img
            src="/assets/landing/appbar_logo.png" // Replace with your logo path
            alt="Company Logo"
            style={{ width: 150, display: "block", margin: "0 auto" }}
          />
        </Box>
      </Grid>

      {/* Email Verification Form */}
      <Grid item xs={12} sm={8} md={4}>
        <Box
          bgcolor="white"
          p={4}
          boxShadow={3}
          borderRadius={4}
          textAlign="center"
        >
          {/* Show message about code expiration */}
          <Typography variant="body2" color="textSecondary">
            The verification code will expire in{" "}
            {Math.floor(codeExpirationTime / 60)}:
            {codeExpirationTime % 60 < 10
              ? `0${codeExpirationTime % 60}`
              : codeExpirationTime % 60}{" "}
            minutes.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Verify Your Email
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Verification Code"
              variant="outlined"
              fullWidth
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter verification code"
              required
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Verify
            </Button>
          </form>
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
          )}
          {/* Disable Resend button until the timer reaches 0 */}
          <Button
            onClick={handleResendCode}
            variant="text"
            color="primary"
            fullWidth
            disabled={resendTimer > 0} // Disable the button if timer is running
            sx={{ mt: 2 }}
          >
            {resendTimer > 0
              ? `Resend Code in ${resendTimer}s`
              : "Resend Verification Code"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VerifyEmailForm;
