import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { LeadStatus } from "../../../interfaces/LeadStatus";
import {
  useDeleteAdminLeadStatusMutation,
  useGetAdminLeadStatusesQuery,
} from "../../../features/lead_status/adminLeadStatusSlice";

const AdminLeadStatusList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data, error, isLoading, refetch } = useGetAdminLeadStatusesQuery({
    page,
    limit,
  });
  const [deleteLeadStatus] = useDeleteAdminLeadStatusMutation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedLeadStatusId, setSelectedLeadStatusId] = useState<
    string | null
  >(null);

  const addLeadStatus = () => {
    navigate("/admin-dashboard/add-lead-status");
  };

  const handleDelete = async () => {
    if (selectedLeadStatusId) {
      await deleteLeadStatus(selectedLeadStatusId);
      setShowDialog(false);
      refetch();
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const totalPages = Math.ceil((data?.total || 0) / limit);

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography>Error loading lead statuses</Typography>;

  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>
        Lead Status Table
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        onClick={addLeadStatus}
        sx={{ marginBottom: 2 }}
      >
        Add New Lead Status
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lead Status Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Modified Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((leadStatus: LeadStatus) => (
              <TableRow key={leadStatus._id}>
                <TableCell>{leadStatus.status}</TableCell>
                <TableCell>
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      width: 20,
                      height: 20,
                      backgroundColor: leadStatus.color,
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px", // Set a max width according to your design
                  }}
                >
                  {leadStatus.description}
                </TableCell>
                <TableCell>
                  {moment(leadStatus.createdAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell>
                  {moment(leadStatus.updatedAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    component={Link}
                    to={`/admin-dashboard/edit-lead-status/${leadStatus._id}`}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => {
                      setSelectedLeadStatusId(leadStatus._id!);
                      setShowDialog(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, newPage) => handlePageChange(newPage)}
        sx={{ mt: 2, display: "flex", justifyContent: "center" }}
      />
      <ConfirmationDialog
        show={showDialog}
        title="Delete Lead Status"
        message="Are you sure you want to delete this lead status?"
        onConfirm={handleDelete}
        onCancel={() => setShowDialog(false)}
      />
    </Box>
  );
};

export default AdminLeadStatusList;
