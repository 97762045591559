// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   createTheme,
//   Step,
//   StepLabel,
//   Stepper,
//   ThemeProvider,
//   Typography,
// } from "@mui/material";
// import { Company } from "../../../interfaces/Company";
// import { useCreateCompanyMutation } from "../../../features/company/companySlice";
// import CompanyDetailsForm from "./CompanyDetailsForm";
// import PricingPlansForm from "./PricingPlansForm";
// import PaymentForm from "./PaymentForm";
// import { toast, ToastContainer } from "react-toastify";

// const steps = ["Company Details", "Pricing Plans", "Payment"];

// const StepperForm: React.FC = () => {
//   // TODO remove, this demo shouldn't need to reset the theme.
//   const defaultTheme = createTheme();

//   const [activeStep, setActiveStep] = useState(0);
//   const [companyData, setCompanyData] = useState<Partial<Company>>({});
//   const [createCompany] = useCreateCompanyMutation();

//   const handleNext = () => {
//     // Add validation logic here before proceeding to the next step
//     if (activeStep === 0) {
//       if (
//         !companyData.name ||
//         !companyData.logo ||
//         !companyData.contactPersonInformation?.firstName ||
//         !companyData.contactPersonInformation?.lastName ||
//         !companyData.contactPersonInformation?.email ||
//         !companyData.contactPersonInformation?.phoneNumber
//       ) {
//         toast.error("Please fill all required fields before proceeding.");
//         return;
//       }
//     }
//     setActiveStep((prevStep) => prevStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevStep) => prevStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//     setCompanyData({});
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Container
//         component="main"
//         sx={{
//           // padding: "20px", // or use theme spacing, e.g., theme.spacing(2)
//           p: 4,
//         }}
//       >
//         <Box sx={{ width: "100%" }}>
//           <ToastContainer />
//           <Stepper activeStep={activeStep}>
//             {steps.map((label) => (
//               <Step key={label}>
//                 <StepLabel>{label}</StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//           {activeStep === steps.length ? (
//             <Box>
//               <Typography sx={{ mt: 2, mb: 1 }}>
//                 All steps completed - your company has been created.
//               </Typography>
//               <Button onClick={handleReset}>Reset</Button>
//             </Box>
//           ) : (
//             <Box>
//               {getStepContent(activeStep, companyData, setCompanyData)}
//               <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
//                 <Button
//                   color="inherit"
//                   disabled={activeStep === 0}
//                   onClick={handleBack}
//                   sx={{ mr: 1 }}
//                 >
//                   Back
//                 </Button>
//                 <Button onClick={handleNext}>
//                   {activeStep === steps.length - 1 ? "Finish" : "Next"}
//                 </Button>
//               </Box>
//             </Box>
//           )}
//         </Box>
//       </Container>
//     </ThemeProvider>
//   );
// };

// function getStepContent(
//   step: number,
//   companyData: Partial<Company>,
//   setCompanyData: React.Dispatch<React.SetStateAction<Partial<Company>>>
// ) {
//   switch (step) {
//     case 0:
//       return (
//         <CompanyDetailsForm
//           companyData={companyData}
//           setCompanyData={setCompanyData}
//         />
//       );
//     case 1:
//       return (
//         <PricingPlansForm
//           companyData={companyData}
//           setCompanyData={setCompanyData}
//         />
//       );
//     case 2:
//       return (
//         <PaymentForm
//           companyData={companyData}
//           setCompanyData={setCompanyData}
//         />
//       );
//     default:
//       throw new Error("Unknown step");
//   }
// }

// export default StepperForm;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  createTheme,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Company } from "../../../interfaces/Company";
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
} from "../../../features/company/companySlice"; // Ensure you have the hooks imported
import CompanyDetailsForm from "./CompanyDetailsForm";
import PaymentForm from "./PaymentForm";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/store";
import { selectCurrentUser } from "../../../features/auth/authSlice";
// import { useGetUserQuery } from "../../../features/auth/authApiSlice";
import PricingPlansForm from "./PricingPlansForm";
import {
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from "../../../features/subscription/subscriptionSlice";
import { SubscriptionStatus } from "../../../interfaces/Subscription";
import { useNavigate } from "react-router-dom";
import { useCreatePaymentMutation } from "../../../features/payment/paymentSlice";
import { Payment, PaymentStatus } from "../../../interfaces/Payment";
import { useGetUserQuery } from "../../../features/user/userSlice";

const steps = ["Company Details", "Pricing Plans", "Payment"];

const StepperForm: React.FC = () => {
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => selectCurrentUser(state));
  const {
    data: user,
    refetch: refetchUser,
    isSuccess,
  } = useGetUserQuery(userData._id!);
  const [loading, setLoading] = useState(false);

  const defaultTheme = createTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [companyData, setCompanyData] = useState<Partial<Company>>({});

  const [phoneError, setPhoneError] = useState<string>("");

  const [ipa, setIpa] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [amount, setAmount] = useState<string>("");

  const [paymentData, setPaymentData] = useState<Partial<Payment>>({});

  const [createCompany] = useCreateCompanyMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  // Subscription mutation
  const [createSubscription] = useCreateSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();

  const [createPayment] = useCreatePaymentMutation();

  // Store company-related information in local state
  const [companyId, setCompanyId] = useState<string>("");
  const [subscriptionId, setSubscriptionId] = useState<string>("");
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>(
    SubscriptionStatus.READY_TO_PAY
  );
  // const [isPaid, setIsPaid] = useState<boolean>(false);
  const [selectedPricingPlanId, setSelectedPricingPlanId] =
    useState<string>(""); // New state for selected plan

  useEffect(() => {
    refetchUser();
  }, [refetchUser, activeStep]);

  // Update local state when the user data is available
  useEffect(() => {
    if (
      user &&
      isSuccess &&
      typeof user.company === "object" &&
      user.company !== null
    ) {
      setCompanyId(user.company?._id!);
      if (
        typeof user.company.subscription === "object" &&
        user.company.subscription !== null
      ) {
        setSubscriptionId(user.company?.subscription?._id!);
        // setIsPaid(user.company?.subscription?.isPaid);
        setSubscriptionStatus(user.company?.subscription?.status);
        setAmount(user.company?.subscription?.paidAmount.toString());
        if (typeof user.company.subscription?.payment === "object") {
          setPaymentData(user.company?.subscription?.payment);
        }
      }
      setCompanyData(user.company);
    }
  }, [user, isSuccess, activeStep]);

  // Set the initial active step based on the company data
  useEffect(() => {
    if (!companyId) {
      setActiveStep(0);
    } else if (!subscriptionId) {
      setActiveStep(1);
    } else if (
      subscriptionStatus === SubscriptionStatus.READY_TO_PAY &&
      paymentData === null
    ) {
      setActiveStep(2);
    } else if (
      paymentData !== null &&
      (paymentData?.status === PaymentStatus.PENDING ||
        paymentData?.status === PaymentStatus.REQUESTED)
    ) {
      navigate("/waiting-confirmation");
    } else if (paymentData?.status === PaymentStatus.SUCCESSFUL) {
      navigate("/dashboard");
    }
  }, [companyId, subscriptionId, subscriptionStatus, paymentData, navigate]);

  // // Fetch company data if editing
  // const { data: fetchedCompany, refetch } = useGetCompanyQuery(
  //   companyId || "",
  //   {
  //     skip: !companyId, // Skip the query if there's no companyId
  //   }
  // );
  // console.log("fetchedCompany", fetchedCompany);

  // Trigger refetch on page load and when companyId changes
  // useEffect(() => {
  //   if (companyId) {
  //     refetch();
  //   }
  // }, [companyId, refetch]);

  // useEffect(() => {
  //   if (fetchedCompany) {
  //     setCompanyData(fetchedCompany);
  //   }
  // }, [fetchedCompany]);

  const handleNext = async () => {
    setLoading(true); // Disable the buttons while loading
    try {
      // Add validation logic here before proceeding to the next step
      if (activeStep === 0) {
        if (
          !companyData.name ||
          !companyData.logo ||
          !companyData.contactPersonInformation?.firstName ||
          !companyData.contactPersonInformation?.lastName ||
          !companyData.contactPersonInformation?.email ||
          !companyData.contactPersonInformation?.phoneNumber
        ) {
          toast.error("Please fill all required fields before proceeding.");
          return;
        }

        // New condition to check for phoneError
        if (phoneError) {
          toast.error("Please correct phone number before proceeding.");
          return;
        }
        if (companyId) {
          const updatedCompany: Company = {
            _id: companyId,
            name: companyData.name,
            logo: companyData.logo,
            contactPersonInformation: {
              firstName: companyData.contactPersonInformation.firstName,
              middleName: companyData.contactPersonInformation.middleName,
              lastName: companyData.contactPersonInformation.lastName,
              email: companyData.contactPersonInformation.email,
              phoneNumber: companyData.contactPersonInformation.phoneNumber,
            },
            locations: [],
          };
          // Update the existing company
          await updateCompany({
            id: companyId,
            company: updatedCompany,
          }).unwrap();
          toast.success("Company updated successfully.");
        } else {
          // Create a new company
          const createdCompany = await createCompany(companyData).unwrap();
          if (createdCompany) {
            toast.success("Company created successfully.");
            // Optionally set the companyId in state if needed
            setCompanyData(createdCompany);
          } else {
            throw new Error("Company creation failed.");
          }
        }
      } else if (activeStep === 1) {
        // Create subscription on the second step
        if (!selectedPricingPlanId) {
          toast.error("Please select a pricing plan.");
          return;
        }
        if (subscriptionId) {
          // Update the existing subscription
          await updateSubscription({
            id: subscriptionId,
            subscription: {
              company: companyId,
              pricingPlan: selectedPricingPlanId,
              paidAmount: 0, // Adjust based on actual logic
              duration: "", // Replace with selected duration
              status: SubscriptionStatus.READY_TO_PAY,
              isActive: true,
            },
          }).unwrap();
          toast.success("Subscription updated successfully.");
        } else {
          const newSubscription = await createSubscription({
            company: companyId,
            pricingPlan: selectedPricingPlanId,
            paidAmount: 0, // Adjust based on actual logic
            duration: "", // Replace with selected duration
            status: SubscriptionStatus.READY_TO_PAY,
            isActive: true,
          }).unwrap();
          toast.success("Subscription created successfully.");
          setSubscriptionId(newSubscription._id!);
        }
      } else if (activeStep === 2) {
        // Validate the fields in the PaymentForm before proceeding
        if (!ipa || !mobileNumber || !amount) {
          toast.error("Please fill all required fields before proceeding.");
          return;
        }

        // Call createPayment here using the necessary parameters
        const paymentResponse = await createPayment({
          subscription: subscriptionId,
          IPA: ipa,
          mobileNumber: `+20${mobileNumber}`, // Concatenate the country code
          amount: Number(amount), // Ensure the amount is a number
        }).unwrap();

        // If payment creation is successful, navigate to the waiting approval page
        if (paymentResponse) {
          toast.success("Payment created successfully.");
          navigate("/waiting-confirmation");
        } else {
          toast.error("Payment failed.");
          // throw new Error("Payment failed.");
        }
      }

      // Advance to the next step only if API call succeeds
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error("Error during step handling:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Enable the buttons when finished
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompanyData({});
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        sx={{
          p: 4,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <ToastContainer />
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <Box>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - your company has been{" "}
                {companyId ? "updated" : "created"}.
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mx: "auto",
              }}
            >
              {getStepContent(
                activeStep,
                companyData,
                setCompanyData,
                phoneError, // Pass down phoneError
                setPhoneError, // Pass down the setter as well
                setSelectedPricingPlanId,
                ipa,
                setIpa,
                mobileNumber,
                setMobileNumber,
                amount,
                setAmount
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0 || loading}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button onClick={handleNext} disabled={loading}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

function getStepContent(
  step: number,
  companyData: Partial<Company>,
  setCompanyData: React.Dispatch<React.SetStateAction<Partial<Company>>>,
  phoneError: string,
  setPhoneError: React.Dispatch<React.SetStateAction<string>>,
  setSelectedPricingPlanId: React.Dispatch<React.SetStateAction<string>>,
  ipa: string,
  setIpa: React.Dispatch<React.SetStateAction<string>>,
  mobileNumber: string,
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>,
  amount: string,
  setAmount: React.Dispatch<React.SetStateAction<string>>
) {
  switch (step) {
    case 0:
      return (
        <CompanyDetailsForm
          companyData={companyData}
          setCompanyData={setCompanyData}
          phoneError={phoneError} // Pass phoneError
          setPhoneError={setPhoneError} // Pass setter for phoneError
        />
      );
    case 1:
      return (
        <PricingPlansForm
          setSelectedPricingPlanId={setSelectedPricingPlanId} // Pass down setter
          // companyData={companyData}
          // setCompanyData={setCompanyData}
        />
      );
    case 2:
      return (
        <PaymentForm
          ipa={ipa}
          setIpa={setIpa}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          amount={amount}
          setAmount={setAmount}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

export default StepperForm;
