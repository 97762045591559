// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { Modal, Button, Form } from 'react-bootstrap';
// import { toast } from 'react-toastify';
// import styled from 'styled-components';
// import { useGetLocationQuery, useUpdateLocationMutation } from '../../../../features/location/locationSlice';

// const EditLocation = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const { data: location, isLoading, refetch } = useGetLocationQuery(id);
//   const [updateLocation] = useUpdateLocationMutation();

//   const [formData, setFormData] = useState({
//     name: '',
//     country: '',
//     city: '',
//     region: '',
//     masterPlan: '',
//     images: [],
//     latitude: '',
//     longitude: '',
//     unitTypes: [],
//     paymentPlans: [],
//   });

//   const [masterPlanPreview, setMasterPlanPreview] = useState(null);
//   const [imagesPreviews, setImagesPreviews] = useState([]);

//   const [unitType, setUnitType] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [paymentPlan, setPaymentPlan] = useState({
//     name: '',
//     duration: '',
//     downPayment: '',
//     installments: [],
//   });
//   const [installment, setInstallment] = useState({ duration: '', amount: '' });

//   useEffect(() => {
//     if (location) {
//       setFormData(location);
//       setMasterPlanPreview(location.masterPlan.url || '');
//       setImagesPreviews(location.images.map(img => img.url) || []);
//     }
//   }, [location]);

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const handleImageUpload = (e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setFormData({ ...formData, masterPlan: reader.result });
//       setMasterPlanPreview(reader.result);
//     };
//     if (file) {
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleImageUploadMultiple = (e) => {
//     const files = Array.from(e.target.files);
//     if (files.length + formData.images.length > 3) {
//       toast.error('Cannot upload more than 3 images', { position: 'bottom-right' });
//       return;
//     }

//     const newImages = [];
//     const newImagePreviews = [];

//     files.forEach(file => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         newImages.push(reader.result);
//         newImagePreviews.push(reader.result);
//         if (newImages.length === files.length) {
//           setFormData(prevState => ({
//             ...prevState,
//             images: [...prevState.images, ...newImages],
//           }));
//           setImagesPreviews(prevState => [...prevState, ...newImagePreviews]);
//         }
//       };
//       reader.readAsDataURL(file);
//     });
//   };

//   const handleRemoveImage = (index) => {
//     const newImages = formData.images.filter((_, i) => i !== index);
//     const newImagePreviews = imagesPreviews.filter((_, i) => i !== index);
//     setFormData({ ...formData, images: newImages });
//     setImagesPreviews(newImagePreviews);
//   };

//   // const handleAddUnitType = () => {
//   //   if (unitType && !formData.unitTypes.includes(unitType)) {
//   //     setFormData({ ...formData, unitTypes: [...formData.unitTypes, unitType] });
//   //     setUnitType('');
//   //   }
//   // };

//   const handleAddUnitType = () => {
//     if (unitType && !formData.unitTypes.includes(unitType)) {
//       setFormData((prevState) => ({
//         ...prevState,
//         unitTypes: [...prevState.unitTypes, unitType]
//       }));
//       setUnitType('');
//     }
//   };

//   const handleRemoveUnitType = (type) => {
//     setFormData({ ...formData, unitTypes: formData.unitTypes.filter((t) => t !== type) });
//   };

//   const handleAddInstallment = () => {
//     if (installment.duration && installment.amount) {
//       setPaymentPlan({
//         ...paymentPlan,
//         installments: [...paymentPlan.installments, installment],
//       });
//       setInstallment({ duration: '', amount: '' });
//     }
//   };

//   const handleRemoveInstallment = (index) => {
//     setPaymentPlan({
//       ...paymentPlan,
//       installments: paymentPlan.installments.filter((_, i) => i !== index),
//     });
//   };

//   const handleAddPaymentPlan = () => {
//     if (paymentPlan.name && paymentPlan.duration && paymentPlan.downPayment) {
//       setFormData({
//         ...formData,
//         paymentPlans: [...formData.paymentPlans, paymentPlan],
//       });
//       setShowModal(false);
//       setPaymentPlan({ name: '', duration: '', downPayment: '', installments: [] });
//     }
//   };

//   const handleEditPaymentPlan = (index) => {
//     setPaymentPlan(formData.paymentPlans[index]);
//     setShowModal(true);
//     handleRemovePaymentPlan(index);
//   };

//   const handleRemovePaymentPlan = (index) => {
//     setFormData({
//       ...formData,
//       paymentPlans: formData.paymentPlans.filter((_, i) => i !== index),
//     });
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//       e.preventDefault();
//       console.log('Submitting form with data:', formData); // Log the form data
//       try {
//           await updateLocation({ id: id, location: formData }).unwrap(); // Ensure you are sending the complete formData
//           toast.success('Location updated successfully!', { position: 'bottom-right' });
//           navigate('/dashboard/location'); // Redirect to locations list or wherever after successful update
//       } catch (error) {
//           console.error('Error updating location:', error);
//           toast.error('Failed to update location. Please try again later.', {
//               position: 'bottom-right',
//           });
//       }
//   };

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="container">
//       <h2>Edit Location</h2>
//       <form onSubmit={handleSubmit}>
//         <div className="form-floating mb-2">
//             <input
//                 type="text"
//                 className="form-control"
//                 name='name'
//                 value={formData.name}
//                 onChange={handleChange}
//                 placeholder="Location Name"
//             />
//           <label htmlFor="name">Location Name</label>
//         </div>
//         <div className="row">
//           <div className="col-md-4 mb-3">
//             <div className="form-floating mb-2">
//               <input
//                 type="text"
//                 className="form-control"
//                 name="country"
//                 value={formData.country}
//                 onChange={handleChange}
//                 required
//               />
//               <label htmlFor="country">Country</label>
//             </div>
//           </div>
//           <div className="col-md-4 mb-3">
//             <div className="form-floating mb-2">
//               <input
//                 type="text"
//                 className="form-control"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//                 required
//               />
//               <label htmlFor="city">City</label>
//             </div>
//           </div>
//           <div className="col-md-4 mb-3">
//             <div className="form-floating mb-2">
//               <input
//                 type="text"
//                 className="form-control"
//                 name="region"
//                 value={formData.region}
//                 onChange={handleChange}
//               />
//               <label htmlFor="region">Region</label>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-6 mb-3">
//             <div className="form-floating mb-2">
//               <input
//                 type="number"
//                 className="form-control"
//                 name="latitude"
//                 value={formData.latitude}
//                 onChange={handleChange}
//                 required
//               />
//               <label htmlFor="latitude">Latitude</label>
//             </div>
//           </div>
//           <div className="col-md-6 mb-3">
//             <div className="form-floating mb-2">
//               <input
//                 type="number"
//                 className="form-control"
//                 name="longitude"
//                 value={formData.longitude}
//                 onChange={handleChange}
//                 required
//               />
//               <label htmlFor="longitude">Longitude</label>
//             </div>
//           </div>
//         </div>
//         <div className="mb-3">
//           <ImagePreview>
//             {masterPlanPreview ? (
//               <img src={masterPlanPreview} alt="Master Plan Preview" />
//             ) : (
//               <p>No Master Plan Image Uploaded</p>
//             )}
//           </ImagePreview>
//           <div className="form-icon position-relative">
//             <input type="file" id="masterPlan" name="masterPlan" accept="image/*" hidden onChange={handleImageUpload} />
//             <label className="btn-upload btn btn-primary w-100" htmlFor="masterPlan">Upload Master Plan Image</label>
//           </div>
//         </div>
//         <div className="mb-3">
//           <ImagePreview>
//             {imagesPreviews.length > 0 ? (
//               imagesPreviews.map((preview, index) => (
//                 <div key={index} style={{ position: 'relative' }}>
//                   <img src={preview} alt={`Image ${index}`} />
//                   <button
//                     type="button"
//                     style={{ position: 'absolute', top: 0, right: 0, background: 'none', border: 'none', color: 'red' }}
//                     onClick={() => handleRemoveImage(index)}
//                   >
//                     <i className="bi bi-trash"></i>
//                   </button>
//                 </div>
//               ))
//             ) : (
//               <p>No Images Uploaded</p>
//             )}
//           </ImagePreview>
//           <div className="form-icon position-relative">
//             <input type="file" id="images" name="images" accept="image/*" hidden multiple onChange={handleImageUploadMultiple} />
//             <label className="btn-upload btn btn-primary w-100" htmlFor="images">Upload Image (Up to 3)</label>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-12 mb-3 d-flex">
//             <div className="form-floating flex-grow-1 mb-2 me-2">
//               <input
//                 type="text"
//                 className="form-control"
//                 value={unitType}
//                 onChange={(e) => setUnitType(e.target.value)}
//               />
//               <label htmlFor="unitType">Unit Type</label>
//             </div>
//             <button type="button" className="btn btn-secondary align-self-end mb-2" onClick={handleAddUnitType}>
//               Add Unit Type
//             </button>
//           </div>
//         </div>
//         <div className="mb-3">
//           <h5>Unit Types</h5>
//           <UnitTypeList>
//             {formData.unitTypes?.map((type, index) => (
//               <UnitTypeItem key={index}>
//                 {type}
//                 <button type="button" onClick={() => handleRemoveUnitType(type)}>
//                   <i className="bi bi-trash"></i>
//                 </button>
//               </UnitTypeItem>
//             ))}
//           </UnitTypeList>
//         </div>
//         <div className="row">
//           <div className="col-md-12 mb-3">
//             <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>
//               Add Payment Plan
//             </button>
//             <div className="mt-2">
//               {formData.paymentPlans?.map((plan, index) => (
//                 <PaymentPlanItem key={index}>
//                   <div>
//                     {plan.name} ({plan.duration}, {plan.downPayment})
//                     <ul>
//                       {plan.installments?.map((inst, i) => (
//                         <li key={i}>
//                           {inst.duration}: {inst.amount}
//                         </li>
//                       ))}
//                     </ul>
//                   </div>
//                   <div>
//                     <button type="button" onClick={() => handleEditPaymentPlan(index)}>
//                       <i className="bi bi-pencil-square"></i>
//                     </button>
//                     <button type="button" onClick={() => handleRemovePaymentPlan(index)}>
//                       <i className="bi bi-trash"></i>
//                     </button>
//                   </div>
//                 </PaymentPlanItem>
//               ))}
//             </div>
//           </div>
//         </div>
//         <button type="submit" className="btn btn-primary">
//           Save Changes
//         </button>
//       </form>

//       {/* Payment Plan Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>{paymentPlan.name ? 'Edit Payment Plan' : 'Add Payment Plan'}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group className="mb-3">
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={paymentPlan.name}
//                 onChange={(e) => setPaymentPlan({ ...paymentPlan, name: e.target.value })}
//               />
//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Duration</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={paymentPlan.duration}
//                 onChange={(e) => setPaymentPlan({ ...paymentPlan, duration: e.target.value })}
//               />
//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Down Payment</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={paymentPlan.downPayment}
//                 onChange={(e) => setPaymentPlan({ ...paymentPlan, downPayment: e.target.value })}
//               />
//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Installments</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Duration"
//                 value={installment.duration}
//                 onChange={(e) => setInstallment({ ...installment, duration: e.target.value })}
//               />
//               <Form.Control
//                 type="number"
//                 placeholder="Amount"
//                 value={installment.amount}
//                 onChange={(e) => setInstallment({ ...installment, amount: e.target.value })}
//               />
//               <Button variant="secondary" onClick={handleAddInstallment}>
//                 Add Installment
//               </Button>
//               <ul>
//                 {paymentPlan.installments?.map((inst, index) => (
//                   <li key={index}>
//                     {inst.duration}: {inst.amount}{' '}
//                     <button style={{ border: 'none' }} type="button" onClick={() => handleRemoveInstallment(index)}>
//                       <i className="bi bi-trash text-danger"></i>
//                     </button>
//                   </li>
//                 ))}
//               </ul>
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleAddPaymentPlan}>
//             {paymentPlan.name ? 'Save Changes' : 'Add Payment Plan'}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// const ImagePreview = styled.div`
//   margin: 2rem 0 2rem 2rem;
//   padding: 1rem;
//   border: 1px solid rgb(183, 183, 183);
//   height: 180px; /* Fixed height */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: rgb(78, 78, 78);

//   img {
//     max-width: 100%;
//     max-height: 100%; /* Add this line to maintain the aspect ratio */
//     width: auto; /* Add this line to maintain the aspect ratio */
//     height: auto; /* Add this line to maintain the aspect ratio */
//     padding: 0.5rem;
//   }
// `;

// const UnitTypeList = styled.div`
//   padding: 0.5rem;
//   border: 1px solid #ddd;
//   border-radius: 4px;
//   margin-top: 0.5rem;
//   max-height: 150px;
//   overflow-y: auto;
// `;

// const UnitTypeItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0.5rem;
//   border-bottom: 1px solid #eee;

//   button {
//     background: none;
//     border: none;
//     color: red;
//     cursor: pointer;
//   }
// `;

// const PaymentPlanItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0.5rem;
//   border-bottom: 1px solid #eee;

//   button {
//     background: none;
//     border: none;
//     color: #007bff;
//     cursor: pointer;
//     margin-left: 0.5rem;
//   }
// `;

// export default EditLocation;

import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Modal,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { toast } from "react-toastify";
// import { styled } from "@mui/material/styles";
import styled from "styled-components";
import {
  useGetLocationQuery,
  useUpdateLocationMutation,
} from "../../../features/location/locationSlice";
import { Location } from "../../../interfaces/Location";
import { PaymentPlan } from "../../../interfaces/PaymentPlan";
import { Installment } from "../../../interfaces/Installment";
import {
  Upload as UploadIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

// const ImagePreview = styled(Box)({
//   display: "flex",
//   flexDirection: "row",
//   flexWrap: "wrap",
//   gap: "10px",
//   "& img": {
//     width: "100px",
//     height: "100px",
//     objectFit: "cover",
//   },
// });

const ImagePreview = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const PaymentPlanItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 0",
  borderBottom: "1px solid #ddd",
});

// interface Installment {
//   duration: string;
//   amount: string;
// }

// interface PaymentPlan {
//   name: string;
//   duration: string;
//   downPayment: string;
//   installments: Installment[];
// }

// interface LocationFormData {
//   name: string;
//   country: string;
//   city: string;
//   region: string;
//   masterPlan: string;
//   images: string[];
//   latitude: string;
//   longitude: string;
//   unitTypes: string[];
//   paymentPlans: PaymentPlan[];
// }

const EditLocation = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data: location, isLoading, refetch } = useGetLocationQuery(id!);
  const [updateLocation] = useUpdateLocationMutation();

  const [formData, setFormData] = useState<Location>({
    name: "",
    country: "",
    city: "",
    region: "",
    masterPlan: "",
    images: [],
    latitude: 0,
    longitude: 0,
    unitTypes: [],
    paymentPlans: [],
  });

  const [masterPlanPreview, setMasterPlanPreview] = useState<string | null>(
    null
  );
  const [imagesPreviews, setImagesPreviews] = useState<string[]>([]);

  const [unitType, setUnitType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>({
    name: "",
    duration: "",
    downPayment: "",
    installments: [],
  });
  const [installment, setInstallment] = useState<Installment>({
    duration: "",
    amount: "",
  });

  useEffect(() => {
    if (location) {
      setFormData(location);
      // setMasterPlanPreview(location.masterPlan || "");
      // setImagesPreviews(location.images || []);

      // Handle type checking for masterPlan
      if (typeof location.masterPlan === "string") {
        setMasterPlanPreview(location.masterPlan);
      } else {
        setMasterPlanPreview(location.masterPlan?.url || "");
      }

      // Handle type checking for images
      const imageUrls = location.images.map((img) =>
        typeof img === "string" ? img : img.url
      );
      setImagesPreviews(imageUrls);
    }
  }, [location]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, masterPlan: reader.result as string });
      setMasterPlanPreview(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadMultiple = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length + formData.images.length > 3) {
      toast.error("Cannot upload more than 3 images", {
        position: "bottom-right",
      });
      return;
    }

    const newImages: string[] = [];
    const newImagePreviews: string[] = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        newImages.push(reader.result as string);
        newImagePreviews.push(reader.result as string);
        if (newImages.length === files.length) {
          setFormData((prevState) => ({
            ...prevState,
            images: [...prevState.images, ...newImages],
          }));
          setImagesPreviews((prevState) => [...prevState, ...newImagePreviews]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (index: number) => {
    const newImages = formData.images.filter((_, i) => i !== index);
    const newImagePreviews = imagesPreviews.filter((_, i) => i !== index);
    setFormData({ ...formData, images: newImages });
    setImagesPreviews(newImagePreviews);
  };

  const handleAddUnitType = () => {
    if (unitType && !formData.unitTypes.includes(unitType)) {
      setFormData((prevState) => ({
        ...prevState,
        unitTypes: [...prevState.unitTypes, unitType],
      }));
      setUnitType("");
    }
  };

  const handleRemoveUnitType = (type: string) => {
    setFormData({
      ...formData,
      unitTypes: formData.unitTypes.filter((t) => t !== type),
    });
  };

  const handleAddInstallment = () => {
    if (installment.duration && installment.amount) {
      setPaymentPlan({
        ...paymentPlan,
        installments: [...paymentPlan.installments, installment],
      });
      setInstallment({ duration: "", amount: "" });
    }
  };

  const handleRemoveInstallment = (index: number) => {
    setPaymentPlan({
      ...paymentPlan,
      installments: paymentPlan.installments.filter((_, i) => i !== index),
    });
  };

  const handleAddPaymentPlan = () => {
    if (paymentPlan.name && paymentPlan.duration && paymentPlan.downPayment) {
      setFormData({
        ...formData,
        paymentPlans: [...formData.paymentPlans, paymentPlan],
      });
      setShowModal(false);
      setPaymentPlan({
        name: "",
        duration: "",
        downPayment: "",
        installments: [],
      });
    }
  };

  const handleEditPaymentPlan = (index: number) => {
    setPaymentPlan(formData.paymentPlans[index]);
    setShowModal(true);
    handleRemovePaymentPlan(index);
  };

  const handleRemovePaymentPlan = (index: number) => {
    setFormData({
      ...formData,
      paymentPlans: formData.paymentPlans.filter((_, i) => i !== index),
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateLocation({
        id: id!,
        location: {
          ...formData,
          latitude: parseFloat(formData.latitude.toString()),
          longitude: parseFloat(formData.longitude.toString()),
        },
      }).unwrap();
      toast.success("Location updated successfully!", {
        position: "bottom-right",
      });
      navigate(`/dashboard/edit-location/${id}`);
    } catch (error) {
      console.error("Error updating location:", error);
      toast.error("Failed to update location. Please try again later.", {
        position: "bottom-right",
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box p={3}>
      <Typography variant="h4">Edit Location</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Location Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <Box display="flex" gap={2}>
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Region"
            name="region"
            value={formData.region}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Box>
        <Box display="flex" gap={2}>
          <TextField
            label="Latitude"
            name="latitude"
            type="number"
            value={formData.latitude}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Longitude"
            name="longitude"
            type="number"
            value={formData.longitude}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
        </Box>
        <Box mt={2} mb={2}>
          <Typography variant="h6">Master Plan</Typography>
          {/* <Button variant="contained" component="label">
            Upload Master Plan
            <input type="file" hidden onChange={handleImageUpload} />
          </Button> */}
          {/* {masterPlanPreview && (
            <img src={masterPlanPreview} alt="Master Plan" height="150px" />
          )} */}
          <ImagePreview>
            {masterPlanPreview ? (
              <img
                src={masterPlanPreview}
                alt="Master Plan Preview"
                style={{ width: "50%", maxHeight: "200px" }}
              />
            ) : (
              <Typography>No Master Plan Image Uploaded</Typography>
            )}
          </ImagePreview>
          <label htmlFor="masterPlan">
            <input
              type="file"
              id="masterPlan"
              name="masterPlan"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadIcon />}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Upload Master Plan Image
            </Button>
          </label>
        </Box>
        <Box sx={{ margin: "16px 0" }}>
          <ImagePreview>
            {imagesPreviews.length > 0 ? (
              imagesPreviews.map((preview, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    marginRight: 2,
                  }}
                >
                  <img
                    src={preview}
                    alt={`Preview ${index}`}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(index)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "red",
                      backgroundColor: "rgba(255,255,255,0.7)",
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography>No Images Uploaded</Typography>
            )}
          </ImagePreview>
          <label htmlFor="images">
            <input
              type="file"
              id="images"
              name="images"
              accept="image/*"
              multiple
              style={{ display: "none" }}
              onChange={handleImageUploadMultiple}
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadIcon />}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Upload Images
            </Button>
          </label>
        </Box>
        <Box mt={2} mb={2}>
          <Typography variant="h6">Unit Types</Typography>
          <Box display="flex" gap={2}>
            <TextField
              label="Unit Type"
              value={unitType}
              onChange={(e) => setUnitType(e.target.value)}
              fullWidth
              variant="outlined"
            />
            <Button
              color="secondary"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddUnitType}
            >
              Add
            </Button>
          </Box>
          <List>
            {formData.unitTypes.map((type, index) => (
              <ListItem key={index}>
                <ListItemText primary={type} />
                <ListItemSecondaryAction>
                  <IconButton
                    color="error"
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveUnitType(type)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box mt={2} mb={2}>
          <Typography variant="h6">Payment Plans</Typography>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setShowModal(true)}
          >
            Add Payment Plan
          </Button>
          <Box sx={{ marginTop: 2 }}>
            {formData.paymentPlans.map((plan, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: 2,
                  padding: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="subtitle1">
                  {plan.name} ({plan.duration}, {plan.downPayment})
                </Typography>
                <ul style={{ paddingLeft: "16px" }}>
                  {plan.installments.map((inst, i) => (
                    <li key={i}>
                      <Typography variant="body2">
                        {inst.duration}: {inst.amount}
                      </Typography>
                    </li>
                  ))}
                </ul>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => handleEditPaymentPlan(index)}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => handleRemovePaymentPlan(index)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Button fullWidth type="submit" variant="contained" color="primary">
          Update Location
        </Button>
      </form>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Add/Edit Payment Plan</Typography>
          <TextField
            label="Plan Name"
            name="name"
            value={paymentPlan.name}
            onChange={(e) =>
              setPaymentPlan({ ...paymentPlan, name: e.target.value })
            }
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Duration"
            name="duration"
            value={paymentPlan.duration}
            onChange={(e) =>
              setPaymentPlan({ ...paymentPlan, duration: e.target.value })
            }
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Down Payment"
            name="downPayment"
            type="number"
            value={paymentPlan.downPayment}
            onChange={(e) =>
              setPaymentPlan({ ...paymentPlan, downPayment: e.target.value })
            }
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Typography variant="h6">Installments</Typography>
          <Box display="flex" gap={2} mt={2}>
            <TextField
              label="Duration"
              name="installmentDuration"
              value={installment.duration}
              onChange={(e) =>
                setInstallment({ ...installment, duration: e.target.value })
              }
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Amount"
              name="installmentAmount"
              type="number"
              value={installment.amount}
              onChange={(e) =>
                setInstallment({ ...installment, amount: e.target.value })
              }
              fullWidth
              variant="outlined"
            />
            <Button variant="contained" onClick={handleAddInstallment}>
              Add
            </Button>
          </Box>
          <List>
            {paymentPlan.installments.map((inst, index) => (
              <PaymentPlanItem key={index}>
                <ListItemText
                  primary={`${inst.duration} months - ${inst.amount} amount`}
                />
                <IconButton
                  edge="end"
                  color="error"
                  aria-label="delete"
                  onClick={() => handleRemoveInstallment(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </PaymentPlanItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddPaymentPlan}
          >
            Save Payment Plan
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditLocation;
