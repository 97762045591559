// import React from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import { LeadTransaction } from "../interfaces/LeadTransaction";

// interface RecentTransactionsProps {
//   transactions: LeadTransaction[]; // Explicitly define transactions as LeadTransaction[]
// }

// const RecentTransactions: React.FC<RecentTransactionsProps> = ({
//   transactions,
// }) => {
//   // Helper function to determine the transaction type
//   const getTransactionType = (transaction: LeadTransaction) => {
//     if (transaction.assigned) return "Assigned";
//     if (transaction.status) return "Status";
//     if (transaction.call) return "Call";
//     return "Unknown";
//   };

//   return (
//     <Table size="small">
//       <TableHead>
//         <TableRow>
//           <TableCell>Date</TableCell>
//           <TableCell>Type</TableCell>
//           <TableCell>Status / Assignment</TableCell>
//           <TableCell align="right">Assigned Agent / Caller</TableCell>
//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {transactions?.map((transaction) => (
//           <TableRow key={transaction._id}>
//             <TableCell>
//               {new Date(transaction.createdAt || "").toLocaleDateString()}
//             </TableCell>
//             <TableCell>{getTransactionType(transaction)}</TableCell>
//             <TableCell>
//               {transaction.status
//                 ? // Type guard to check if from and to are of type LeadStatus
//                   typeof transaction.status.from !== "string" &&
//                   typeof transaction.status.to !== "string"
//                   ? `${transaction.status.from.status} → ${transaction.status.to.status}`
//                   : "N/A"
//                 : transaction.assigned
//                 ? // Similar type guard for assigned
//                   typeof transaction.assigned.from !== "string" &&
//                   typeof transaction.assigned.to !== "string"
//                   ? `${transaction.assigned.from.name} → ${transaction.assigned.to.name}`
//                   : "N/A"
//                 : transaction.call
//                 ? `Called ${transaction.call.phoneNumber}`
//                 : "N/A"}
//             </TableCell>
//             <TableCell align="right">
//               {transaction.assigned
//                 ? typeof transaction.assigned.to === "string"
//                   ? transaction.assigned.to
//                   : transaction.assigned.to.name // Access 'name' from User object
//                 : transaction.status
//                 ? typeof transaction.status.operatedBy === "string"
//                   ? transaction.status.operatedBy
//                   : transaction.status.operatedBy.name // Access 'name' from User object
//                 : transaction.call
//                 ? typeof transaction.call.callMadeBy === "string"
//                   ? transaction.call.callMadeBy
//                   : transaction.call.callMadeBy.name // Access 'name' from User object
//                 : "N/A"}
//             </TableCell>
//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//   );
// };

// export default RecentTransactions;

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Box,
} from "@mui/material";
import { LeadTransaction } from "../interfaces/LeadTransaction";
// Icon for Assigned
// Icon for Status
// Icon for Call
import { AssignmentTurnedIn, CallMade, Sync } from "@mui/icons-material";
import moment from "moment";

interface RecentTransactionsProps {
  transactions: LeadTransaction[]; // Explicitly define transactions as LeadTransaction[]
  page: number; // Current page for pagination
  limit: number; // Number of items per page
  totalTransactions: number; // Total number of transactions for pagination
  onPageChange: (event: unknown, newPage: number) => void; // Page change handler
}

const RecentTransactions: React.FC<RecentTransactionsProps> = ({
  transactions,
  page,
  limit,
  totalTransactions,
  onPageChange,
}) => {
  // Helper function to determine the transaction type
  // const getTransactionType = (transaction: LeadTransaction) => {
  //   if (transaction.assigned) return "Assigned";
  //   if (transaction.status) return "Status";
  //   if (transaction.call) return "Call";
  //   return "Unknown";
  // };

  // Helper function to get the corresponding icon for the transaction type
  const getTransactionIcon = (transaction: LeadTransaction) => {
    if (transaction.assigned) return <AssignmentTurnedIn color="primary" />;
    if (transaction.status) return <Sync color="success" />;
    if (transaction.call) return <CallMade color="info" />;
    return null;
  };
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Status / Assignment</TableCell>
            <TableCell align="right">Assigned Agent / Caller</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions?.map((transaction) => (
            <TableRow key={transaction._id}>
              <TableCell>
                <IconButton>{getTransactionIcon(transaction)}</IconButton>
              </TableCell>
              <TableCell>
                {transaction.status ? (
                  // Type guard to check if from and to are of type LeadStatus
                  typeof transaction.status.from !== "string" &&
                  typeof transaction.status.to !== "string" ? (
                    <Box>
                      <span style={{ color: transaction.status.from.color }}>
                        {transaction.status.from.status}
                      </span>{" "}
                      →{" "}
                      <span style={{ color: transaction.status.to.color }}>
                        {transaction.status.to.status}
                      </span>
                    </Box>
                  ) : (
                    "N/A"
                  )
                ) : transaction.assigned ? (
                  // Similar type guard for assigned
                  typeof transaction.assigned.from !== "string" &&
                  typeof transaction.assigned.to !== "string" ? (
                    <Box>
                      <span style={{ color: transaction.assigned.from.color }}>
                        {transaction.assigned.from.name}
                      </span>{" "}
                      →{" "}
                      <span style={{ color: transaction.assigned.to.color }}>
                        {transaction.assigned.to.name}
                      </span>
                    </Box>
                  ) : (
                    "N/A"
                  )
                ) : transaction.call ? (
                  `Called ${transaction.call.phoneNumber}`
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell align="right">
                {transaction.assigned
                  ? typeof transaction.assigned.to === "string"
                    ? transaction.assigned.to
                    : transaction.assigned.to.name // Access 'name' from User object
                  : transaction.status
                  ? typeof transaction.status.operatedBy === "string"
                    ? transaction.status.operatedBy
                    : transaction.status.operatedBy.name // Access 'name' from User object
                  : transaction.call
                  ? typeof transaction.call.callMadeBy === "string"
                    ? transaction.call.callMadeBy
                    : transaction.call.callMadeBy.name // Access 'name' from User object
                  : "N/A"}
              </TableCell>
              <TableCell align="right">
                {/* {new Date(transaction.createdAt || "").toLocaleDateString()} */}

                {moment(transaction.createdAt).format("MMMM D, YYYY h:mm A")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalTransactions}
        rowsPerPage={limit}
        page={page}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default RecentTransactions;
