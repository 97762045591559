import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "../../../features/customer/customerSlice";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useGetCountriesQuery } from "../../../features/country/countrySlice";
import { useGetContactMethodsQuery } from "../../../features/contact_method/contactMethodSlice";
import { PhoneNumber } from "../../../interfaces/PhoneNumber";

const EditCustomer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [primaryPhone, setPrimaryPhone] = useState<PhoneNumber>({
    country: "",
    phoneNumber: 0,
  });
  const [secondaryPhone, setSecondaryPhone] = useState<PhoneNumber>({
    country: "",
    phoneNumber: 0,
  });
  const [landline, setLandline] = useState<string>("");
  const [contactMethod, setContactMethod] = useState<string>("");
  const [preferredCallTime, setPreferredCallTime] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [primaryPhoneError, setPrimaryPhoneError] = useState<string>("");
  const [secondaryPhoneError, setSecondaryPhoneError] = useState<string>("");

  const {
    data: customer,
    isFetching: isFetchingCustomer,
    isSuccess,
    refetch,
  } = useGetCustomerQuery(id!);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const [updateCustomer] = useUpdateCustomerMutation();

  const { data: contactMethods, refetch: refetchContactMethods } =
    useGetContactMethodsQuery({
      page: 1,
      limit: 0,
    });
  const { data: countries, refetch: refetchCountries } = useGetCountriesQuery();

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    refetchContactMethods();
    refetchCountries();
  }, []);

  useEffect(() => {
    if (customer) {
      setName(customer.name);
      setEmail(customer.email);

      setPrimaryPhone({
        country: (() => {
          if (
            customer.primaryPhone &&
            typeof customer.primaryPhone.country === "object"
          ) {
            return customer.primaryPhone.country._id || ""; // Use _id if country is an object
          }
          if (typeof customer.primaryPhone?.country === "string") {
            return customer.primaryPhone.country; // Return string if it is valid
          }
          return ""; // Default fallback to an empty string
        })(),
        phoneNumber: customer.primaryPhone?.phoneNumber || 0, // Fallback to 0 if phoneNumber is undefined
      });

      setSecondaryPhone({
        country: (() => {
          if (
            customer.secondaryPhone &&
            typeof customer.secondaryPhone.country === "object"
          ) {
            return customer.secondaryPhone.country._id || ""; // Use _id if country is an object
          }
          if (typeof customer.secondaryPhone?.country === "string") {
            return customer.secondaryPhone.country; // Return string if it is valid
          }
          return ""; // Default fallback to an empty string
        })(),
        phoneNumber: customer.secondaryPhone?.phoneNumber || 0, // Fallback to 0 if phoneNumber is undefined
      });
      setLandline(customer.landline || "");
      setContactMethod(
        typeof customer.contactMethod === "string"
          ? customer.contactMethod
          : customer.contactMethod?._id || "" // Assuming ContactMethod object has an _id field representing the string value
      );
      setPreferredCallTime(customer.preferredCallTime || "");
    }
  }, [customer]);

  const handleCountryChange = (
    type: "primary" | "secondary",
    value: string
  ) => {
    if (type === "primary") {
      setPrimaryPhone({ ...primaryPhone, country: value });
    } else {
      setSecondaryPhone({ ...secondaryPhone, country: value });
    }
  };

  const handlePhoneNumberChange = (
    type: "primary" | "secondary",
    value: string
  ) => {
    if (type === "primary") {
      setPrimaryPhone({ ...primaryPhone, phoneNumber: parseInt(value) });
    } else {
      setSecondaryPhone({ ...secondaryPhone, phoneNumber: parseInt(value) });
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validate primary phone number length
  useEffect(() => {
    const selectedCountry = countries?.find(
      (country) => country._id === primaryPhone.country
    );

    if (selectedCountry) {
      if (
        primaryPhone.phoneNumber.toString().length !== selectedCountry.length
      ) {
        setPrimaryPhoneError(
          `Phone number must be exactly ${selectedCountry.length} digits`
        );
      } else {
        setPrimaryPhoneError(""); // Clear the error if length is valid
      }
    }
  }, [primaryPhone.phoneNumber, primaryPhone.country, countries]);

  // Validate secondary phone number length
  useEffect(() => {
    const selectedCountry = countries?.find(
      (country) => country._id === secondaryPhone.country
    );

    if (selectedCountry) {
      if (
        secondaryPhone.phoneNumber.toString().length !== selectedCountry.length
      ) {
        setSecondaryPhoneError(
          `Phone number must be exactly ${selectedCountry.length} digits`
        );
      } else {
        setSecondaryPhoneError(""); // Clear the error if length is valid
      }
    }
  }, [secondaryPhone.phoneNumber, secondaryPhone.country, countries]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
    try {
      if (
        name.trim() === "" ||
        email.trim() === "" ||
        !validateEmail(email) ||
        !primaryPhone.country ||
        !primaryPhone.phoneNumber
      ) {
        return;
      }
      await updateCustomer({
        id: id!,
        customer: {
          name,
          email,
          primaryPhone,
          secondaryPhone,
          landline,
          contactMethod,
          preferredCallTime,
        },
      }).unwrap();

      toast.success("Customer updated successfully!");
      navigate("/dashboard/customer");
    } catch (err: any) {
      if ("status" in err && "data" in err) {
        const error = err as { status: number; data: { message: string } };
        toast.error(error.data.message || "Failed to update Customer");
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchingCustomer) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="sm">
      <Box component="div" sx={{ mt: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Customer
        </Typography>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Customer Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={isSubmitted && name.trim() === ""}
                helperText={
                  isSubmitted && name.trim() === ""
                    ? "Customer Name is required"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                error={
                  isSubmitted && (email.trim() === "" || !validateEmail(email))
                }
                helperText={
                  isSubmitted && email.trim() === ""
                    ? "Email is required"
                    : isSubmitted && !validateEmail(email)
                    ? "Invalid email format"
                    : ""
                }
              />
            </Grid>

            {/* Primary Phone */}
            {/* <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    error={isSubmitted && !primaryPhone.country}
                  >
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={primaryPhone.country}
                      onChange={(e) =>
                        handleCountryChange("primary", e.target.value as string)
                      }
                    >
                      {countries &&
                        countries.map((country) => (
                          <MenuItem key={country._id} value={country._id}>
                            {country.symbol} ({country.code})
                          </MenuItem>
                        ))}
                    </Select>
                    {isSubmitted && !primaryPhone.country && (
                      <FormHelperText>Country is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Primary Phone"
                    value={primaryPhone.phoneNumber}
                    onChange={(e) =>
                      handlePhoneNumberChange("primary", e.target.value)
                    }
                    error={isSubmitted && !primaryPhone.phoneNumber}
                    helperText={
                      isSubmitted && !primaryPhone.phoneNumber
                        ? "Primary Phone is required"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    error={isSubmitted && !primaryPhone.country}
                  >
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={primaryPhone.country}
                      onChange={(e) =>
                        handleCountryChange("primary", e.target.value as string)
                      }
                    >
                      {countries &&
                        countries.map((country) => (
                          <MenuItem key={country._id} value={country._id}>
                            {country.symbol} ({country.code})
                          </MenuItem>
                        ))}
                    </Select>
                    {isSubmitted && !primaryPhone.country && (
                      <FormHelperText>Country is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    label="Primary Phone"
                    value={primaryPhone.phoneNumber}
                    onChange={(e) =>
                      handlePhoneNumberChange("primary", e.target.value)
                    }
                    error={
                      !!primaryPhoneError ||
                      (isSubmitted && !primaryPhone.phoneNumber)
                    }
                    helperText={
                      primaryPhoneError ||
                      (isSubmitted && !primaryPhone.phoneNumber
                        ? "Primary Phone is required"
                        : "")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Secondary Phone */}
            {/* <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={secondaryPhone.country}
                      onChange={(e) =>
                        handleCountryChange(
                          "secondary",
                          e.target.value as string
                        )
                      }
                    >
                      {countries &&
                        countries.map((country) => (
                          <MenuItem key={country._id} value={country._id}>
                            {country.symbol} ({country.code})
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Secondary Phone"
                    value={secondaryPhone.phoneNumber}
                    onChange={(e) =>
                      handlePhoneNumberChange("secondary", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    error={isSubmitted && !secondaryPhone.country}
                  >
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={secondaryPhone.country}
                      onChange={(e) =>
                        handleCountryChange(
                          "secondary",
                          e.target.value as string
                        )
                      }
                    >
                      {countries &&
                        countries.map((country) => (
                          <MenuItem key={country._id} value={country._id}>
                            {country.symbol} ({country.code})
                          </MenuItem>
                        ))}
                    </Select>
                    {isSubmitted && !secondaryPhone.country && (
                      <FormHelperText>Country is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    label="Secondary Phone"
                    value={secondaryPhone.phoneNumber}
                    onChange={(e) =>
                      handlePhoneNumberChange("secondary", e.target.value)
                    }
                    error={
                      !!secondaryPhoneError ||
                      (isSubmitted && !secondaryPhone.phoneNumber)
                    }
                    helperText={
                      secondaryPhoneError ||
                      (isSubmitted && !secondaryPhone.phoneNumber
                        ? "Secondary Phone is required"
                        : "")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Landline"
                value={landline}
                onChange={(e) => setLandline(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Contact Method</InputLabel>
                <Select
                  value={contactMethod}
                  onChange={(e) => setContactMethod(e.target.value as string)}
                >
                  <MenuItem value="">Select Contact Method</MenuItem>
                  {contactMethods &&
                    contactMethods.data.map((method) => (
                      <MenuItem key={method._id} value={method._id}>
                        {method.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Preferred Call Time"
                value={preferredCallTime}
                onChange={(e) => setPreferredCallTime(e.target.value)}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update Customer"}
            </Button>
            {/* <Button
              onClick={() => navigate("/dashboard/customers")}
              sx={{ ml: 2 }}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button> */}
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default EditCustomer;
