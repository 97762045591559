// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { useCreateLeadSubTypeMutation } from '../../../../features/lead_sub_type/leadSubTypeSlice';
// import { useGetLeadTypesQuery } from '../../../../features/lead_type/leadTypeSlice';

// const AddLeadSubType = () => {
//     const [name, setName] = useState('');
//     // const [description, setDescription] = useState('');
//     const [leadType, setLeadType] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);
//     const navigate = useNavigate();
//     const [createLeadSubType] = useCreateLeadSubTypeMutation();
//     const { data: leadTypeData } = useGetLeadTypesQuery();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await createLeadSubType({ name, leadType }).unwrap();
//             setName('');
//             // setDescription('');
//             navigate('/dashboard/lead-sub-type');
//             toast.success('Lead SubType added successfully');
//         } catch (err) {
//             toast.error('Failed to add Lead SubType');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Add New Lead SubType</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Lead SubType Name"
//                                     />
//                                     <label htmlFor="name">Lead SubType Name</label>
//                                 </div>

//                                 {/* <div className="form-floating mb-2">
//                                     <textarea
//                                         className="form-control"
//                                         name='description'
//                                         value={description}
//                                         onChange={(e) => setDescription(e.target.value)}
//                                         placeholder="Lead SubType Description"
//                                     />
//                                     <label htmlFor="description">Lead SubType Description</label>
//                                 </div> */}

//                                 <div className="form-floating mb-2">
//                                     <select
//                                         className="form-select"
//                                         value={leadType}
//                                         onChange={(e) => setLeadType(e.target.value)}
//                                     >
//                                         <option value="">Select Lead Type</option>
//                                         {leadTypeData?.map(leadType => (
//                                             <option key={leadType._id} value={leadType._id}>{leadType.name}</option>
//                                         ))}
//                                     </select>
//                                     <label htmlFor="leadType">Lead Type</label>
//                                 </div>
//                                 <button className="btn btn-primary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Adding Lead SubType...' : 'Add Lead SubType'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/lead-subtype' className="text-dark fw-bold">Lead SubType List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddLeadSubType;

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useCreateLeadSubTypeMutation } from "../../../features/lead_sub_type/leadSubTypeSlice";
import { useGetLeadTypesQuery } from "../../../features/lead_type/leadTypeSlice";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const AddLeadSubType: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [leadType, setLeadType] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createLeadSubType] = useCreateLeadSubTypeMutation();
  // Fetch all ad groups without pagination
  const { data: leadTypeData } = useGetLeadTypesQuery({
    page: 1,
    limit: 0,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createLeadSubType({ name, leadType }).unwrap();
      setName("");
      setLeadType("");
      navigate("/dashboard/lead-sub-type");
      toast.success("Lead SubType added successfully");
    } catch (err) {
      toast.error("Failed to add Lead SubType");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Add New Lead SubType
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Lead SubType Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="lead-type-select-label">Lead Type</InputLabel>
          <Select
            labelId="lead-type-select-label"
            value={leadType}
            onChange={(e) => setLeadType(e.target.value as string)}
            required
          >
            <MenuItem value="">
              <em>Select Lead Type</em>
            </MenuItem>
            {leadTypeData?.data?.map((leadType) => (
              <MenuItem key={leadType._id} value={leadType._id}>
                {leadType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          startIcon={isFormLoading && <CircularProgress size={20} />}
        >
          {isFormLoading ? "Adding Lead SubType..." : "Add Lead SubType"}
        </Button>

        <Typography
          align="center"
          variant="body2"
          color="textSecondary"
          className="mt-3"
        >
          <NavLink to="/dashboard/lead-sub-type">
            Back to Lead SubType List
          </NavLink>
        </Typography>
      </form>
    </Container>
  );
};

export default AddLeadSubType;
