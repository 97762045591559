// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import ConfirmationDialog from '../common/ConfirmationDialog';
// import { useDeleteLeadTypeMutation, useGetLeadTypesQuery } from '../../../../features/lead_type/leadTypeSlice';

// const LeadTypeList = () => {
//     const { data, error, isLoading, refetch } = useGetLeadTypesQuery();
//     const [deleteLeadType] = useDeleteLeadTypeMutation();
//     const navigate = useNavigate();
//     const [showDialog, setShowDialog] = useState(false);
//     const [selectedLeadTypeId, setSelectedLeadTypeId] = useState(null);

//     const addLeadType = () => {
//         navigate('/dashboard/add-lead-type');
//     };

//     const handleDelete = async (e) => {
//         e.preventDefault();
//         if (selectedLeadTypeId) {
//             await deleteLeadType(selectedLeadTypeId);
//             setShowDialog(false);
//             refetch();
//         }
//     };

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     if (isLoading) return <div>Loading...</div>;
//     if (error) return <div>Error loading lead types</div>;

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Lead Type Table</h4>
//                             </div>
//                             <button id="table2-new-row-button" className="btn btn-primary mb-4 mr-2" onClick={addLeadType}>
//                                 Add New Lead Type
//                             </button>
//                         </div>
//                         <div className="card-body">
//                             <div className="table-responsive border-top userlist-table">
//                                 <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
//                                     <thead>
//                                         <tr>
//                                             <th>Lead Type Name</th>
//                                             <th>Actions</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {data?.map((leadType) => (
//                                             <tr key={leadType._id}>
//                                                 <td>{leadType.name}</td>
//                                                 <td>
//                                                     <Link to={`/dashboard/edit-lead-type/${leadType._id}`}>
//                                                         <i className="bi bi-pencil-square m-1 text-primary"></i>
//                                                     </Link>
//                                                     <a onClick={() => { setSelectedLeadTypeId(leadType._id); setShowDialog(true); }}>
//                                                         <i className="bi bi-trash3-fill m-1 text-danger"></i>
//                                                     </a>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {showDialog && (
//                 <ConfirmationDialog
//                     title="Confirm Deletion"
//                     message="Are you sure you want to delete this Lead Type?"
//                     onConfirm={handleDelete}
//                     onCancel={() => setShowDialog(false)}
//                 />
//             )}
//         </div>
//     );
// };

// export default LeadTypeList;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  useDeleteLeadTypeMutation,
  useGetLeadTypesQuery,
} from "../../../features/lead_type/leadTypeSlice";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { LeadType } from "../../../interfaces/LeadType";
import moment from "moment";

const LeadTypeList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data, error, isLoading, refetch } = useGetLeadTypesQuery({
    page,
    limit,
  } as any);
  const [deleteLeadType] = useDeleteLeadTypeMutation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedLeadTypeId, setSelectedLeadTypeId] = useState<string | null>(
    null
  );

  const addLeadType = () => {
    navigate("/dashboard/add-lead-type");
  };

  const handleDelete = async () => {
    if (selectedLeadTypeId) {
      await deleteLeadType(selectedLeadTypeId);
      setShowDialog(false);
      refetch();
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const totalPages = Math.ceil((data?.total || 0) / limit);

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography>Error loading lead types</Typography>;

  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>
        Lead Type Table
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        onClick={addLeadType}
        sx={{ marginBottom: 2 }}
      >
        Add New Lead Type
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lead Type Name</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Modified Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((leadType: LeadType) => (
              <TableRow key={leadType._id}>
                <TableCell>{leadType.name}</TableCell>
                <TableCell>
                  {moment(leadType.createdAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell>
                  {moment(leadType.updatedAt).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                {leadType.company && (
                  <TableCell>
                    <IconButton
                      color="primary"
                      component={Link}
                      to={`/dashboard/edit-lead-type/${leadType._id}`}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setSelectedLeadTypeId(leadType._id ?? null);
                        setShowDialog(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Box>
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this Lead Type?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Box>
  );
};

export default LeadTypeList;
