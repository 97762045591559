// import React, { useState, useEffect } from 'react';
// import { NavLink, useNavigate, useParams } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { useGetContactMethodQuery, useUpdateContactMethodMutation } from '../../../features/contact_method/contactMethodSlice';

// const EditContactMethod = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [name, setName] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);
//     const { data: contactMethodData, refetch } = useGetContactMethodQuery(id);
//     const [updateContactMethod] = useUpdateContactMethodMutation();

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     useEffect(() => {
//         if (contactMethodData) {
//             setName(contactMethodData?.name || '');
//         }
//     }, [contactMethodData]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await updateContactMethod({ id, contactMethod: { name } }).unwrap();
//             toast.success('ContactMethod updated successfully');
//             navigate('/dashboard/contact-method');
//         } catch (err) {
//             toast.error('Failed to update ContactMethod');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Edit ContactMethod</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="ContactMethod Name"
//                                     />
//                                     <label htmlFor="name">ContactMethod Name</label>
//                                 </div>

//                                 <button className="btn btn-primary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Updating ContactMethod...' : 'Update ContactMethod'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/contactMethod' className="text-dark fw-bold">ContactMethod List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditContactMethod;

import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Button, TextField, Box, CircularProgress } from "@mui/material";
import {
  useGetContactMethodQuery,
  useUpdateContactMethodMutation,
} from "../../../features/contact_method/contactMethodSlice";

const EditContactMethod: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const { data: contactMethodData, refetch } = useGetContactMethodQuery(id!);
  const [updateContactMethod] = useUpdateContactMethodMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (contactMethodData) {
      setName(contactMethodData?.name || "");
    }
  }, [contactMethodData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await updateContactMethod({ id: id!, contactMethod: { name } }).unwrap();
      toast.success("ContactMethod updated successfully");
      navigate("/dashboard/contact-method");
    } catch (err) {
      toast.error("Failed to update ContactMethod");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box p={2}>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Contact Method Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          required
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          startIcon={isFormLoading && <CircularProgress size={20} />}
        >
          {isFormLoading
            ? "Updating Contact Method..."
            : "Update Contact Method"}
        </Button>
      </form>
      <Box textAlign="center" mt={2}>
        <NavLink to="/dashboard/contact-method">
          Back to Contact Method List
        </NavLink>
      </Box>
    </Box>
  );
};

export default EditContactMethod;
