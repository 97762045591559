import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../features/auth/authApiSlice";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setCredentials } from "../../features/auth/authSlice";
import { SubscriptionStatus } from "../../interfaces/Subscription";
import { useDispatch } from "react-redux";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.orcida.com/">
        Orcida
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

interface ErrorResponse {
  data?: {
    statusCode?: number;
    status?: number;
    message?: any;
    errors?: { [key: string]: any };
  };
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Register() {
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>(
    {}
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [register, { isLoading }] = useRegisterMutation();

  const generateError = (message: string) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password") as string;
    const confirmPassword = data.get("confirmPassword") as string;

    setFormErrors({});
    // Check if passwords match
    if (password !== confirmPassword) {
      setFormErrors({ confirmPassword: "Passwords do not match" });
      return;
    }

    try {
      const userData = await register({
        name: data.get("name") as string,
        email: data.get("email") as string,
        password: data.get("password") as string,
        color: data.get("color") as string,
      }).unwrap();

      // Set credentials in Redux state
      dispatch(setCredentials({ ...userData }));

      // Navigate to login page
      const companyId = userData.user.company?._id;
      const subscriptionId = userData.user.company?.subscription?._id;
      const status = userData.user.company?.subscription?.status;

      // Check if the user is verified, if not navigate to verification page
      if (!userData.user.isEmailVerified) {
        navigate("/verify-email", { state: { email: userData.user.email } });
        return; // Prevent further navigation logic
      }

      if (
        !companyId ||
        !subscriptionId ||
        status === SubscriptionStatus.READY_TO_PAY
      ) {
        navigate("/company-setup");
      } else {
        navigate("/dashboard");
      }

      // Clear form fields
      event.currentTarget.reset();
      setFormErrors({});
    } catch (err) {
      const error = err as ErrorResponse;
      console.log(error);

      if (error?.data?.statusCode === 400 || error?.data?.status === 400) {
        // if (Array.isArray(error?.data?.message.message)) {
        //   const newErrors: { [key: string]: string } = {};
        //   error.data.message.message.forEach((errMsg: any) => {
        //     newErrors[errMsg.field] = errMsg.errors[0]; // Get first error message
        //   });
        //   setFormErrors(newErrors);
        // }
        if (Array.isArray(error?.data?.errors)) {
          const newErrors: { [key: string]: string } = {};
          error.data.errors.forEach((errMsg: any) => {
            console.log("errMsg", errMsg);

            newErrors[errMsg.field] = errMsg.errors[0]; // Get the first error message
          });
          setFormErrors(newErrors);
          console.log("formErrors", formErrors);
        }
      } else if (
        error?.data?.statusCode === 409 ||
        error?.data?.status === 409
      ) {
        generateError(error?.data?.message);
      }
    }
  };

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   const password = data.get("password") as string;
  //   const confirmPassword = data.get("confirmPassword") as string;

  //   // Check if passwords match
  //   if (password !== confirmPassword) {
  //     generateError("Passwords do not match");
  //     return;
  //   }
  //   // console.log({
  //   //   name: data.get("name"),
  //   //   email: data.get("email"),
  //   //   password: data.get("password"),
  //   //   confirmPassword: data.get("confirmPassword"),
  //   // });
  //   try {
  //     const userData = await register({
  //       name: data.get("name") as string,
  //       email: data.get("email") as string,
  //       password: data.get("password") as string,
  //       color: data.get("color") as string,
  //     }).unwrap();
  //     // dispatch(setCredentials({ ...userData }));
  //     console.log("User data after registration:", userData);
  //     // Navigate to login before resetting the form
  //     navigate("/login");

  //     // Clear form fields
  //     event.currentTarget.reset();
  //   } catch (err) {
  //     const error = err as ErrorResponse;
  //     if (!error?.data?.statusCode) {
  //       generateError("No Server Response");
  //     } else if (error?.data?.statusCode === 400) {
  //       if (Array.isArray(error?.data?.message)) {
  //         error.data.message.forEach((msg: string) => {
  //           generateError(msg);
  //         });
  //       } else {
  //         generateError("Invalid error format");
  //       }
  //     } else if (error?.data?.statusCode === 401) {
  //       generateError("Unauthorized");
  //     } else {
  //       generateError(error?.data?.message || "Registration Failed");
  //     }
  //   }
  // };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <ToastContainer />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}

          {/* Logo at the top */}
          <Grid item>
            <Box mb={3}>
              <img
                src="/assets/landing/appbar_logo.png" // Replace with your logo path
                alt="Company Logo"
                style={{ width: 150, display: "block", margin: "0 auto" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={4}>
            <Box
              bgcolor="white"
              p={4}
              boxShadow={3}
              borderRadius={4}
              textAlign="center"
            >
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      autoFocus
                      error={!!formErrors.name}
                      helperText={formErrors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"} // Toggle between text and password
                      id="password"
                      autoComplete="new-password"
                      error={!!formErrors.password}
                      helperText={formErrors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmPassword"
                      error={!!formErrors.confirmPassword}
                      helperText={formErrors.confirmPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="color"
                      name="color"
                      id="color"
                      label="Choose Color"
                      variant="outlined"
                      margin="normal"
                      defaultValue="#84B9E1"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox value="allowExtraEmails" color="primary" />
                      }
                      label="I want to receive inspiration, marketing promotions and updates via email."
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Loading..." : "Sign Up"}{" "}
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
