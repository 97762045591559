// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { useCreateLeadTypeMutation } from '../../../../features/lead_type/leadTypeSlice';

// const AddLeadType = () => {
//     const [name, setName] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);
//     const navigate = useNavigate();
//     const [createLeadType] = useCreateLeadTypeMutation();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await createLeadType({ name }).unwrap();
//             setName('');
//             navigate('/dashboard/lead-type');
//         } catch (err) {
//             toast.error('Failed to create Lead Type');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Add New Lead Type</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Lead Type Name"
//                                     />
//                                     <label htmlFor="name">Lead Type Name</label>
//                                 </div>

//                                 <button className="btn btn-secondary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Adding Lead Type...' : 'Add Lead Type'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/lead-type' className="text-dark fw-bold">Lead Type List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddLeadType;

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useCreateLeadTypeMutation } from "../../../features/lead_type/leadTypeSlice";

const AddLeadType: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createLeadType] = useCreateLeadTypeMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createLeadType({ name }).unwrap();
      setName("");
      navigate("/dashboard/lead-type");
    } catch (err) {
      toast.error("Failed to create Lead Type");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>
        Add New Lead Type
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Lead Type Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
        >
          {isFormLoading ? "Adding Lead Type..." : "Add Lead Type"}
        </Button>
        <Box textAlign="center" mt={3}>
          <Typography variant="body2">
            <NavLink to="/dashboard/lead-type">Back to Lead Type List</NavLink>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default AddLeadType;
