import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { PhoneNumber } from "../../../interfaces/PhoneNumber";
import { useCreateCustomerMutation } from "../../../features/customer/customerSlice";
import { Customer } from "../../../interfaces/Customer";
import { useGetContactMethodsQuery } from "../../../features/contact_method/contactMethodSlice";
import { useGetCountriesQuery } from "../../../features/country/countrySlice";
import { toast, ToastContainer } from "react-toastify";

const AddCustomerModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onAddCustomer: (customer: Customer) => void;
  refetchCustomers: () => void;
}> = ({ open, onClose, onAddCustomer, refetchCustomers }) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [primaryPhone, setPrimaryPhone] = useState<PhoneNumber>({
    country: "",
    phoneNumber: 0,
  });
  const [secondaryPhone, setSecondaryPhone] = useState<PhoneNumber>({
    country: "",
    phoneNumber: 0,
  });
  const [landline, setLandline] = useState<string>("");
  const [contactMethod, setContactMethod] = useState<string>("");
  const [preferredCallTime, setPreferredCallTime] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createCustomer] = useCreateCustomerMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data: contactMethods, refetch: refetchContactMethods } =
    useGetContactMethodsQuery({
      page: 1,
      limit: 0,
    });
  const { data: countries, refetch: refetchCountries } = useGetCountriesQuery();

  useEffect(() => {
    refetchContactMethods();
    refetchCountries();
  }, []);

  useEffect(() => {
    if (countries && countries.length > 0) {
      const defaultCountryId = countries[0]._id || "";
      setPrimaryPhone((prev) => ({
        ...prev,
        country: defaultCountryId as string,
      }));
      setSecondaryPhone((prev) => ({
        ...prev,
        country: defaultCountryId as string,
      }));
    }
  }, [countries]);

  const handleCountryChange = (
    type: "primary" | "secondary",
    value: string
  ) => {
    if (type === "primary") {
      setPrimaryPhone({ ...primaryPhone, country: value });
    } else {
      setSecondaryPhone({ ...secondaryPhone, country: value });
    }
  };

  const handlePhoneNumberChange = (
    type: "primary" | "secondary",
    value: string
  ) => {
    if (type === "primary") {
      setPrimaryPhone({ ...primaryPhone, phoneNumber: parseInt(value) });
    } else {
      setSecondaryPhone({ ...secondaryPhone, phoneNumber: parseInt(value) });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
    try {
      if (
        name.trim() === "" ||
        email.trim() === "" ||
        !validateEmail(email) ||
        !primaryPhone.country ||
        !primaryPhone.phoneNumber // This checks if phoneNumber is a falsy value (e.g., 0, null, or undefined)
      ) {
        return; // Prevent submission if any required fields are missing or invalid
      }
      const newCustomer = await createCustomer({
        name,
        email,
        primaryPhone,
        secondaryPhone,
        landline,
        contactMethod,
        preferredCallTime,
        leads: [],
      }).unwrap();
      setIsSubmitted(false);
      onAddCustomer(newCustomer);
      setName("");
      setEmail("");
      setPrimaryPhone({ country: "", phoneNumber: 0 });
      setSecondaryPhone({ country: "", phoneNumber: 0 });
      setLandline("");
      setContactMethod("");
      setPreferredCallTime("");
      onClose();
      refetchCustomers(); // This will trigger refetching of customer list
    } catch (err: any) {
      // Check if the error has 'status' or 'statusCode'
      if ("status" in err && "data" in err) {
        // Format: { status: number; data: { message: string } }
        const error = err as { status: number; data: { message: string } };
        toast.error(error.data.message || "Failed to add Customer");
      } else if ("response" in err) {
        // Format: { statusCode: number; message: string; ... }
        const error = err as {
          response: { statusCode: number; message: string };
        };

        if (error.response.statusCode === 409) {
          toast.error(
            error.response.message || "Conflict: Resource already exists"
          );
        } else if (error.response.statusCode === 500) {
          toast.error(error.response.message || "Internal Server Error");
        } else {
          toast.error("Failed to add Customer");
        }
      } else {
        // Fallback for any other unexpected error format
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Email validation function
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // Makes the modal width 80% of the container's width
    maxWidth: 700, // Adds a max width so it doesn't exceed a specific size on larger screens
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    "@media (max-width: 600px)": {
      width: "90%", // On small screens, make the modal width 95% of the container
    },
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <ToastContainer />
        <h2>Add New Customer</h2>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Customer Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={isSubmitted && name.trim() === ""} // Show error if submitted and name is empty
                helperText={
                  isSubmitted && name.trim() === ""
                    ? "Customer Name is required"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                error={
                  isSubmitted && (email.trim() === "" || !validateEmail(email))
                }
                helperText={
                  isSubmitted && email.trim() === ""
                    ? "Email is required"
                    : isSubmitted && !validateEmail(email)
                    ? "Invalid email format"
                    : ""
                }
              />
            </Grid>

            {/* Primary Phone */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    error={isSubmitted && !primaryPhone.country}
                  >
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={primaryPhone.country}
                      onChange={(e) =>
                        handleCountryChange("primary", e.target.value as string)
                      }
                    >
                      {countries &&
                        countries.map((country) => (
                          <MenuItem key={country._id} value={country._id}>
                            {country.symbol} ({country.code})
                          </MenuItem>
                        ))}
                    </Select>
                    {isSubmitted && !primaryPhone.country && (
                      <FormHelperText>Country is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Primary Phone"
                    value={primaryPhone.phoneNumber}
                    onChange={(e) =>
                      handlePhoneNumberChange("primary", e.target.value)
                    }
                    error={isSubmitted && !primaryPhone.phoneNumber}
                    helperText={
                      isSubmitted && !primaryPhone.phoneNumber
                        ? "Primary Phone is required"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Secondary Phone */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={secondaryPhone.country}
                      onChange={(e) =>
                        handleCountryChange(
                          "secondary",
                          e.target.value as string
                        )
                      }
                    >
                      {countries &&
                        countries.map((country) => (
                          <MenuItem key={country._id} value={country._id}>
                            {country.symbol} ({country.code})
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Secondary Phone"
                    value={secondaryPhone.phoneNumber}
                    onChange={(e) =>
                      handlePhoneNumberChange("secondary", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Landline"
                value={landline}
                onChange={(e) => setLandline(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Contact Method</InputLabel>
                <Select
                  value={contactMethod}
                  onChange={(e) => setContactMethod(e.target.value as string)}
                >
                  <MenuItem value="">Select Contact Method</MenuItem>
                  {contactMethods &&
                    contactMethods.data.map((method) => (
                      <MenuItem key={method._id} value={method._id}>
                        {method.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Preferred Call Time"
                value={preferredCallTime}
                onChange={(e) => setPreferredCallTime(e.target.value)}
                type="time"
              />
            </Grid>
          </Grid>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Customer"}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddCustomerModal;
