// import { useLocation, Navigate, Outlet } from "react-router-dom"
// import { useSelector } from "react-redux"
// import { selectCurrentToken } from "./authSlice"

// const RequireAuth = () => {
//     const token = useSelector(selectCurrentToken)
//     const location = useLocation()

//     return (
//         token
//             ? <Outlet />
//             : <Navigate to="/login" state={{ from: location }} replace />
//     )
// }
// export default RequireAuth

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { selectCurrentToken } from "./adminSlice";

// Define the types for the component
const AdminRequireAuth: React.FC = () => {
  const token = useSelector((state: RootState) => selectCurrentToken(state));
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/admin-login" state={{ from: location }} replace />
  );
};

export default AdminRequireAuth;
