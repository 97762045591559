import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetPricingPlanQuery,
  useUpdatePricingPlanMutation,
} from "../../../features/pricing_plan/pricingPlanSlice"; // Adjust the import path accordingly
// Adjust the import path accordingly
import { toast } from "react-toastify";
import { Duration } from "../../../interfaces/Duration";
import { useGetDurationsQuery } from "../../../features/duration/durationSlice";

const EditPricingPlan: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, error, isLoading, refetch } = useGetPricingPlanQuery(id!);
  const [updatePricingPlan, { isLoading: isUpdating }] =
    useUpdatePricingPlanMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [name, setName] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [noOfUsers, setNoOfUsers] = useState<number | undefined>();
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [features, setFeatures] = useState<string[]>([]);
  const [best, setBest] = useState<boolean>(false);

  // Fetch all durations without pagination
  const { data: durations } = useGetDurationsQuery({
    page: 1,
    limit: 0,
  });

  useEffect(() => {
    if (data) {
      setName(data.name || "");
      setDuration(typeof data.duration === "string" ? data.duration : "");
      setNoOfUsers(data.noOfUsers);
      setDescription(data.description);
      setPrice(data.price);
      setFeatures(data.features || []);
      setBest(data.best!);
    }
  }, [data]);

  const handleFeatureChange = (index: number, value: string) => {
    const newFeatures = [...features];
    newFeatures[index] = value;
    setFeatures(newFeatures);
  };

  const handleAddFeature = () => {
    setFeatures([...features, ""]);
  };

  const handleRemoveFeature = (index: number) => {
    const newFeatures = [...features];
    newFeatures.splice(index, 1);
    setFeatures(newFeatures);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updatePricingPlan({
        id: id!,
        pricingPlan: {
          name,
          duration,
          noOfUsers,
          description,
          price,
          features,
          best,
        },
      }).unwrap();
      toast.success("Pricing Plan updated successfully");
      navigate("/admin-dashboard/pricing-plan");
    } catch (err) {
      toast.error("Failed to update Pricing Plan");
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error)
    return <Typography color="error">Error loading pricing plan</Typography>;

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Edit Pricing Plan
      </Typography>
      <TextField
        fullWidth
        label="Name"
        variant="outlined"
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <Select
        fullWidth
        variant="outlined"
        value={duration}
        onChange={(e) => setDuration(e.target.value as string)}
        displayEmpty
        required
      >
        <MenuItem value="">
          <em>Select Duration</em>
        </MenuItem>
        {durations &&
          durations?.data.map((duration: Duration) => (
            <MenuItem key={duration._id} value={duration._id}>
              {duration.duration}
            </MenuItem>
          ))}
      </Select>
      <TextField
        fullWidth
        label="Number of Users"
        variant="outlined"
        margin="normal"
        type="number"
        value={noOfUsers}
        onChange={(e) => setNoOfUsers(parseInt(e.target.value, 10))}
      />
      <TextField
        fullWidth
        label="Description"
        variant="outlined"
        margin="normal"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
      />
      <TextField
        fullWidth
        label="Price"
        variant="outlined"
        margin="normal"
        type="number"
        value={price}
        onChange={(e) => setPrice(parseFloat(e.target.value))}
        required
      />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Features
      </Typography>
      {features.map((feature, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <TextField
            fullWidth
            label={`Feature ${index + 1}`}
            variant="outlined"
            value={feature}
            onChange={(e) => handleFeatureChange(index, e.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleRemoveFeature(index)}
            sx={{ ml: 2 }}
          >
            Remove
          </Button>
        </Box>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddFeature}
        sx={{ mt: 2 }}
      >
        Add Feature
      </Button>
      <Box sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={best}
              onChange={(e) => setBest(e.target.checked)}
            />
          }
          label="Best Plan"
        />
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        disabled={isUpdating}
        sx={{ mt: 2 }}
      >
        {isUpdating ? <CircularProgress size={24} /> : "Update Pricing Plan"}
      </Button>
    </Box>
  );
};

export default EditPricingPlan;
