// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { selectCurrentToken, selectCurrentAdminRole } from './authSlice';

// const PrivateRoute = ({ children, roles }) => {
//     const token = useSelector(selectCurrentToken);
//     const userRole = useSelector(selectCurrentAdminRole);

//     if (!token) {
//         // If not authenticated, redirect to login
//         return <Navigate to="/login" replace />;
//     }

//     if (roles && !roles.includes(userRole)) {
//         // If role not authorized, redirect to unauthorized page or home
//         return <Navigate to="/unauthorized" replace />;
//     }

//     // If authorized, render the children components
//     return children;
// };

// export default PrivateRoute;

import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentAdmin,
  selectCurrentAdminRole,
} from "./adminSlice";
import { RootState } from "../store";

// Define the props type
interface PrivateRouteProps {
  children: ReactNode;
  roles?: string[];
}

const AdminPrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  roles,
}) => {
  const token = useSelector((state: RootState) => selectCurrentToken(state));
  // const admin = useSelector((state: RootState) => selectCurrentAdmin(state));
  const adminRole = useSelector((state: RootState) =>
    selectCurrentAdminRole(state)
  );
  const location = useLocation();

  if (!token) {
    // If not authenticated, redirect to login
    return <Navigate to="/admin-login" replace state={{ from: location }} />;
  }

  if (roles && !roles.includes(adminRole)) {
    // If role not authorized, redirect to unauthorized page or home
    return <Navigate to="/unauthorized" replace />;
  }

  // If authorized, render the children components
  return <>{children}</>;
};

export default AdminPrivateRoute;
