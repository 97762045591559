// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate, NavLink } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import { useGetAdvertisementQuery, useUpdateAdvertisementMutation } from '../../../features/advertisement/advertisementSlice';
// import { useGetAdGroupsQuery } from '../../../features/ad_group/adGroupSlice';

// const EditAdvertisement = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [name, setName] = useState('');
//     const [description, setDescription] = useState('');
//     const [adGroup, setAdGroup] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);

//     const { data: adGroupData } = useGetAdGroupsQuery();

//     console.log(adGroupData);

//     const { data: advertisementData, refetch } = useGetAdvertisementQuery(id);
//     const [updateAdvertisement] = useUpdateAdvertisementMutation();

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     useEffect(() => {
//         if (advertisementData) {
//             setName(advertisementData?.name || '');
//             setDescription(advertisementData?.description || '');
//             setAdGroup(advertisementData?.adGroup || '');
//         }
//     }, [advertisementData]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await updateAdvertisement({ id, advertisement: { name, description, adGroup } }).unwrap();
//             toast.success('Advertisement updated successfully');
//             navigate('/dashboard/advertisement');
//         } catch (err) {
//             toast.error('Failed to update Advertisement');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Edit Advertisement</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Advertisement Name"
//                                     />
//                                     <label htmlFor="name">Advertisement Name</label>
//                                 </div>

//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='description'
//                                         value={description}
//                                         onChange={(e) => setDescription(e.target.value)}
//                                         placeholder="Advertisement Description"
//                                     />
//                                     <label htmlFor="description">Advertisement Description</label>
//                                 </div>

//                                 <div className="form-floating mb-2">
//                                     <select
//                                         className="form-select"
//                                         value={adGroup}
//                                         onChange={(e) => setAdGroup(e.target.value)}
//                                     >
//                                         <option value="">Select Ad Group</option>
//                                         {adGroupData?.map(adGroup => (
//                                             <option key={adGroup._id} value={adGroup._id}>{adGroup.name}</option>
//                                         ))}
//                                     </select>
//                                     <label htmlFor="adGroup">Ad Group</label>
//                                 </div>

//                                 <button className="btn btn-primary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Updating Advertisement...' : 'Update Advertisement'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/advertisement' className="text-dark fw-bold">Advertisement List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditAdvertisement;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useGetAdGroupsQuery } from "../../../features/ad_group/adGroupSlice";
import {
  useGetAdvertisementQuery,
  useUpdateAdvertisementMutation,
} from "../../../features/advertisement/advertisementSlice";

const EditAdvertisement: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [adGroup, setAdGroup] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  // Fetch all ad groups without pagination
  const { data: adGroups } = useGetAdGroupsQuery({
    page: 1,
    limit: 0,
  });

  const {
    data: advertisementData,
    isLoading,
    isError,
  } = useGetAdvertisementQuery(id!);
  const [updateAdvertisement] = useUpdateAdvertisementMutation();

  useEffect(() => {
    if (advertisementData) {
      setName(advertisementData.name || "");
      setDescription(advertisementData.description || "");
      const adGroupData = advertisementData.adGroup;
      if (typeof adGroupData === "string") {
        setAdGroup(adGroupData);
      } else if (adGroupData && typeof adGroupData === "object") {
        setAdGroup(adGroupData._id || ""); // Or another property based on your requirements
      }
    }
  }, [advertisementData]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await updateAdvertisement({
        id: id!,
        advertisement: { name, description, adGroup },
      }).unwrap();
      toast.success("Advertisement updated successfully");
      navigate("/dashboard/advertisement");
    } catch (err) {
      toast.error("Failed to update Advertisement");
    } finally {
      setIsFormLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Typography color="error">
        Failed to load advertisement details
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Edit Advertisement
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Advertisement Name"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Advertisement Description"
          variant="outlined"
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
        />
        <Select
          fullWidth
          variant="outlined"
          //   margin="normal"
          value={adGroup}
          onChange={(e) => setAdGroup(e.target.value as string)}
          displayEmpty
          required
        >
          <MenuItem value="">
            <em>Select Ad Group</em>
          </MenuItem>
          {adGroups &&
            adGroups?.data.map((adGroup) => (
              <MenuItem key={adGroup._id} value={adGroup._id}>
                {adGroup.name}
              </MenuItem>
            ))}
        </Select>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          sx={{ mt: 2 }}
        >
          {isFormLoading ? (
            <CircularProgress size={24} />
          ) : (
            "Update Advertisement"
          )}
        </Button>
      </form>
    </Box>
  );
};

export default EditAdvertisement;
