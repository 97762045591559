import { Payment } from "./Payment";
import { PricingPlan } from "./PricingPlan";

export enum SubscriptionStatus {
  READY_TO_PAY = "readyToPay",
  REQUESTED = "requested",
  PAID = "paid",
  APPROVED = "approved",
  EXPIRED = "expired",
}

export interface Subscription {
  _id?: string; // MongoDB ObjectId as a string
  company: string; // ObjectId reference to Company
  pricingPlan: PricingPlan | string; // ObjectId reference to PricingPlan
  paidAmount: number; // Paid amount as number
  duration: string; // ObjectId reference to Duration
  status: SubscriptionStatus; // Enum for subscription status
  startDate?: string; // ISO date string
  endDate?: string; // ISO date string
  payment?: Payment | string; // ObjectId reference to Payment
  isActive: boolean; // Indicates if the subscription is active
}

export interface SubscriptionResponse {
  data: Subscription[];
  total: number;
}
