// import * as React from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Card from "@mui/material/Card";
// import Chip from "@mui/material/Chip";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import Container from "@mui/material/Container";
// import Divider from "@mui/material/Divider";
// import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
// import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

// const tiers = [
//   {
//     title: "Free",
//     price: "0",
//     description: [
//       "10 users included",
//       "2 GB of storage",
//       "Help center access",
//       "Email support",
//     ],
//     buttonText: "Sign up for free",
//     buttonVariant: "outlined",
//   },
//   {
//     title: "Professional",
//     subheader: "Recommended",
//     price: "15",
//     description: [
//       "20 users included",
//       "10 GB of storage",
//       "Help center access",
//       "Priority email support",
//       "Dedicated team",
//       "Best deals",
//     ],
//     buttonText: "Start now",
//     buttonVariant: "contained",
//   },
//   {
//     title: "Enterprise",
//     price: "30",
//     description: [
//       "50 users included",
//       "30 GB of storage",
//       "Help center access",
//       "Phone & email support",
//     ],
//     buttonText: "Contact us",
//     buttonVariant: "outlined",
//   },
// ];

// const Pricing = () => {
//   return (
//     <Container
//       id="pricing"
//       sx={{
//         pt: { xs: 4, sm: 12 },
//         pb: { xs: 8, sm: 16 },
//         position: "relative",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         gap: { xs: 3, sm: 6 },
//       }}
//     >
//       <Box
//         sx={{
//           width: { sm: "100%", md: "60%" },
//           textAlign: { sm: "left", md: "center" },
//         }}
//       >
//         <Typography component="h2" variant="h4" color="text.primary">
//           Pricing
//         </Typography>
//         <Typography variant="body1" color="text.secondary">
//           Quickly build an effective pricing table for your potential customers
//           with this layout. <br />
//           It&apos;s built with default Material UI components with little
//           customization.
//         </Typography>
//       </Box>
//       <Grid container spacing={3} alignItems="center" justifyContent="center">
//         {tiers.map((tier) => (
//           <Grid
//             item
//             key={tier.title}
//             xs={12}
//             sm={tier.title === "Enterprise" ? 12 : 6}
//             md={4}
//           >
//             <Card
//               sx={{
//                 p: 2,
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: 4,
//                 border: tier.title === "Professional" ? "1px solid" : undefined,
//                 borderColor:
//                   tier.title === "Professional" ? "primary.main" : undefined,
//                 background:
//                   tier.title === "Professional"
//                     ? "linear-gradient(#033363, #021F3B)"
//                     : undefined,
//               }}
//             >
//               <CardContent>
//                 <Box
//                   sx={{
//                     mb: 1,
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     color: tier.title === "Professional" ? "grey.100" : "",
//                   }}
//                 >
//                   <Typography component="h3" variant="h6">
//                     {tier.title}
//                   </Typography>
//                   {tier.title === "Professional" && (
//                     <Chip
//                       icon={<AutoAwesomeIcon />}
//                       label={tier.subheader}
//                       size="small"
//                       sx={{
//                         background: (theme) =>
//                           theme.palette.mode === "light" ? "" : "none",
//                         backgroundColor: "primary.contrastText",
//                         "& .MuiChip-label": {
//                           color: "primary.dark",
//                         },
//                         "& .MuiChip-icon": {
//                           color: "primary.dark",
//                         },
//                       }}
//                     />
//                   )}
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "baseline",
//                     color:
//                       tier.title === "Professional" ? "grey.50" : undefined,
//                   }}
//                 >
//                   <Typography component="h3" variant="h2">
//                     ${tier.price}
//                   </Typography>
//                   <Typography component="h3" variant="h6">
//                     &nbsp; per month
//                   </Typography>
//                 </Box>
//                 <Divider
//                   sx={{
//                     my: 2,
//                     opacity: 0.2,
//                     borderColor: "grey.500",
//                   }}
//                 />
//                 {tier.description.map((line) => (
//                   <Box
//                     key={line}
//                     sx={{
//                       py: 1,
//                       display: "flex",
//                       gap: 1.5,
//                       alignItems: "center",
//                     }}
//                   >
//                     <CheckCircleRoundedIcon
//                       sx={{
//                         width: 20,
//                         color:
//                           tier.title === "Professional"
//                             ? "primary.light"
//                             : "primary.main",
//                       }}
//                     />
//                     <Typography
//                       component="text"
//                       variant="subtitle2"
//                       sx={{
//                         color:
//                           tier.title === "Professional"
//                             ? "grey.200"
//                             : undefined,
//                       }}
//                     >
//                       {line}
//                     </Typography>
//                   </Box>
//                 ))}
//               </CardContent>
//               <CardActions>
//                 <Button
//                   fullWidth
//                   variant={tier.buttonVariant as "outlined" | "contained"}
//                   component="a"
//                   href="/material-ui/getting-started/templates/checkout/"
//                   target="_blank"
//                 >
//                   {tier.buttonText}
//                 </Button>
//               </CardActions>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Container>
//   );
// };

// export default Pricing;

import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { PricingPlan } from "../../interfaces/PricingPlan";
import { Duration } from "../../interfaces/Duration";
import { useGetDurationsQuery } from "../../features/duration/userDurationSlice";
import { useGetPricingPlansQuery } from "../../features/pricing_plan/userPricingPlanSlice";
import { Link as ReactDomLink } from "react-router-dom";

const Pricing = () => {
  const [selectedDuration, setSelectedDuration] = React.useState<string>("");
  const [selectedPricingPlanId, setLocalSelectedPricingPlanId] =
    React.useState<string>(""); // Use a local state for managing the selected pricing plan ID
  // const [selectedPricingPlanId, setSelectedPricingPlanId] =
  //   React.useState<string>("");
  const { data: durationsData } = useGetDurationsQuery({ page: 1, limit: 10 });
  const { data: pricingPlansData, refetch } = useGetPricingPlansQuery({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    refetch(); // Fetch new pricing plans data when the component mounts
  }, []);

  useEffect(() => {
    if (durationsData?.data?.length) {
      const lastDurationIndex = durationsData.data.length - 1;
      setSelectedDuration(durationsData.data[lastDurationIndex]._id!); // Select the last duration by default
    }
  }, [durationsData]);

  useEffect(() => {
    if (pricingPlansData?.data?.length && selectedDuration) {
      const filteredPlans = pricingPlansData.data
        .filter((plan: PricingPlan) => plan.duration === selectedDuration)
        .reverse();

      if (filteredPlans.length > 0) {
        // setSelectedPricingPlanId(filteredPlans[0]._id!);
        setLocalSelectedPricingPlanId(filteredPlans[0]._id!);
      }
    }
  }, [pricingPlansData, selectedDuration]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedDuration(newValue);
  };

  const handleButtonClick = (pricingPlanId: string) => {
    setLocalSelectedPricingPlanId(pricingPlanId); // Update local state for the selected plan
    // setSelectedPricingPlanId(pricingPlanId); // Pass selected plan ID to parent via prop function
  };

  // const filteredPricingPlans = pricingPlansData?.data.filter(
  //   (plan: PricingPlan) => plan.duration === selectedDuration
  // );

  const selectedDurationObject = durationsData?.data?.find(
    (duration: Duration) => duration._id === selectedDuration
  );

  const getPerText = () => {
    switch (selectedDurationObject?.duration) {
      case "Monthly":
        return "per month";
      case "Quarterly":
        return "per quarter";
      case "Yearly":
        return "per year";
      default:
        return "";
    }
  };

  const filteredPricingPlans = pricingPlansData?.data
    .filter((plan: PricingPlan) => plan.duration === selectedDuration)
    .reverse();

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Choose a pricing plan that best fits your business needs. Our CRM
          offers flexible options to scale as your business grows. Each plan
          includes robust features to enhance your lead management, improve
          customer interactions, and drive sales.
        </Typography>
      </Box>

      <Tabs value={selectedDuration} onChange={handleTabChange}>
        {durationsData?.data
          ?.slice()
          .reverse()
          .map((duration: Duration) => (
            <Tab
              key={duration._id}
              value={duration._id}
              label={duration.duration}
            />
          ))}
      </Tabs>

      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {filteredPricingPlans?.map((pricingPlan: PricingPlan) => (
          <Grid
            item
            key={pricingPlan._id}
            xs={12}
            sm={pricingPlan.best ? 12 : 6}
            md={4}
          >
            {/* //   <Card
          //     sx={{
          //       p: 2,
          //       display: "flex",
          //       flexDirection: "column",
          //       gap: 4,
          //       border: pricingPlan.best ? "1px solid" : undefined,
          //       borderColor: pricingPlan.best ? "primary.main" : undefined,
          //       background: pricingPlan.best
          //         ? "linear-gradient(#033363, #021F3B)"
          //         : undefined,
          //       borderBottom:
          //         pricingPlan._id === selectedPricingPlanId
          //           ? "4px solid #1976d2" // Change color as desired
          //           : "none",
          //       boxShadow:
          //         pricingPlan._id === selectedPricingPlanId
          //           ? "0 4px 8px rgba(0, 0, 0, 0.2)" // Adds shadow to selected card
          //           : undefined,
          //     }}
          //   > */}
            <Card
              sx={(theme) => ({
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: pricingPlan.best ? "1px solid" : undefined,
                borderColor: pricingPlan.best
                  ? theme.palette.primary.main
                  : undefined, // Use theme object
                background: pricingPlan.best
                  ? `linear-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                  : undefined, // Use the theme for the gradient
                borderBottom:
                  pricingPlan._id === selectedPricingPlanId
                    ? `4px solid ${theme.palette.primary.main}` // Use theme for the bottom border
                    : "none",
                boxShadow:
                  pricingPlan._id === selectedPricingPlanId
                    ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                    : undefined,
              })}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: pricingPlan.best ? "grey.100" : "",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {pricingPlan.name}
                  </Typography>
                  {pricingPlan.best && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label="Recommended"
                      size="small"
                      sx={{
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": { color: "primary.dark" },
                        "& .MuiChip-icon": { color: "primary.dark" },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color: pricingPlan.best ? "grey.50" : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    E£{pricingPlan.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; {getPerText()}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {pricingPlan.features?.map((feature: string) => (
                  <Box
                    key={feature}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: pricingPlan.best
                          ? "primary.light"
                          : "primary.main",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{ color: pricingPlan.best ? "grey.200" : undefined }}
                    >
                      {feature}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={pricingPlan.best ? "contained" : "outlined"}
                  onClick={() => handleButtonClick(pricingPlan._id!)}
                >
                  Select
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* Add the "Get Started" button at the bottom */}
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          component={ReactDomLink}
          to="/register"
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
};

export default Pricing;
