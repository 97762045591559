// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { useCreateAdvertisementMutation } from '../../../../features/advertisement/advertisementSlice';
// import { useGetAdGroupsQuery } from '../../../../features/ad_group/adGroupSlice';

// const AddAdvertisement = () => {
//     const [name, setName] = useState('');
//     const [description, setDescription] = useState('');
//     const [adGroup, setAdGroup] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);
//     const navigate = useNavigate();
//     const [createAdvertisement] = useCreateAdvertisementMutation();
//     const { data: adGroups } = useGetAdGroupsQuery();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         try {
//             await createAdvertisement({ name, description, adGroup }).unwrap();
//             setName('');
//             setDescription('');
//             setAdGroup('');
//             navigate('/dashboard/advertisement');
//             toast.success('Advertisement added successfully');
//         } catch (err) {
//             toast.error('Failed to add Advertisement');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Add New Advertisement</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="form-floating mb-2">
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         name='name'
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Advertisement Name"
//                                     />
//                                     <label htmlFor="name">Advertisement Name</label>
//                                 </div>

//                                 <div className="form-floating mb-2">
//                                     <textarea
//                                         className="form-control"
//                                         name='description'
//                                         value={description}
//                                         onChange={(e) => setDescription(e.target.value)}
//                                         placeholder="Advertisement Description"
//                                     />
//                                     <label htmlFor="description">Advertisement Description</label>
//                                 </div>

//                                 <div className="form-floating mb-2">
//                                     <select
//                                         className="form-select"
//                                         name='adGroup'
//                                         value={adGroup}
//                                         onChange={(e) => setAdGroup(e.target.value)}
//                                     >
//                                         <option value="">Select Ad Group</option>
//                                         {adGroups && adGroups.map(adGroup => (
//                                             <option key={adGroup._id} value={adGroup._id}>{adGroup.name}</option>
//                                         ))}
//                                     </select>
//                                     <label htmlFor="adGroup">Ad Group</label>
//                                 </div>

//                                 <button className="btn btn-primary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Adding Advertisement...' : 'Add Advertisement'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/advertisement' className="text-dark fw-bold">Advertisement List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddAdvertisement;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateAdvertisementMutation } from "../../../features/advertisement/advertisementSlice";
import {
  adGroupApiSlice,
  useGetAdGroupsQuery,
} from "../../../features/ad_group/adGroupSlice";
import { useDispatch } from "react-redux";

const AddAdvertisement: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [adGroup, setAdGroup] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createAdvertisement] = useCreateAdvertisementMutation();
  // Fetch all ad groups without pagination
  const { data: adGroups, refetch: refetchAdGroup } = useGetAdGroupsQuery({
    page: 1,
    limit: 0,
  });

  useEffect(() => {
    // Reset the API cache to clear the cached data for the user
    dispatch(adGroupApiSlice.util.resetApiState());
    refetchAdGroup();
  }, [dispatch]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createAdvertisement({ name, description, adGroup }).unwrap();
      setName("");
      setDescription("");
      setAdGroup("");
      navigate("/dashboard/advertisement");
      toast.success("Advertisement added successfully");
    } catch (err) {
      toast.error("Failed to add Advertisement");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Add New Advertisement
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Advertisement Name"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Advertisement Description"
          variant="outlined"
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
        />
        <Select
          fullWidth
          variant="outlined"
          //   margin="normal"
          value={adGroup}
          onChange={(e) => setAdGroup(e.target.value as string)}
          displayEmpty
          required
        >
          <MenuItem value="">
            <em>Select Ad Group</em>
          </MenuItem>
          {adGroups &&
            adGroups?.data.map((adGroup) => (
              <MenuItem key={adGroup._id} value={adGroup._id}>
                {adGroup.name}
              </MenuItem>
            ))}
        </Select>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          sx={{ mt: 2 }}
        >
          {isFormLoading ? <CircularProgress size={24} /> : "Add Advertisement"}
        </Button>
      </form>
    </Box>
  );
};

export default AddAdvertisement;
