import React, { useEffect } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/store";
import { selectCurrentUser } from "../../../features/auth/authSlice";
import { PaymentStatus } from "../../../interfaces/Payment";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../../../features/user/userSlice";
import { CheckCircle } from "@mui/icons-material";

const WaitingConfirmation = () => {
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => selectCurrentUser(state));
  const {
    data: user,
    refetch: refetchUser,
    isSuccess,
  } = useGetUserQuery(userData._id!, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (userData._id) {
      refetchUser();
    }
  }, [userData._id, refetchUser]);
  console.log("User", user);

  useEffect(() => {
    if (
      isSuccess &&
      typeof user.company === "object" &&
      user.company !== null &&
      typeof user.company.subscription === "object" &&
      user.company.subscription !== null &&
      typeof user.company.subscription.payment === "object" &&
      user.company.subscription.payment !== null &&
      user.company?.subscription?.payment.status === PaymentStatus.SUCCESSFUL
    ) {
      navigate("/dashboard");
    }
  }, [user]);
  const company = typeof user?.company === "object" ? user.company : null;

  const subscription =
    company && typeof company.subscription === "object"
      ? company.subscription
      : null;

  const payment =
    subscription && typeof subscription.payment === "object"
      ? subscription.payment
      : null;
  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     height: "100vh",
    //     backgroundColor: "#f5f5f5",
    //   }}
    // >
    //   <Card
    //     sx={{
    //       boxShadow: 3,
    //       borderRadius: 2,
    //       padding: 3,
    //       maxWidth: "400px",
    //       textAlign: "center",
    //     }}
    //   >
    //     <CardContent>
    //       <CircularProgress sx={{ mb: 3 }} />
    //       <Typography variant="h6" gutterBottom>
    //         Waiting for Confirmation
    //       </Typography>
    //       <Typography variant="body1" sx={{ mb: 2 }}>
    //         Thank you for your patience. Your account will be activated soon
    //         after approval.
    //       </Typography>
    //     </CardContent>
    //   </Card>
    // </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#e0f7fa", // Light blue background for a fresh look
      }}
    >
      <Card
        sx={{
          boxShadow: 4,
          borderRadius: 3,
          padding: 4,
          maxWidth: "450px",
          textAlign: "center",
          backgroundColor: "#ffffff", // White background for card
        }}
      >
        <CardContent>
          <CheckCircle sx={{ fontSize: 60, color: "#4caf50", mb: 3 }} />
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            Request in Progress
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, color: "#666" }}>
            We will send a collect request to your Instapay account{" "}
            <strong>{payment?.IPA}@instapay</strong> for the mobile number{" "}
            <strong>{payment?.mobileNumber}</strong>.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#666" }}>
            We will notify you at <strong>{user?.email}</strong> once your
            account has been activated.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default WaitingConfirmation;
