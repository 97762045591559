import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Avatar,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import { Logout, Settings, HelpOutline } from "@mui/icons-material";
import {
  selectCurrentUser,
  selectCurrentUserRole,
  signOut,
} from "../features/auth/authSlice";
import profileImg from "./images/user.jpg";
import { AppDispatch } from "../features/store";

// Define a mapping from role keys to display strings
const roleDisplayMap = {
  owner: "OWNER",
  head_of_sales: "HEAD_OF_SALES",
  sales_director: "SALES_DIRECTOR",
  sales_manager: "SALES_MANAGER",
  sales: "SALES",
};

type Role =
  | "owner"
  | "head_of_sales"
  | "sales_director"
  | "sales_manager"
  | "sales";

// Utility function to get the display name for a role
const getRoleDisplayName = (role: Role): string => {
  return roleDisplayMap[role] || "Role";
};

const NavAvatar = () => {
  const dispatch = useDispatch<AppDispatch>(); // Use AppDispatch type
  const user = useSelector(selectCurrentUser);
  const role = useSelector(selectCurrentUserRole);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleSignOut = () => {
  //   dispatch(logOut());
  //   window.location.href = "/login";
  // };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <div>
      <IconButton onClick={handleMenuOpen} color="inherit">
        <Avatar alt="User" src={user?.avatar?.url || profileImg} />
        {/* <Typography variant="body2" sx={{ marginLeft: 1 }}>
          {user?.name || "Guest"}
        </Typography> */}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem disabled>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">{user?.name || "Guest"}</Typography>
            <Typography variant="body2" color="textSecondary">
              {getRoleDisplayName(role) || "Role"}
            </Typography>
          </Box>
        </MenuItem>

        <Divider />

        <MenuItem
          component={Link}
          to={`/dashboard/profile/${user._id}`}
          onClick={handleMenuClose}
        >
          <Avatar
            alt="Profile"
            src={user?.avatar?.url || profileImg}
            sx={{ marginRight: 2 }}
          />
          My Profile
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleMenuClose}>
          <Settings fontSize="small" sx={{ marginRight: 2 }} />
          Account Settings
        </MenuItem>

        <MenuItem onClick={handleMenuClose}>
          <HelpOutline fontSize="small" sx={{ marginRight: 2 }} />
          Need Help?
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleSignOut}>
          <Logout fontSize="small" sx={{ marginRight: 2 }} />
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
};

export default NavAvatar;
